<!-- Big Screen -->
<ng-container *ngIf="this.bigScreenMode">
  <ion-row *ngIf="this.showHeader" class="sis-ion-row vertical-align-content sis-header-row">
    <ion-col size="3">{{ 'general.term.description' | translate }}</ion-col>
    <ion-col size="1">{{ 'general.term.active' | translate }}</ion-col>
    <ion-col size="1.5">{{ 'general.term.on' | translate }}</ion-col>
    <ion-col size="1.5">{{ 'general.term.off' | translate }}</ion-col>
    <ion-col size="1">{{ 'general.term.priorityShort' | translate }}</ion-col>
    <ion-col size="2">{{ 'general.term.status' | translate }}</ion-col>
    <ion-col></ion-col>
  </ion-row>

  <ion-row class="sis-global-row-60 vertical-align-content sis-row">
    <ion-col size="3">
      <ion-input
        class="sis-text-input"
        [ngClass]="this.descriptionInputCss"
        inputmode="text"
        maxlength="50"
        [value]="assetOverrideItem.description"
        (ionBlur)="this.descriptionChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-description-input"
      ></ion-input>
    </ion-col>
    <ion-col size="1">
      <ion-checkbox
        [checked]="assetOverrideItem.active"
        (ionChange)="this.activeChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-active-ckb"
      ></ion-checkbox>
    </ion-col>
    <ion-col size="1.5">
      <ion-input
        class="sis-global-input"
        [value]="assetOverrideItem.startTime"
        type="time"
        (ionBlur)="this.startTimeChanged($event)"
        [disabled]="!this.writePermission"
        required
        data-test="sismedia-asset-override-start-time-input"
      ></ion-input>
    </ion-col>
    <ion-col size="1.5">
      <ion-input
        class="sis-global-input"
        [value]="assetOverrideItem.endTime"
        type="time"
        (ionBlur)="this.endTimeChanged($event)"
        [disabled]="!this.writePermission"
        required
        data-test="sismedia-asset-override-end-time-input"
      ></ion-input>
    </ion-col>
    <ion-col size="1">
      <ion-input
        class="sis-text-input"
        [ngClass]="this.priorityInputCss"
        inputmode="numeric"
        type="number"
        min="0"
        max="100"
        [value]="assetOverrideItem.priority"
        (ionBlur)="this.priorityChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-prio-input"
      ></ion-input>
    </ion-col>
    <ion-col size="2">
      <ion-icon
        *ngFor="let options of this.sisMediaItem.iconOptions"
        [name]="options.iconName"
        class="sis-statusicon"
        [ngClass]="[
          this.assetOverrideItem.overrideStatus === options.sisMediaStatus
            ? options.colorEnabledClassName
            : this.writePermission
            ? options.colorDisabledClassName
            : 'sis-disabled'
        ]"
        [class.sis-global-default-cursor]="!this.writePermission"
        (click)="this.overrideStatusChanged(options.sisMediaStatus)"
        matTooltip="{{ options.tooltipTranslationString | translate }}"
        [attr.data-test]="options.dataTestOverrideString"
      ></ion-icon>
    </ion-col>
    <ion-col size="1">
      <a
        *ngIf="!this.isDeleting && !this.isSaving"
        color="secondary"
        class="sis-asset-override-clickable"
        (click)="deleteEntry()"
        [disabled]="!this.writePermission"
        matTooltip="{{ 'general.term.delete' | translate }}"
        data-test="sismedia-asset-override-item-delete"
      >
        <ion-icon style="font-size: 22px" class="sis-ion-icon-asset" name="trash-outline"></ion-icon>
      </a>

      <a
        *ngIf="this.writePermission && this.hasChanged && !this.isSaving"
        color="secondary"
        class="sis-asset-override-clickable"
        (click)="saveEntry()"
        matTooltip="{{ 'general.term.save' | translate }}"
        data-test="sismedia-asset-override-item-save"
      >
        <ion-icon style="font-size: 30px" class="sis-ion-icon-asset" name="checkmark-outline"></ion-icon>
      </a>

      <a
        *ngIf="this.writePermission && this.hasChanged && !this.isSaving"
        color="secondary"
        class="sis-asset-override-clickable"
        (click)="reset()"
        matTooltip="{{ 'general.term.reset' | translate }}"
        data-test="sismedia-asset-override-item-reset"
      >
        <ion-icon style="font-size: 30px" class="sis-ion-icon-asset" name="close-outline"></ion-icon>
      </a>

      <ion-spinner *ngIf="this.isDeleting || this.isSaving" name="dots"></ion-spinner>
    </ion-col>
  </ion-row>
</ng-container>

<!-- Small Screen -->
<ng-container *ngIf="!this.bigScreenMode">
  <ion-row class="sis-ion-row vertical-align-content">
    <ion-col>
      <ion-input
        class="sis-text-input"
        [ngClass]="this.descriptionInputCss"
        inputmode="text"
        maxlength="50"
        [value]="assetOverrideItem.description"
        (ionBlur)="this.descriptionChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-description-input"
      ></ion-input>
    </ion-col>
  </ion-row>
  <ion-row class="sis-ion-row-small vertical-align-content">
    <ion-col size="1.8" class="sis-header-small">{{ 'general.term.active' | translate }}</ion-col>
    <ion-col>
      <ion-checkbox
        [checked]="assetOverrideItem.active"
        (ionChange)="this.activeChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-active-ckb"
      ></ion-checkbox>
    </ion-col>
    <ion-col size="1.9" class="sis-header-small">{{ 'general.term.priorityShort' | translate }}</ion-col>
    <ion-col>
      <ion-input
        class="sis-text-input"
        [ngClass]="this.priorityInputCss"
        inputmode="numeric"
        type="number"
        [value]="assetOverrideItem.priority"
        (ionBlur)="this.priorityChanged($event)"
        [disabled]="!this.writePermission"
        data-test="sismedia-asset-override-prio-input"
      ></ion-input>
    </ion-col>
  </ion-row>
  <ion-row class="sis-ion-row-small vertical-align-content">
    <ion-col size="1.8" class="sis-header-small">{{ 'general.term.on' | translate }}</ion-col>
    <ion-col>
      <ion-input
        class="sis-time-input"
        [value]="assetOverrideItem.startTime"
        type="time"
        (ionBlur)="this.startTimeChanged($event)"
        [disabled]="!this.writePermission"
        required
        data-test="sismedia-asset-override-start-time-input"
      ></ion-input>
    </ion-col>
    <ion-col size="1.9" class="sis-header-small">{{ 'general.term.off' | translate }}</ion-col>
    <ion-col>
      <ion-input
        class="sis-time-input"
        [value]="assetOverrideItem.endTime"
        type="time"
        (ionBlur)="this.endTimeChanged($event)"
        [disabled]="!this.writePermission"
        required
        data-test="sismedia-asset-override-end-time-input"
      ></ion-input>
    </ion-col>
  </ion-row>
  <ion-row class="sis-ion-row-small vertical-align-content sis-row" style="padding-bottom: 40px">
    <ion-col size="1.8" class="sis-header-small">{{ 'general.term.status' | translate }}</ion-col>
    <ion-col>
      <ion-icon
        *ngFor="let options of this.sisMediaItem.iconOptions"
        [name]="options.iconName"
        class="sis-statusicon"
        [ngClass]="[
          this.assetOverrideItem.overrideStatus === options.sisMediaStatus
            ? options.colorEnabledClassName
            : this.writePermission
            ? options.colorDisabledClassName
            : 'sis-disabled'
        ]"
        [class.sis-global-default-cursor]="!this.writePermission"
        (click)="this.overrideStatusChanged(options.sisMediaStatus)"
        matTooltip="{{ options.tooltipTranslationString | translate }}"
        [attr.data-test]="options.dataTestOverrideString"
      ></ion-icon>
    </ion-col>
    <ion-col size="5.1">
      <ion-chip
        color="secondary"
        class="sis-global-round-btn"
        style="margin: 3px"
        (click)="deleteEntry()"
        [disabled]="!this.writePermission"
      >
        <ion-label *ngIf="!this.isDeleting">
          <ion-icon slot="end" name="trash-outline"></ion-icon>
        </ion-label>
        <ion-spinner *ngIf="this.isDeleting" name="dots"></ion-spinner>
      </ion-chip>
      <ion-chip
        color="secondary"
        class="sis-global-round-btn"
        style="margin: 3px"
        (click)="reset()"
        [disabled]="!this.writePermission || !this.hasChanged"
        data-test="sismedia-asset-override-item-reset"
      >
        <ion-label *ngIf="!this.isSaving">
          <ion-icon name="close-outline"></ion-icon>
        </ion-label>
      </ion-chip>
      <ion-chip
        color="secondary"
        class="sis-global-round-btn"
        style="margin: 3px"
        (click)="saveEntry()"
        [disabled]="!this.writePermission || !this.hasChanged"
        data-test="sismedia-asset-override-item-save"
      >
        <ion-label *ngIf="!this.isSaving">
          <ion-icon name="checkmark-outline"></ion-icon>
        </ion-label>
        <ion-spinner *ngIf="this.isSaving" name="dots"></ion-spinner>
      </ion-chip>
    </ion-col>
  </ion-row>
</ng-container>
