<ion-grid
  style="padding-bottom: 5px"
  [ngClass]="{'sis-grid-small-screen' : !this.bigScreenMode}"
  class="sis-global-page-grid sis-global-no-scrollbar"
>
  <sis-page-title
    [title]="'general.term.infotext'"
    [featureId]="[[this.featureId]]"
  >
  </sis-page-title>

  <ion-segment
    [value]="selectedTab"
    (ionChange)="selectionChanged($event.detail.value)"
    *ngIf="bigScreenMode && categories && selectedTab"
  >
    <ion-segment-button [value]="category" *ngFor="let category of this.categories">
      <ion-label>{{category.translatedCategory}}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div
    class="sis-infotext-container sis-global-sis-scrollbar"
    [ngClass]="{'sis-infotext-container-big-screen' : this.bigScreenMode}"
  >
    <div
      *ngFor="let infotext of this.infotexts"
      class="sis-infotext-item"
      [ngClass]="{'sis-clickable' : this.writePermission}"
      (click)="this.showTextEditor(infotext)"
    >
      <span class="sis-infotext-title">{{infotext.translatedTitle}}</span>
      <div class="sis-infotext-textcontainer">{{infotext.translatedText}}</div>
    </div>
  </div>
</ion-grid>

<!-- Small Screen -->
<ion-toolbar class="sis-infotext-tabs" *ngIf="!bigScreenMode && this.categories && this.selectedTab">
  <ion-tab-bar class="sis-infotext-tab-bar" #tabs [selectedTab]="this.selectedTab">
    <ion-tab-button
      *ngFor="let category of this.categories; let i = index"
      [className]="i === 0 ? 'sis-infotext-tab-left' : 'sis-infotext-tab'"
      [tab]="category"
      (click)="selectionChanged(category)"
    >
      {{category.translatedCategory}}
    </ion-tab-button>
  </ion-tab-bar>
</ion-toolbar>
