<ion-header>
  <ion-toolbar>
    <ion-title class="sis-imageBrowse-header">
      {{ 'mediacenter.term.images' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="sis-content">
  <div *ngIf="!this.images" class="sis-loading-text">
    <ion-spinner name="dots" class="sis-spinner"></ion-spinner>
  </div>
  <ion-grid style="padding: 0px" *ngIf="this.images">
    <sis-media-center-image-container
      *ngFor="let image of this.images; trackBy: itemId"
      [image]="image"
      (imageSelected)="this.imageSelected($event)"
    ></sis-media-center-image-container>
  </ion-grid>
</ion-content>
<ion-footer style="height: fit-content" class="ion-no-border">
  <ion-button expand="full" class="sis-file-selector">
    <label class="sis-image-label vertical-align-content" for="file">{{
      'mediacenter.term.uploadFile' | translate
    }}</label>
  </ion-button>
</ion-footer>
<input type="file" id="file" [accept]="this.acceptedFileFormats" (change)="handleFileInput($event)" />
<!-- To create thumbnails from all currently uploaded images of a tenant, uncomment the next line and click the button (for prod: ng serve --c=production) -->
<!-- <ion-button (click)="createAllThumbnails()"></ion-button> -->
