import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TimetableDirection } from 'src/app/timetable/domain/timetable-direction.enum';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { TimetableStation } from 'src/app/timetable/domain/timetable-station.model';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  templateUrl: './journey-editor.component.html',
  styleUrls: ['./journey-editor.component.scss'],
})
export class JourneyEditorComponent extends Unsubscriber implements OnInit {
  journey: TimetableJourney;
  stations: TimetableStation[];
  season: TimetableSeason;
  journeyFormsGroup: FormGroup;
  hasMoreThanTwoStations: boolean;
  isNewJourney: boolean;
  isSaving: boolean;

  constructor(private modalCtrl: ModalController, private timetableService: TimetableService) {
    super();
  }

  ngOnInit() {
    this.stations = this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
    this.hasMoreThanTwoStations = this.stations.length > 2;

    this.journeyFormsGroup = new FormGroup(
      {
        vehicleNumber: new FormControl(this.journey?.vehicleNumber, [Validators.pattern('^[a-zA-Z0-9äÄöÖüÜ\\s-&+]*$'), Validators.maxLength(10)]),
        direction: new FormControl(this.hasMoreThanTwoStations ? this.journey?.direction ?? TimetableDirection.up : this.journey?.direction),
      },
      {
        validators: [this.validateDepartureTime()],
      }
    );

    if (this.hasMoreThanTwoStations) {
      this.stations.forEach((station) => {
        this.journeyFormsGroup.addControl(
          `departureTime_${station.guid}`,
          new FormControl(this.journey?.items?.find((i) => i.stationGuids.some((g) => g === station.guid))?.departureTime)
        );
      });
    } else {
      this.journeyFormsGroup.addControl('departureTime', new FormControl(this.journey?.items ? this.journey.items[0]?.departureTime : null));
    }
  }

  validateDepartureTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex1 = /^\d{2}:\d{2}:\d{2}$/g;
      const regex2 = /^\d{2}:\d{2}$/g;
      const departureTimes = [];
      if (this.hasMoreThanTwoStations) {
        this.stations.forEach((station) => {
          departureTimes.push(control.get(`departureTime_${station.guid}`)?.value);
        });
        return departureTimes.every((entry) => entry?.match(regex1) != null || entry?.match(regex2) != null || entry == null || entry == '')
          ? null
          : { departureTimesInvalid: true };
      } else {
        departureTimes.push(control.get('departureTime')?.value);
        return departureTimes.every((entry) => entry?.match(regex1) != null || entry?.match(regex2) != null) ? null : { departureTimesInvalid: true };
      }
    };
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    this.isSaving = true;
    await this.timetableService.updateJourney(this.rejoinJourneyData());
    this.close();
  }

  private rejoinJourneyData() {
    const journeyPostData: TimetableJourney = {
      guid: this.isNewJourney ? undefined : this.journey.guid,
      seasonGuid: this.isNewJourney ? this.season.guid : this.journey.seasonGuid,
      direction: this.journeyFormsGroup.value.direction,
      vehicleNumber: this.journeyFormsGroup.value.vehicleNumber,
      items: [],
    };

    if (this.hasMoreThanTwoStations) {
      journeyPostData.direction = TimetableDirection.up;

      this.stations.forEach((station) => {
        const departureTime = this.journeyFormsGroup.value[`departureTime_${station.guid}`];

        if (departureTime) {
          journeyPostData.items.push({
            guid: this.isNewJourney ? undefined : this.journey.items.find((i) => i.stationGuids[0] === station.guid)?.guid,
            journeyGuid: this.isNewJourney ? undefined : this.journey.guid,
            departureTime: departureTime,
            stationGuids: [station.guid],
          });
        }
      });
    } else {
      let stationGuids = this.stations.map((s) => s.guid);

      if (this.journeyFormsGroup.value.direction === TimetableDirection.up) {
        stationGuids = [stationGuids[0]];
      }

      if (this.journeyFormsGroup.value.direction === TimetableDirection.down) {
        stationGuids = [stationGuids[1]];
      }

      journeyPostData.items.push({
        guid: this.isNewJourney ? undefined : this.journey.items[0].guid,
        journeyGuid: this.isNewJourney ? undefined : this.journey.guid,
        departureTime: this.journeyFormsGroup.value.departureTime,
        stationGuids: stationGuids,
      });
    }

    return journeyPostData;
  }
}
