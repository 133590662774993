<!-- Big Screen -->
<ion-row *ngIf="this.bigScreenMode$ | async" class="sis-row" data-test="slopes-operating-times-location-row">
  <ion-col size="1"></ion-col>
  <ion-col size="3" class="sis-subtitle-text-location">{{ this.editableItem.location }}</ion-col>
  <ion-col class="ion-no-padding">
    <form [formGroup]="this.formGroup">
      <ion-grid *ngIf="this.formGroup" class="ion-no-padding">
        <ion-row class="sis-text-row">
          <ion-col size="4" class="sis-subtitle-text">{{ 'slopesOperatingTimes.term.firstRide' | translate }}</ion-col>
          <ion-col size="2" data-test="slopes-operating-times-first-ride-input-col">
            <ion-input
              type="time"
              formControlName="firstRide"
              class="sis-global-input"
              [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.firstRide?.errors }"
              required
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="sis-text-row">
          <ion-col size="4" class="sis-subtitle-text">{{ 'slopesOperatingTimes.term.lastRide' | translate }}</ion-col>
          <ion-col size="2" data-test="slopes-operating-times-last-ride-input-col">
            <ion-input
              type="time"
              formControlName="lastRide"
              class="sis-global-input"
              [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.lastRide?.errors }"
              required
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="sis-text-row" style="margin-bottom: 5px">
          <ion-col size="4" class="sis-subtitle-text">{{
            'slopesOperatingTimes.term.lastSlopeControl' | translate
          }}</ion-col>
          <ion-col size="2" data-test="slopes-operating-times-last-slope-control-input-col">
            <ion-input
              type="time"
              formControlName="lastSlopeControl"
              class="sis-global-input"
              [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.lastSlopeControl?.errors }"
              required
            ></ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </ion-col>
</ion-row>

<!-- Small Screen -->
<ng-container *ngIf="!(this.bigScreenMode$ | async)">
  <ion-row class="sis-location-row-small">
    <ion-col class="sis-subtitle-text-location sis-global-center">{{ this.editableItem.location }}</ion-col>
  </ion-row>
  <form [formGroup]="this.formGroup">
    <ion-row class="sis-time-row-small">
      <ion-col class="sis-subtitle-text">{{ 'slopesOperatingTimes.term.firstRide' | translate }}</ion-col>
      <ion-col class="sis-operating-times-input-small">
        <ion-input
          type="time"
          formControlName="firstRide"
          class="sis-global-input"
          [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.firstRide?.errors }"
          required
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="sis-time-row-small">
      <ion-col class="sis-subtitle-text">{{ 'slopesOperatingTimes.term.lastRide' | translate }}</ion-col>
      <ion-col class="sis-operating-times-input-small">
        <ion-input
          type="time"
          formControlName="lastRide"
          class="sis-global-input"
          [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.lastRide?.errors }"
          required
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="sis-time-row-small">
      <ion-col class="sis-subtitle-text">{{ 'slopesOperatingTimes.term.lastSlopeControl' | translate }}</ion-col>
      <ion-col class="sis-operating-times-input-small">
        <ion-input
          type="time"
          formControlName="lastSlopeControl"
          class="sis-global-input"
          [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.lastSlopeControl?.errors }"
          required
        ></ion-input>
      </ion-col>
    </ion-row>
  </form>
</ng-container>
