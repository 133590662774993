/// <reference types="@types/ckeditor" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CKEditor4 } from 'ckeditor4-angular';
import { combineLatest, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlobStorageContainerService } from 'src/app/core/blob-storage/blob-storage-container.service';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { MediaCenterSlideUpdatedEvent } from 'src/app/core/eventbus/events';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { VideoBrowseModal } from 'src/app/media-center//video-browse/video-browse.component';
import { CKEditorService } from 'src/app/media-center/ckeditor/ckeditor.service';
import { CKEditorQueryParams } from 'src/app/media-center/ckeditor/ckeditor-queryparams.model';
import { ImageBrowseModal } from 'src/app/media-center/image-browse/image-browse.component';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';
import { environment } from 'src/environments/environment';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-media',
  templateUrl: './ckeditor.page.html',
  styleUrls: ['./ckeditor.page.scss'],
})
export class CKEditorPage extends EventBusSubscriberBase implements OnInit {
  slide: Slide;
  ckEditorData: string;
  templateUrl: string;
  config: CKEDITOR.config;

  @ViewChild('nameInput', { static: false }) nameInput: IonInput;

  slideExists = false;
  slideNameIsEmpty = true;
  slideChanged = false;

  private slides: Slide[] = [];
  private editor: any;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService,
    private ckEditorService: CKEditorService,
    private slidesService: MediaCenterSlidesService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private blobStorageContainerService: BlobStorageContainerService,
    eventBus: EventBusService
  ) {
    super(eventBus);

    this.slide = new Slide();

    this.observe(MediaCenterSlideUpdatedEvent, (event) => {
      if (event.slide && event.slide.guid === this.slide.guid && !event.removed) {
        this.slideChanged = !event.success;
      }
    });

    combineLatest([this.destinationService.selectedTenant$, this.slidesService.slides$, this.activatedRoute.queryParams])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async ([tenant, slides, queryParams]) => {
        this.slides = slides;
        this.slide.guid = (queryParams as CKEditorQueryParams).slide ?? this.slide.guid;
        this.templateUrl = `${environment.baseUrlMediaCenterBlobStorage}/${tenant.sisId}/template.js`;
        await this.setSlideData();
      });
  }

  ngOnInit(): void {
    setTimeout(() => this.nameInput.setFocus(), 100);

    // Subscribe to "mediaCenterBlobStorageUpdatedEvent" to display a toast about successful or failed video conversion without staying in upload mode.
    this.blobStorageContainerService.mediaCenterBlobStorageUpdatedEvent$.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  setEditor(event: CKEditor4.Editor): void {
    this.editor = event.editor;

    const imgElements = document.getElementsByClassName('cke_button__image');
    if (imgElements) {
      (imgElements[0] as HTMLElement).onclick = () => {
        this.showImageBrowseModal();
      };
    }

    const videoElements = document.getElementsByClassName('cke_button__video');
    if (videoElements) {
      (videoElements[0] as HTMLElement).onclick = () => {
        this.showVideoBrowseModal();
      };
    }
  }

  save(slideName: string): void {
    if (!this.slide.guid) {
      this.slide.guid = v4guid();
    }

    this.saveSlide(slideName, this.slide.guid);
    this.slide.name = slideName;
    this.slideChanged = false;
  }

  close(): void {
    if (this.slideChanged) {
      this.confirmationDialogService.presentAlert('mediacenter.phrase.exitWithoutSaving', 'mediacenter.term.exit').then((confirm) => {
        if (confirm) {
          this.navigateToMediaCenter();
        }
      });
    } else {
      this.navigateToMediaCenter();
    }
  }

  slideChangedEvent(): void {
    this.slideChanged = '<!DOCTYPE html>' + this.editor.getData() !== this.slide.data;
  }

  slideNameChangedEvent(slideName: string): void {
    this.slideChanged = true;
    this.slideExists = this.slides.find((s) => s.name === slideName && s.guid != this.slide.guid) != null;
    this.slideNameIsEmpty = slideName.trim().length === 0;
  }

  showSaveAsCopyModal(slideName: string): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.alertCtrl
        .create({
          header: await this.getTranslatedString('general.term.saveAs'),
          subHeader: '',
          inputs: [
            {
              name: 'slideName',
              type: 'text',
              value: `${slideName} ${await this.getTranslatedString('general.term.copy')}`,
            },
          ],
          buttons: [
            {
              text: await this.getTranslatedString('general.term.save'),
              handler: (data) => {
                const slideExists = this.slides.find((s) => s.name === data.slideName) != null;
                if (slideExists) {
                  this.showSlideAlreadyExistsAlert(slideName);
                } else {
                  this.saveSlide(data.slideName, v4guid());
                }
                resolve();
              },
            },
            {
              text: await this.getTranslatedString('general.term.cancel'),
              role: 'cancel',
            },
          ],
          backdropDismiss: false,
        })
        .then((modal) => {
          modal.present();
        });
    });
  }

  private async setSlideData(): Promise<void> {
    if (!this.slide.guid || !this.slides) {
      const defaultStyle =
        `<link href="${environment.baseUrlMediaCenterBlobStorage}/general/ckeditor/style.css" rel="stylesheet" />` +
        '<style id="default-margin" type="text/css">body { background: white }</style>';
      this.ckEditorData = defaultStyle + '<p></p>';
      this.slide.data = this.ckEditorData;
      return;
    }

    const slide = this.slides.find((s) => s.guid === this.slide.guid);

    if (!slide?.canEdit) {
      this.navigateToMediaCenter();
      return;
    }

    if (slide) {
      this.slide.url = slide.url;
      this.slide.name = slide.name;
      this.slideNameIsEmpty = slide.name.trim().length === 0;
      const data = await firstValueFrom(this.ckEditorService.requestHtmlFile(`${this.slide.url}?${Math.random() * 10000}`));
      this.ckEditorData = data;
      this.slide.data = data;
    }
  }

  private saveSlide(slideName: string, slideGuid: string): void {
    const data = '<!DOCTYPE html>' + this.editor.getData();
    const contentType = this.embeddedContentType(data);

    this.slidesService.uploadSlide(slideName, slideGuid, data, contentType);
  }

  private embeddedContentType(data: string) {
    const parser = new DOMParser();
    const html = parser.parseFromString(data, 'text/html');
    if (html.getElementById('youtube') != null) {
      return 'youtube';
    }
    if (html.getElementById('video') != null) {
      return 'video';
    }
    return 'html';
  }

  private navigateToMediaCenter(): void {
    this.router.navigate(['slides'], {
      relativeTo: this.activatedRoute.parent,
      queryParams: {
        slide: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private showImageBrowseModal(): void {
    this.modalCtrl
      .create({
        component: ImageBrowseModal,
        cssClass: 'sis-image-browse-modal',
      })
      .then((modal) => {
        modal.onDidDismiss().then((url) => {
          if (url && url.data != null && url.data.indexOf('.mp4') === -1) {
            this.editor.insertHtml(`<img src="${encodeURI(url.data)}">`);
          }
        });
        modal.present();
      });
  }

  private showVideoBrowseModal(): void {
    this.modalCtrl
      .create({
        component: VideoBrowseModal,
        cssClass: 'sis-video-browse-modal',
      })
      .then((modal) => {
        modal.onDidDismiss().then((url) => {
          if (url && url.data?.videoUrl != null && url.data?.videoUrl.indexOf('.mp4') !== -1) {
            this.editor.insertHtml(
              `<video id="video" width="${url.data.videoWidth}" height="${url.data.videoHeight}" autoplay="true" muted="true">
              <source src="${encodeURI(url.data.videoUrl).replace('.mp4', '.mpd')}" type="application/dash+xml">
              <source src="${encodeURI(url.data.videoUrl)}" type="video/mp4"></video>`
            );
          }
        });
        modal.present();
      });
  }

  private showSlideAlreadyExistsAlert(slideName: string): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.alertCtrl
        .create({
          header: await this.getTranslatedString('mediacenter.phrase.slideExists'),
          subHeader: '',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.showSaveAsCopyModal(slideName);
                resolve();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  private getTranslatedString(translateKey: string): Promise<string> {
    return firstValueFrom(this.translateService.get(translateKey));
  }
}
