import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { OperatingInfoDefaultTitle } from 'src/app/operating-info/domain/operating-info-default-title.model';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoPriority } from 'src/app/operating-info/domain/operating-info-priority';
import { OperatingInfoText } from 'src/app/operating-info/domain/operating-info-text.model';
import { OperatingInfoService } from 'src/app/operating-info/operating-info.service';

@Component({
  selector: 'sis-operating-info-entry-edit',
  templateUrl: './sis-operating-info-entry-edit.component.html',
  styleUrls: ['./sis-operating-info-entry-edit.component.scss'],
})
export class OperatingInfoEntryEditComponent extends Unsubscriber implements OnInit {
  oldItem: OperatingInfoEntry;
  libraryItem: OperatingInfoEntry;
  bigScreenMode: boolean;
  defaultTitles: OperatingInfoDefaultTitle[];
  hasDefaultTitle: boolean;
  priorities: OperatingInfoPriority[];
  formGroup: FormGroup;
  formControls: {
    [key: string]: AbstractControl;
  };

  @Input('operatingInfoLibraryEntry') operatingInfoLibraryEntry: OperatingInfoEntry;

  constructor(
    private operatingInfoService: OperatingInfoService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService
  ) {
    super();

    this.formGroup = new FormGroup({
      descriptionDe: new FormControl<string>('', Validators.required),
      titleDe: new FormControl<string>({ value: '', disabled: true }, Validators.required),
      textDe: new FormControl<string>('', Validators.required),
    });

    this.formControls = this.formGroup.controls;

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const changedItem = this.formGroup.getRawValue();
      if (this.libraryItem) {
        const textDe = this.libraryItem.text?.find((t) => t.language === 'de');
        if (textDe) {
          textDe.description = changedItem.descriptionDe;
          textDe.text = changedItem.textDe;
          textDe.title = changedItem.titleDe;
        }
      }
    });
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.operatingInfoService.operatingInfoRequestData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.defaultTitles = data.defaultTitles;
      this.hasDefaultTitle = data.defaultTitles?.length > 0;
      this.priorities = data.priorities.filter((p) => p.priority != 0);

      if (this.hasDefaultTitle) {
        this.formGroup.controls['titleDe'].disable();
      } else {
        this.formGroup.controls['titleDe'].enable();
      }

      this.setItem(this.operatingInfoLibraryEntry);
    });
  }

  async insert(): Promise<void> {
    if (this.formGroup.valid) {
      this.libraryItem.color = this.priorities.find((p) => p.priority === this.libraryItem.priority).color;
      const success = await this.operatingInfoService.updateLibrary(
        this.libraryItem,
        OperatingInfoService.insertOperation
      );
      if (success) {
        this.close();
      }
    }
  }

  async update(): Promise<void> {
    if (this.formGroup.valid) {
      this.libraryItem.color = this.priorities.find((p) => p.priority === this.libraryItem.priority).color;
      const success = await this.operatingInfoService.updateLibrary(
        this.libraryItem,
        OperatingInfoService.updateOperation
      );
      if (success) {
        this.close();
      }
    }
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  private setItem(item: OperatingInfoEntry): void {
    this.oldItem = item;

    if (this.oldItem) {
      const text: OperatingInfoText[] = [];
      this.oldItem.text.forEach((t) => {
        text.push({
          language: t.language,
          text: t.text,
          title: t.title,
          description: t.description,
        });
      });

      this.libraryItem = {
        color: this.oldItem.color,
        guid: this.oldItem.guid,
        priority: this.oldItem.priority,
        text: text,
        active: this.oldItem.active,
      };
    } else {
      const language = this.translateService.currentLang;
      let defaultTitle: OperatingInfoDefaultTitle;

      if (this.defaultTitles) {
        defaultTitle = this.defaultTitles.find((t) => t.language === language) ?? this.defaultTitles[0];
      }

      this.libraryItem = {
        priority: 1,
        active: false,
        text: [
          {
            language: defaultTitle?.language ?? 'de',
            title: defaultTitle?.title ?? '',
          },
        ],
      };
    }
    this.setFormValues(this.libraryItem);
  }

  private setFormValues(operatingInfoEntry: OperatingInfoEntry): void {
    const textDe = operatingInfoEntry.text.find((t) => t.language === 'de');

    if (textDe) {
      this.formGroup?.setValue({
        titleDe: textDe.title ?? '',
        textDe: textDe.text ?? '',
        descriptionDe: textDe.description ?? '',
      });
    }
  }
}
