import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Category } from 'src/app/infotext/domain/category.model';
import { Infotext } from 'src/app/infotext/domain/infotext.model';
import { InfotextService } from 'src/app/infotext/infotext.service';
import { TexteditorModal } from 'src/app/infotext/texteditor-modal/texteditor-modal';

@Component({
  templateUrl: './infotext.page.html',
  styleUrls: ['./infotext.page.scss'],
})
export class InfotextPage extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.SISMEDIA_INFOTEXT, FeatureAccessLevel.WRITE);

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  infotexts: Infotext[];
  categories: Category[] = [];
  selectedTab: Category;
  writePermission: boolean;
  bigScreenMode: boolean;
  featureId=FeatureId.SISMEDIA_INFOTEXT

  constructor(
    private screenSizeService: ScreenSizeService,
    private modalCtrl: ModalController,
    private infotextService: InfotextService,
    private destinationService: DestinationService,
    private logbookModalService: LogbookModalService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.infotextService.categories$
      .pipe(
        switchMap((categories) =>
          this.translateService.onLangChange.pipe(
            startWith([]),
            map(() => {
              categories.forEach((category) => {
                category.translatedCategory = this.infotextService.getValueByLang(category, 'category');
                category.infotexts.forEach((infotext) => {
                  infotext.translatedText = this.infotextService.getValueByLang(infotext, 'text');
                  infotext.translatedTitle = this.infotextService.getValueByLang(infotext, 'title');
                });
              });
              return categories;
            })
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((categories) => {
        this.categories = categories.sort((a, b) => a.id - b.id);
        if (this.categories && this.categories.length > 0) {
          if (!this.categories.includes(this.selectedTab)) {
            this.selectedTab = this.categories[0];
          }
          this.infotexts = this.selectedTab.infotexts.sort((a, b) => a.textOrder - b.textOrder);
        }
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(InfotextPage.requiredFeature)) ?? false;
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  selectionChanged(category: Category): void {
    this.infotexts = category.infotexts;
    this.selectedTab = category;
  }

  showTextEditor(selectedText: Infotext): void {
    if (!this.writePermission) {
      return;
    }

    this.modalCtrl
      .create({
        component: TexteditorModal,
        cssClass: 'sis-infotext-modal',
        backdropDismiss: false,
        componentProps: {
          infotext: selectedText,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }
}
