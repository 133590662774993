import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { filter, firstValueFrom, map, takeUntil } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { TimetableJourneyUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { TimetableItem } from 'src/app/timetable/domain/timetable-item.model';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableStation } from 'src/app/timetable/domain/timetable-station.model';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  selector: 'sis-timetable-journey-row',
  templateUrl: './journey-row.component.html',
  styleUrls: ['./journey-row.component.scss'],
})
export class JourneyRowComponent extends Unsubscriber implements OnInit, OnChanges {
  @Input() journey: TimetableJourney;
  @Input() stations: TimetableStation[];

  @Output() openJourneyModalClick = new EventEmitter<TimetableJourney>();

  timetableItems: TimetableItem[] = [];

  readonly writePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) =>
      features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.WRITE))
      )
    )
  );

  constructor(
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService,
    private timetableService: TimetableService,
    private eventBus: EventBusService
  ) {
    super();
  }

  ngOnInit(): void {
    this.eventBus
      .observe(TimetableJourneyUpdatedEvent)
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event.timetableJourney.guid === this.journey.guid)
      )
      .subscribe((event) => {
        this.journey = event.timetableJourney;
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    if (this.journey && this.stations) {
      this.timetableItems = this.stations.map((s) => this.journey.items?.find((i) => i.stationGuids.includes(s.guid)));
    }
  }

  async openJourneyModal(journey: TimetableJourney): Promise<void> {
    const writePermission = await firstValueFrom(this.writePermission$);
    if (writePermission) {
      this.openJourneyModalClick.emit(journey);
    }
  }

  async deleteJourney(journey: TimetableJourney): Promise<void> {
    const writePermission = await firstValueFrom(this.writePermission$);
    if (writePermission) {
      const confirmed = await this.confirmationDialogService.presentAlert(
        'timetable.phrase.deleteJourney',
        'general.term.delete'
      );

      if (confirmed) {
        await this.timetableService.deleteJourney(journey.guid);
      }
    }
  }
}
