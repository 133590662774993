<ion-button
  class="{{ this.buttonType }} {{ this.buttonAlign }}"
  (click)="this.onClick()"
  [disabled]="disabled"
  [ngStyle]="{ width: this.width }"
>
  <ion-label *ngIf="!this.showSpinner">{{ text | translate }}</ion-label>
  <ion-icon *ngIf="this.icon && !this.showSpinner" [name]="this.icon" slot="end"></ion-icon>
  <ion-label *ngIf="this.showSpinner">
    <ion-spinner name="dots"></ion-spinner>
  </ion-label>
</ion-button>
