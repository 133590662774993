import { BehaviorSubject, Observable } from 'rxjs';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { Export } from 'src/app/domain/export/export.model';
import { ParkingIconOptions } from 'src/app/parking/domain/parking-icon-options.model';
import { ParkingSetting } from 'src/app/parking/domain/parking-setting.enum';
import { ParkingStatus } from 'src/app/parking/domain/parking-status.enum';

export class Parking {
  private updating$: Map<ParkingSetting, BehaviorSubject<boolean>> = new Map<ParkingSetting, BehaviorSubject<boolean>>();

  readonly iconOptions: ParkingIconOptions[] = [
    {
      parkingStatus: ParkingStatus.Disabled,
      iconName: 'sis-disabled',
      colorDisabledClassName: 'sis-disabled-black',
      colorEnabledClassName: 'sis-black',
      tooltipTranslationString: 'parking.term.disabled',
      dataTestString: 'parking-state-disabled',
    },
    {
      parkingStatus: ParkingStatus.Closed,
      iconName: 'sis-closed',
      colorDisabledClassName: 'sis-disabled-red',
      colorEnabledClassName: 'sis-red',
      tooltipTranslationString: 'parking.term.closed',
      dataTestString: 'parking-state-closed',
    },
    {
      parkingStatus: ParkingStatus.Full,
      iconName: 'sis-full',
      colorDisabledClassName: 'sis-disabled-red',
      colorEnabledClassName: 'sis-red',
      tooltipTranslationString: 'parking.term.full',
      dataTestString: 'parking-state-full',
    },
    {
      parkingStatus: ParkingStatus.Free,
      iconName: 'sis-open',
      colorDisabledClassName: 'sis-disabled-green',
      colorEnabledClassName: 'sis-green',
      tooltipTranslationString: 'parking.term.free',
      dataTestString: 'parking-state-free',
    },
  ];

  guid: string;
  id: number;
  name: string;
  nameEn: string;
  label: string;
  info: string;
  custom: string;
  status?: ParkingStatus;
  currentOccupancy: number;
  maxCapacity: number;
  occupancyOffset: number;
  autoUpdate: boolean;
  lastStatusChange: Date;
  cockpitOrder: number;
  occupancyTimestamp: Date;

  exports: Export[];

  constructor(init?: Partial<WritablePart<Parking>>) {
    Object.assign(this, init);

    [ParkingSetting.status, ParkingSetting.edit, ParkingSetting.exportMappings].forEach((s) => {
      const updating = new BehaviorSubject<boolean>(false);
      this.updating$.set(s, updating);
    });
  }

  getUpdating(setting: ParkingSetting): Observable<boolean> {
    return this.updating$.get(setting).asObservable();
  }

  setUpdating(setting: ParkingSetting, value: boolean): void {
    this.updating$.get(setting).next(value);
  }
}
