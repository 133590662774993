import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { filter, takeUntil } from 'rxjs/operators';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingService } from 'src/app/parking/domain/parking.service';
import { ParkingIconOptions } from 'src/app/parking/domain/parking-icon-options.model';
import { ParkingSetting } from 'src/app/parking/domain/parking-setting.enum';
import { ParkingStatus } from 'src/app/parking/domain/parking-status.enum';
import { ParkingEditModalComponent } from 'src/app/parking/parking-item/parking-edit-modal/parking-edit-modal.component';
import { ParkingExportsEditModalComponent } from 'src/app/parking/parking-item/parking-exports-edit-modal/parking-exports-edit-modal.component';
import { ParkingOccupancyModalComponent } from 'src/app/parking/parking-item/parking-occupancy-modal/parking-occupancy-modal.component';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-parking-item',
  templateUrl: './parking-item.component.html',
  styleUrls: ['./parking-item.component.scss'],
})
export class ParkingItemComponent extends Unsubscriber implements OnInit, OnChanges {
  static readonly letterWidthMobileView = 11;
  static readonly letterWidthDesktopView = 23;

  @Input() item: Parking;
  @Input() bigScreenMode: boolean;
  @Input() showInfoCol: boolean;
  @Input() showButtons: boolean = true;
  @Input() labelLength: number;

  color: string;
  icon: string;
  writePermission: boolean;
  isStatusUpdating: boolean;
  hasExportsEditPermission: boolean = false;
  hasExports: boolean;
  exportTooltipText: string;
  showingLabelLength: string;

  private readonly requiredFeature = new Feature(FeatureId.PARKING, FeatureAccessLevel.WRITE);

  constructor(
    private parkingService: ParkingService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService
  ) {
    super();
  }

  ngOnInit() {
    if (this.item) {
      this.item
        .getUpdating(ParkingSetting.edit)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          const iconName = IconHelper.getIconName('parkleitsystem');
          this.icon = `${environment.baseUrlPublicBlobStorage}/public-assets/icons/sismedia/styles/sisag/${iconName.substring(4)}.svg`;
        });

      this.item
        .getUpdating(ParkingSetting.status)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isUpdating) => {
          this.isStatusUpdating = isUpdating;
          this.item.iconOptions.forEach((option) => {
            option.activeClasses = this.getStatusIconClasses(this.item, option);
          });
        });

      this.item
        .getUpdating(ParkingSetting.exportMappings)
        .pipe(
          takeUntil(this.onDestroy$),
          filter((updating) => !updating)
        )
        .subscribe(() => {
          this.hasExports = this.item.exports.length > 0;
          this.exportTooltipText = ':\n\n' + this.item.exports.map((x) => `- ${x.name}`).join('\n');
        });
    }

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature));

      this.userSettingsService.userSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((userSettings) => {
        this.hasExportsEditPermission = userSettings.isAdministrator;
      });

      this.item.iconOptions.forEach((option) => {
        option.activeClasses = this.getStatusIconClasses(this.item, option);
      });
    });
  }

  ngOnChanges() {
    this.item.iconOptions.forEach((option) => {
      option.activeClasses = this.getStatusIconClasses(this.item, option);
    });

    this.showingLabelLength = this.bigScreenMode
      ? `${this.labelLength * ParkingItemComponent.letterWidthDesktopView}px`
      : `${Math.min(3, this.labelLength) * ParkingItemComponent.letterWidthMobileView}px`;
  }

  iconClick(status: ParkingStatus): void {
    if (!this.writePermission || this.item.status === null || !this.showButtons || this.item.autoUpdate) {
      return;
    }

    if (this.item.status != status) {
      this.item.setUpdating(ParkingSetting.status, true);
      this.parkingService.updateStatus(this.item.guid, status);
    }
  }

  async openEditModal(): Promise<void> {
    if (this.writePermission) {
      const modal = await this.modalCtrl.create({
        component: ParkingEditModalComponent,
        componentProps: {
          parking: this.item,
        },
        cssClass: 'sis-parking-edit-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async openExportsEditModal() {
    if (this.hasExportsEditPermission) {
      const modal = await this.modalCtrl.create({
        component: ParkingExportsEditModalComponent,
        componentProps: {
          parking: this.item,
        },
        cssClass: 'sis-parking-exports-edit-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async openOccupancyEditModal() {
    if (this.writePermission) {
      const modal = await this.modalCtrl.create({
        component: ParkingOccupancyModalComponent,
        componentProps: {
          parking: this.item,
        },
        cssClass: 'sis-parking-occupancy-edit-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  private getStatusIconClasses(item: Parking, option: ParkingIconOptions): string[] {
    const statusIconClasses: string[] = [];
    if (item.status == null) {
      statusIconClasses.push('sis-no-status');
      return statusIconClasses;
    }

    item.status === option.parkingStatus
      ? statusIconClasses.push(option.colorEnabledClassName)
      : this.writePermission && this.showButtons && !item.autoUpdate
      ? statusIconClasses.push(option.colorDisabledClassName)
      : statusIconClasses.push('sis-disabled');

    if (this.writePermission && !item.autoUpdate && item.status === option.parkingStatus) {
      statusIconClasses.push('sis-global-default-cursor');
    }

    if (!this.writePermission || this.item.autoUpdate) {
      statusIconClasses.push('sis-not-allowed');
    }

    if (!this.showButtons) {
      statusIconClasses.push('sis-reduced-opacity');
      statusIconClasses.push('sis-global-default-cursor');
    }

    return statusIconClasses;
  }

  presentToast() {
    this.userMessageService.presentToast(
      new UserMessage({
        message: 'sismedia.term.noManualSwitching',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'bottom',
        color: UserMessageColor.blue,
      })
    );
  }
}
