import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { filter, takeUntil, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EcoModeSettingsUpdatedEvent, RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { RopewayEcoModeSettings } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.model';
import { RopewayEcoModeSettingsService } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.service';

@Component({
  selector: 'sis-ecomode-settings-modal',
  templateUrl: './ropeway-ecomode-settings-modal.component.html',
  styleUrls: ['./ropeway-ecomode-settings-modal.component.scss'],
})
export class RopewayEcoModeSettingsModal extends Unsubscriber implements OnInit {
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  editableSettings: RopewayEcoModeSettings;
  edited = false;
  saving = false;
  formGroup: FormGroup;
  thresholdLowText: string;
  thresholdMediumText: string;
  maxQuantityPerMinute: string;

  @Input() ropewayEcoModeSettings: RopewayEcoModeSettings;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private ropewayEcoModeSettingsService: RopewayEcoModeSettingsService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        speedEmpty: new FormControl<number>(this.ropewayEcoModeSettings.speedEmpty, [Validators.required]),
        speedLow: new FormControl<number>(this.ropewayEcoModeSettings.speedLow, [Validators.required]),
        speedMedium: new FormControl<number>(this.ropewayEcoModeSettings.speedMedium, [Validators.required]),
        speedHigh: new FormControl<number>(this.ropewayEcoModeSettings.speedHigh, [Validators.required]),
        thresholds: new FormControl<{ lower: number; upper: number }>(
          { lower: this.ropewayEcoModeSettings.thresholdLow, upper: this.ropewayEcoModeSettings.thresholdMedium },
          [Validators.required]
        ),
      },
      {
        validators: [this.validateSpeedInputs()],
      }
    );

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
      this.editableSettings.speedEmpty = Number.parseFloat(values.speedEmpty);
      this.editableSettings.speedLow = Number.parseFloat(values.speedLow);
      this.editableSettings.speedMedium = Number.parseFloat(values.speedMedium);
      this.editableSettings.speedHigh = Number.parseFloat(values.speedHigh);
      this.editableSettings.thresholdLow = Number.parseFloat(values.thresholds.lower);
      this.editableSettings.thresholdMedium = Number.parseFloat(values.thresholds.upper);
      this.edited = this.isEdited();
      this.setText();
    });

    this.eventBus
      .observe(EcoModeSettingsUpdatedEvent)
      .pipe(
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.changedBy === userSettings.userGuid),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([event]) => {
        if (
          event.ropewayEcoModeSettings.ropewayGuid === this.ropewayEcoModeSettings.ropewayGuid &&
          this.saving &&
          event.updateSuccessful
        ) {
          this.close();
        }
        this.saving = false;
      });

    this.eventBus
      .observe(RopewaySettingsUpdatedEvent)
      .pipe(
        filter((event) => event.ropewaySettings.guid === this.ropewayEcoModeSettings.ropewayGuid),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event) => {
        this.editableSettings.maxTransportQuantityPerHour = event.ropewaySettings.maxTransportQuantityPerHour;
        this.setText();
      });

    this.reset();
  }

  reset(): void {
    this.editableSettings = { ...this.ropewayEcoModeSettings };

    this.formGroup.controls.thresholds.setValue({
      lower: this.ropewayEcoModeSettings.thresholdLow,
      upper: this.ropewayEcoModeSettings.thresholdMedium,
    });

    this.formGroup.controls.speedEmpty.setValue(this.ropewayEcoModeSettings.speedEmpty);
    this.formGroup.controls.speedLow.setValue(this.ropewayEcoModeSettings.speedLow);
    this.formGroup.controls.speedMedium.setValue(this.ropewayEcoModeSettings.speedMedium);
    this.formGroup.controls.speedHigh.setValue(this.ropewayEcoModeSettings.speedHigh);

    this.edited = false;
    this.setText();
  }

  save(): void {
    if (!this.saving) {
      if (!this.edited) {
        this.close();
      } else {
        this.ropewayEcoModeSettingsService.saveRopewayEcoModeSettings(this.editableSettings).subscribe();

        this.saving = true;
      }
    }
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  pinFormatter(value: number) {
    return `${value}%`;
  }

  private setText(): void {
    this.thresholdLowText = `${MathUtils.roundTo(
      ((this.editableSettings.maxTransportQuantityPerHour / 60) * this.editableSettings.thresholdLow) / 100,
      1
    )} Pers/min`;
    this.thresholdMediumText = `${MathUtils.roundTo(
      ((this.editableSettings.maxTransportQuantityPerHour / 60) * this.editableSettings.thresholdMedium) / 100,
      1
    )} Pers/min`;
    this.maxQuantityPerMinute = MathUtils.roundTo(this.editableSettings.maxTransportQuantityPerHour / 60, 1);
  }

  private isEdited(): boolean {
    return (
      this.editableSettings.speedEmpty !== this.ropewayEcoModeSettings.speedEmpty ||
      this.editableSettings.speedLow !== this.ropewayEcoModeSettings.speedLow ||
      this.editableSettings.speedMedium !== this.ropewayEcoModeSettings.speedMedium ||
      this.editableSettings.speedHigh !== this.ropewayEcoModeSettings.speedHigh ||
      this.editableSettings.thresholdLow !== this.ropewayEcoModeSettings.thresholdLow ||
      this.editableSettings.thresholdMedium !== this.ropewayEcoModeSettings.thresholdMedium
    );
  }

  private validateSpeedInputs(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const speedEmptyControl = control.get('speedEmpty');
      const speedLowControl = control.get('speedLow');
      const speedMediumControl = control.get('speedMedium');
      const speedHighControl = control.get('speedHigh');

      const speedEmpty = Number.parseFloat(speedEmptyControl.value);
      const speedLow = Number.parseFloat(speedLowControl.value);
      const speedMedium = Number.parseFloat(speedMediumControl.value);
      const speedHigh = Number.parseFloat(speedHighControl.value);

      const valid =
        !isNaN(speedEmpty) &&
        !isNaN(speedLow) &&
        !isNaN(speedMedium) &&
        !isNaN(speedHigh) &&
        speedEmpty <= speedLow &&
        speedLow <= speedMedium &&
        speedMedium <= speedHigh;

      return valid ? null : { speed: true };
    };
  }
}
