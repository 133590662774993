import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { filter, takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { AlarmlistModalComponent } from 'src/app/alarmlist/alarmlist-modal/alarmlist-modal.component';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { ApiConnectionEvent, InternetConnectionEvent, MaintenanceUpdatedEvent } from 'src/app/core/eventbus/events';
import { AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { NewsService } from 'src/app/domain/news/news.service';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { UserService } from 'src/app/domain/user/user.service';

@Component({
  selector: 'sis-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends EventBusSubscriberBase implements OnInit {
  title: string;
  apiConnected = true;
  internetConnected = true;
  isAlarmlistPage = false;
  isGlobalPage = false;
  activeAlarmCount: number;
  underMaintenance = false;
  maintenanceBannerHidden = false;
  isUserAuthenticated = false;

  readonly hasUnreadNews$ = this.newsService.hasUnreadNews$;

  readonly alarmIconLevels = [AlarmLevel.ALARM, AlarmLevel.FAULT, AlarmLevel.SYSTEM];
  readonly warningIconLevels = [AlarmLevel.WARNING];

  private alarmlistModal: HTMLIonModalElement;
  private selectedTenant: Tenant;

  constructor(
    private userService: UserService,
    private destinationService: DestinationService,
    private modalCtrl: ModalController,
    private router: Router,
    private alarmListService: AlarmlistService,
    private newsService: NewsService,
    eventBus: EventBusService
  ) {
    super(eventBus);
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: NavigationEnd) => {
        this.isGlobalPage = event.url.endsWith(SisRoute.GLOBAL);
        this.isAlarmlistPage = event.url.endsWith(SisRoute.ALARMLIST);
      });

    this.observe<ApiConnectionEvent>(ApiConnectionEvent, (event: ApiConnectionEvent) => {
      this.apiConnected = event.apiConnected;
    });

    this.observe<InternetConnectionEvent>(InternetConnectionEvent, (event: InternetConnectionEvent) => {
      this.internetConnected = event.internetConnected;
    });

    this.observe<MaintenanceUpdatedEvent>(MaintenanceUpdatedEvent, (event) => {
      const wasUnderMaintenance = this.underMaintenance;
      this.underMaintenance =
        event && this.selectedTenant && event.tenantSisIdsUnderMaintenance.includes(this.selectedTenant.gatewayId);

      if (this.underMaintenance && wasUnderMaintenance != this.underMaintenance) {
        this.maintenanceBannerHidden = false;
      }
    });

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedTenant) => {
      this.selectedTenant = selectedTenant;
      this.title = selectedTenant?.alias;
      this.maintenanceBannerHidden = false;
      this.underMaintenance = selectedTenant?.underMaintenance ?? false;
    });

    this.userService.isAuthenticated$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((authenticated) => (this.isUserAuthenticated = authenticated));

    this.alarmListService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      const activeAlarms = AlarmlistService.filterActive(alarms);
      const filteredAlarms = activeAlarms.filter(
        (a) =>
          a.level === AlarmLevel.FAULT ||
          a.level === AlarmLevel.ALARM ||
          a.level === AlarmLevel.WARNING ||
          a.level === AlarmLevel.SYSTEM
      );

      this.activeAlarmCount = filteredAlarms.length;
    });
  }

  hideMaintenanceBanner(): void {
    this.maintenanceBannerHidden = true;
  }

  async alarmListIconOnClick(event: Event): Promise<void> {
    event.stopPropagation();
    if (this.isAlarmlistPage) {
      return Promise.resolve();
    }

    if (this.alarmlistModal) {
      this.dismissAlarmlistModal();
      return Promise.resolve();
    }

    this.alarmlistModal = await this.modalCtrl.create({
      component: AlarmlistModalComponent,
      cssClass: 'sis-alarmlist-modal',
    });

    return this.alarmlistModal.present();
  }

  hideModals(): void {
    if (this.alarmlistModal) {
      this.dismissAlarmlistModal();
    }
  }

  reloadApp(): void {
    window.location.reload();
  }

  private dismissAlarmlistModal(): void {
    this.alarmlistModal.dismiss();
    this.alarmlistModal = undefined;
  }
}
