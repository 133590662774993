<svg
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  viewBox="0 0 187.5 225"
>
  <g transform="matrix(5.94,0,0,5.94,-43.5,0) scale(0.55) translate(18, 4)" id="g7190">
    <rect id="Frame" x="0" y="0" width="50" height="50" visibility="hidden" />
    <g id="Icon" fill="#91cddb" style="stroke: none" transform="matrix(0.9,0,0,0.9,1,10)">
      <path
        id="line7143"
        d="m 29.494141,8.2851562 a 1.10011,1.10011 0 0 0 -1.083985,1.1152344 v 8.5000004 a 1.10011,1.10011 0 1 0 2.199219,0 V 9.4003906 A 1.10011,1.10011 0 0 0 29.494141,8.2851562 Z"
      />
      <path
        id="line7145"
        d="m 33.154297,6.1738281 a 1.10011,1.10011 0 0 0 -1.083985,1.1152344 v 8.4999995 a 1.10011,1.10011 0 1 0 2.199219,0 V 7.2890625 A 1.10011,1.10011 0 0 0 33.154297,6.1738281 Z"
      />
      <path
        id="line7147"
        d="m 36.783203,4.0742188 a 1.10011,1.10011 0 0 0 -1.083984,1.1152343 v 8.4999999 a 1.100586,1.100586 0 1 0 2.201172,0 V 5.1894531 A 1.10011,1.10011 0 0 0 36.783203,4.0742188 Z"
      />
      <path
        id="line7149"
        d="m 40.404297,1.984375 a 1.10011,1.10011 0 0 0 -1.083985,1.1152344 v 8.4999996 a 1.10011,1.10011 0 1 0 2.199219,0 V 3.0996094 A 1.10011,1.10011 0 0 0 40.404297,1.984375 Z"
      />
      <path
        id="line7151"
        d="M 44.033203,-0.11523438 A 1.10011,1.10011 0 0 0 42.949219,1 v 8.5 a 1.100586,1.100586 0 1 0 2.201172,0 V 1 a 1.10011,1.10011 0 0 0 -1.117188,-1.11523438 z"
      />
      <path
        id="polygon7153"
        d="M 44.050781,-0.09960937 A 1.10011,1.10011 0 0 0 43.097656,1.5507812 l 3.689453,6.3808594 a 1.10011,1.10011 0 0 0 1.910157,-0.00977 l 3.61914,-6.3789062 a 1.10011,1.10011 0 0 0 -0.957031,-1.64257817 z m 1.90625,2.19921877 h 3.513672 l -1.740234,3.0683594 z"
      />
      <path
        id="polygon7155"
        d="m 25.912109,10.480469 a 1.10011,1.10011 0 0 0 -1,0.542969 L 21.201172,17.34375 A 1.10011,1.10011 0 0 0 22.150391,19 h 7.359375 a 1.10011,1.10011 0 0 0 0.953125,-1.650391 L 26.8125,11.029297 a 1.10011,1.10011 0 0 0 -0.900391,-0.548828 z m -0.06055,3.285156 1.753907,3.035156 h -3.535157 z"
      />
      <path
        id="path7157"
        d="m 5.8242188,22.445312 c -1.6370703,0 -2.9525041,1.031198 -3.4921876,2.333985 -0.5396834,1.302786 -0.3373901,2.961494 0.8203126,4.119141 A 1.10011,1.10011 0 1 0 4.7070312,27.341797 C 4.1762472,26.811039 4.1321794,26.183684 4.3652344,25.621094 4.5982893,25.058503 5.073756,24.644531 5.8242188,24.644531 c 0.7504627,0 1.2278825,0.413972 1.4609374,0.976563 0.233055,0.56259 0.1870341,1.189945 -0.34375,1.720703 a 1.1007113,1.1007113 0 1 0 1.5566407,1.556641 C 9.6557495,27.740791 9.8560897,26.082083 9.3164062,24.779297 8.7767228,23.47651 7.461289,22.445312 5.8242188,22.445312 Z"
      />
      <path
        id="path7159"
        d="m 5.8300781,16.539062 c -1.0172428,10e-7 -1.5969515,0.607996 -2.1425781,1.203126 -0.5456266,0.595129 -1.0381817,1.315318 -1.4765625,2.02539 -0.8684002,1.4066 -1.50140869,2.7628 -1.51367188,2.789063 0,0 -0.003863,0.0097 -0.003906,0.0098 -0.007944,0.01487 -0.73730784,1.381576 -0.78320313,2.986328 -0.02307262,0.806747 0.1460192,1.764535 0.8359375,2.517578 0.68991821,0.753044 1.79204361,1.150391 3.18359371,1.150391 a 1.100586,1.100586 0 1 0 0,-2.201172 c -1.0034499,0 -1.3795277,-0.237965 -1.5605469,-0.435547 C 2.1881214,26.386403 2.0942445,26.077941 2.1074219,25.617188 2.1337766,24.69568 2.6640625,23.542969 2.6640625,23.542969 a 1.10011,1.10011 0 0 0 0.011719,-0.02734 c 0,0 0.6149647,-1.306941 1.4082032,-2.591797 0.3966192,-0.642428 0.840236,-1.276066 1.2246094,-1.695312 0.3843733,-0.419246 0.7287271,-0.488282 0.5214843,-0.488282 -0.1891358,0 0.168392,0.07936 0.5664063,0.488282 0.3980143,0.408918 0.8608604,1.020954 1.2753906,1.644531 0.8290603,1.247154 1.4785156,2.517578 1.4785156,2.517578 a 1.10011,1.10011 0 0 0 0.00977,0.01953 c 0,0 0.5839812,1.193723 0.6191407,2.136719 0.01758,0.471498 -0.072552,0.778532 -0.2792969,0.994141 -0.2067452,0.215608 -0.660834,0.478515 -1.7792969,0.478515 a 1.100586,1.100586 0 1 0 0,2.201172 c 1.4865371,0 2.6336202,-0.391233 3.3671882,-1.15625 0.733567,-0.765016 0.921795,-1.763607 0.890625,-2.599609 -0.06167,-1.653937 -0.85188,-3.043971 -0.869141,-3.074219 l -0.0039,-0.0078 C 11.10168,22.375428 10.41734,21.030325 9.5039169,19.656266 9.0459364,18.967327 8.532767,18.268816 7.9726562,17.693359 7.4125455,17.117903 6.8292139,16.539062 5.8300781,16.539062 Z"
      />
      <path
        id="polyline7161"
        d="m 8.8300781,19.560547 a 1.10011,1.10011 0 1 0 0,2.199219 h 2.9902339 l 2.572266,1.335937 a 1.10011,1.10011 0 1 0 1.013672,-1.951172 l -2.808594,-1.460937 a 1.10011,1.10011 0 0 0 -0.507812,-0.123047 z"
      />
      <path
        id="polyline7163"
        d="m 10.349609,22.441406 a 1.10011,1.10011 0 0 0 -0.6816402,1.919922 l 2.4882812,2.279297 a 1.10011,1.10011 0 0 0 0.744141,0.289063 h 2 a 1.10011,1.10011 0 1 0 0,-2.199219 h -1.572266 l -2.175781,-1.992188 a 1.10011,1.10011 0 0 0 -0.802735,-0.296875 z"
      />
      <polygon
        style="fill: none; stroke: #989898; stroke-width: 2.20000005px"
        points="36.89,24.58 49.89,23.6 49.89,20.75 19.39,20.75 19.39,12.51 20.39,8.02 15.72,8.02 15.72,28.74 14.72,28.74 14.72,32.52 20.39,32.52 20.39,28.74 19.39,28.74 19.39,25.74 24.1,25.74 26.1,29.74 30.18,29.74 29.01,25.74 36.89,25.74 "
        id="polygon7165"
      />
    </g>
  </g>
</svg>
