import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Recipient } from 'src/app/email-delivery/domain/recipient.model';
import { EmailDeliveryService } from 'src/app/email-delivery/email-delivery.service';

@Component({
  selector: 'sis-recipient-item',
  templateUrl: './recipient-item.component.html',
  styleUrls: ['./recipient-item.component.scss'],
})
export class RecipientItemComponent extends Unsubscriber implements OnInit {
  @Input() recipient: Recipient;
  @Input() controlsEnabled: boolean;
  @Input() isEditing: boolean;

  @Output() editing = new EventEmitter();

  bigScreenMode: boolean;
  recipientFormsGroup: FormGroup;
  nameInputInvalid: boolean;
  emailInputInvalid: boolean;
  saveButtonDisabled: boolean;
  locationTranslation: string;
  showSpinner = false;

  constructor(
    private emailDeliveryService: EmailDeliveryService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.recipientFormsGroup = new FormGroup({
      name: new FormControl<string>(this.recipient?.name, [Validators.required]),
      location: new FormControl<string>(this.recipient?.location),
      email: new FormControl<string>(this.recipient?.email, [Validators.required, Validators.email]),
    });

    this.recipientFormsGroup.disable();

    this.recipientFormsGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.nameInputInvalid = this.recipientFormsGroup.controls.name.errors ? true : false;
      this.emailInputInvalid = this.recipientFormsGroup.controls.email.errors ? true : false;
      this.saveButtonDisabled = this.recipientFormsGroup.invalid || !this.anyValueChanged();
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    if (this.isEditing) {
      this.edit();
    }

    this.translateService
      .get('destination.term.location')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((translation) => (this.locationTranslation = translation));
  }

  private anyValueChanged(): boolean {
    return (
      this.recipientFormsGroup.controls.name.value !== this.recipient?.name ||
      this.recipientFormsGroup.controls.email.value !== this.recipient?.email ||
      this.recipientFormsGroup.controls.location.value !== this.recipient?.location
    );
  }

  edit() {
    this.recipientFormsGroup.enable();
    this.editing.emit(true);
  }

  async deleteRecipient(): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert('general.phrase.delete', 'general.term.delete');
    if (confirmed) {
      this.showSpinner = true;
      await this.emailDeliveryService.postRecipientUpsert(this.recipient, true);
      this.showSpinner = false;
    }
  }

  async upsertRecipient(): Promise<void> {
    if (this.saveButtonDisabled) {
      return;
    }

    this.showSpinner = true;

    const recipient = {
      guid: this.recipient?.guid,
      name: this.recipientFormsGroup.controls.name.value,
      location: this.recipientFormsGroup.controls.location.value,
      email: this.recipientFormsGroup.controls.email.value,
    };

    const success = await this.emailDeliveryService.postRecipientUpsert(recipient, false);
    if (success) {
      this.recipientFormsGroup.disable();
      this.editing.emit(false);
      this.recipient = recipient;
    }

    this.showSpinner = false;
  }

  cancelRecipientEdit() {
    this.recipientFormsGroup.disable();
    this.recipientFormsGroup.reset({
      name: this.recipient?.name,
      location: this.recipient?.location,
      email: this.recipient?.email,
    });
    this.editing.emit(false);
  }
}
