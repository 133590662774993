<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="this.oldItem">{{ 'breakdowninfo.phrase.editLibraryEntry' | translate }}</ion-title>
    <ion-title *ngIf="!this.oldItem">{{ 'breakdowninfo.phrase.newEntry' | translate }}</ion-title>
    <ion-button fill="clear" slot="end" (click)="this.close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <div class="sis-subtitle">{{ 'breakdowninfo.term.infoText' | translate }}</div>
    <ion-list *ngIf="this.libraryItem" lines="full" class="ion-no-margin ion-no-padding">
      <ion-item>
        <ion-label position="stacked">Deutsch</ion-label>
        <ion-input placeholder="Title DE" [(ngModel)]="libraryItem.titleDe"></ion-input>
        <ion-input placeholder="Text DE" [(ngModel)]="libraryItem.textDe"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">English</ion-label>
        <ion-input placeholder="Title EN" [(ngModel)]="libraryItem.titleEn"></ion-input>
        <ion-input placeholder="Text EN" [(ngModel)]="libraryItem.textEn"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Français</ion-label>
        <ion-input placeholder="Title FR" [(ngModel)]="libraryItem.titleFr"></ion-input>
        <ion-input placeholder="Text FR" [(ngModel)]="libraryItem.textFr"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Italiano</ion-label>
        <ion-input placeholder="Title IT" [(ngModel)]="libraryItem.titleIt"></ion-input>
        <ion-input placeholder="Text IT" [(ngModel)]="libraryItem.textIt"></ion-input>
      </ion-item>

      <div class="sis-subtitle" style="padding-top: 20px">{{ 'breakdowninfo.term.faultLevel' | translate }}</div>
      <ion-radio-group mode="md" [(ngModel)]="this.libraryItem.color">
        <ion-item lines="none">
          <ion-radio slot="start" [value]="this.green"></ion-radio>
          <div class="sis-state-color" [style.background-color]="this.green"></div>
          <ion-label>{{ 'breakdowninfo.term.operationOk' | translate }}</ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-radio slot="start" [value]="this.yellow"></ion-radio>
          <div class="sis-state-color" [style.background-color]="this.yellow"></div>
          <ion-label>{{ 'breakdowninfo.term.breakdown' | translate }}</ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      (sisClick)="this.oldItem ? this.update() : this.insert()"
      [disabled]="!this.validate()"
      [ngClass]="{ 'sis-global-button-padding-right': this.bigScreenMode }"
      data-test="breakdowninfo-library-item-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      (sisClick)="this.close()"
      data-test="breakdowninfo-library-item-cancel-btn"
    ></sis-general-button>
    <sis-general-button
      *ngIf="this.oldItem"
      [text]="'general.term.delete'"
      [btnType]="'Red'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      [btnAlign]="'Left'"
      (sisClick)="this.delete()"
      data-test="breakdowninfo-library-item-delete-btn"
    ></sis-general-button>
  </div>
</ion-footer>
