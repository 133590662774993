<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <ng-container *ngIf="this.ropeway">
    <ion-row>
      <ion-col class="sis-property">{{ 'general.term.constructedBy' | translate }}</ion-col>
      <ion-col class="sis-value" data-test="sis-ropway-settings-detailview-constructedBy">
        {{ this.ropeway.constructedBy }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="sis-property">{{ 'general.term.controllerType' | translate }}</ion-col>
      <ion-col class="sis-value" data-test="sis-ropway-settings-detailview-controllerType">
        {{ this.ropeway.controllerType }}
      </ion-col>
    </ion-row>
    <ng-container *ngIf="!this.ropeway.isPendelbahn">
      <ion-row>
        <ion-col class="sis-property">{{ 'general.term.transportQuantity' | translate }}</ion-col>
        <ion-col class="sis-value" data-test="sis-ropway-settings-detailview-maxTransportQuantityPerHour">
          {{ this.ropeway.maxTransportQuantityPerHour }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="sis-property">{{ 'general.term.operatingTime' | translate }}</ion-col>
        <ion-col class="sis-value" data-test="sis-ropway-settings-detailview-operatingTime">
          <ng-container *ngIf="this.ropeway.operatingTimeFrom && this.ropeway.operatingTimeUntil">
            {{ this.operatingTimeFromFormatted }}
            {{ 'general.term.toTimeOnly' | translate }}
            {{ this.operatingTimeUntilFormatted }}
          </ng-container>
        </ion-col>
      </ion-row>
    </ng-container>
    <ion-row *ngIf="this.ropeway.availabilityIsAvailable && this.hasAvailabilityFeatureReadPermission$ | async">
      <ion-col class="sis-property">{{ 'general.term.availability' | translate }}</ion-col>
      <ion-col class="sis-value" data-test="sis-ropway-settings-detailview-availability">
        <ion-icon [name]="this.ropeway.availability ? 'checkmark-outline' : 'close-outline'" style="vertical-align: middle"></ion-icon>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-grid>
<div class="sis-bottom-buttons">
  <ion-chip
    class="sis-settings-button"
    color="secondary"
    [disabled]="!(this.hasWritePermission$ | async)"
    (click)="openSettingsModal()"
    data-test="sis-ropeway-settings-button"
  >
    <ion-label>{{ 'general.term.settings' | translate }}</ion-label>
  </ion-chip>
</div>
