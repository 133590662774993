import { AbbSmartSensor } from 'src/app/maps/domain/abbsmartsensor.model';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { GinConfiguration } from 'src/app/maps/domain/gin-configuration.model';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { TruScan } from 'src/app/maps/domain/truscan.model';
import { Webcam } from 'src/app/maps/domain/webcam.model';

export class MasterdataAdapter {
  private static controllerTypeWithoutAvailability = ['connect', 'leitcontrol', 'alptronic'];
  private static pendelbahnRegexp = new RegExp(/\d{3}[ps]/);

  static isAvailabilityAvailable(sisId: string, controllerType: string): boolean {
    return (
      !MasterdataAdapter.isPendelbahn(sisId) && !MasterdataAdapter.controllerTypeWithoutAvailability.some((c) => c === controllerType?.toLowerCase())
    );
  }

  static isPendelbahn(sisId: string): boolean {
    return MasterdataAdapter.pendelbahnRegexp.test(sisId);
  }

  static adapt(item: any): MasterData {
    if (!item) {
      return { sisId: '', gatewayId: '' };
    }

    // create list of ropeways
    const ropeways: Ropeway[] = this.createRopewayList(item);

    // create list of meteostations
    const meteoStations: MeteoStation[] = this.createMeteoStationList(item, ropeways);

    const remoteAccesses: RemoteAccess[] = this.getRemoteAccesses(item);
    const abbSmartSensors: AbbSmartSensor[] = this.getAbbSmartSensors(item);

    this.setMeteoStations(ropeways, meteoStations);

    const offsetSpaToApiMilliseconds = Date.now() - new Date(item.timestamp).getTime();

    const customMarkers = this.createCustomMarkerList(item);

    const ginConfigurations = this.createGinConfigurationList(item);

    return {
      guid: item.guid,
      sisId: item.sisId,
      alias: item.alias,
      fullname: item.fullname,
      gatewayId: item.gatewayId,
      panoMapFilename: item.panoMapFilename,
      logoFilename: item.logoFilename,
      weatherTrendLink: item.weatherTrendLink,
      mySqlRouter: item.mySqlRouter,
      ropeways,
      meteoStations,
      remoteAccesses,
      offsetSpaToApiMilliseconds,
      firstEntriesGroups: item.firstEntriesGroups,
      abbSmartSensors,
      customMarkers,
      ginConfigurations,
    };
  }

  private static createRopewayList(item: any): Ropeway[] {
    const ropeways: Ropeway[] = [];

    if (item && item.ropeways != null) {
      item.ropeways.forEach((r: any) => {
        // create list of configurations
        const configurations = this.getConfigurations(r);
        const webcams = this.getWebcams(r);
        const remoteAccesses = this.getRemoteAccesses(r);
        const abbSmartSensors = this.getAbbSmartSensors(r);
        const truScanInstallations = this.getTruScan(r);
        const hasRedundantControllers = r.sisId && r.sisId.includes('.');

        ropeways.push({
          guid: r.guid,
          sisId: r.sisId,
          alias: r.alias,
          fullname: r.fullname,
          ropewayType: r.ropewayType,
          pathPanoMap: r.pathPanoMap,
          controllerType: r.controllerType,
          constructedBy: r.constructedBy,
          maxTransportQuantityPerHour: r.maxTransportQuantityPerHour,
          operatingTimeFrom: r.operatingTimeFrom,
          operatingTimeUntil: r.operatingTimeUntil,
          availability: r.availability,
          availabilityIsAvailable: MasterdataAdapter.isAvailabilityAvailable(r.sisId, r.controllerType),
          isPendelbahn: MasterdataAdapter.isPendelbahn(r.sisId),
          configurations,
          webcams,
          remoteAccesses,
          abbSmartSensors,
          hasRedundantControllers,
          truScanInstallations: truScanInstallations,
        });
      });
    }
    return ropeways;
  }

  private static getRemoteAccesses(item: any): RemoteAccess[] {
    const remoteAccesses: RemoteAccess[] = [];
    if (item && item.remoteAccesses != null) {
      item.remoteAccesses.forEach((r: any) => {
        remoteAccesses.push({
          guid: r.guid,
          name: r.name,
          type: r.type,
          accessType: r.accessType,
          agentId: r.agentId,
          windowDimensions: r.windowDimensions,
          parentAlias: item.ropeways ? item.alias : item.sisId.toUpperCase(),
          administratorsOnly: r.administratorsOnly,
        });
      });
    }

    return remoteAccesses;
  }

  private static getAbbSmartSensors(item: any): AbbSmartSensor[] {
    const abbSmartSensors: AbbSmartSensor[] = [];
    if (item && item.abbSmartSensors != null) {
      item.abbSmartSensors.forEach((abbSmartSensor: any) => {
        abbSmartSensors.push({
          assetName: abbSmartSensor.assetName,
          assetId: abbSmartSensor.assetId,
          assetType: abbSmartSensor.assetType,
        });
      });
    }

    return abbSmartSensors;
  }

  private static getTruScan(item: any): TruScan[] {
    const truScan: TruScan[] = [];
    if (item && item.truScanInstallations != null) {
      item.truScanInstallations.forEach((truScanInstallation: any) => {
        truScan.push({
          ropewayGuid: truScanInstallation.ropewayGuid,
          installationId: truScanInstallation.installationId,
        });
      });
    }

    return truScan;
  }

  private static createMeteoStationList(item: any, ropeways: Ropeway[]): MeteoStation[] {
    const meteostations: MeteoStation[] = [];
    if (item && item.meteoStations != null) {
      item.meteoStations.forEach((m: any) => {
        // parse positionPanoMap string to Coords object (e.g. "{"x":300,"y":200}")
        const coords = JSON.parse(m.positionPanoMap) as Coords;

        // create list of configurations
        const configurations = this.getConfigurations(m);

        const webcams = this.getWebcams(m);

        const ropeway = ropeways.find((r) => r.guid === m.ropewayGuid);
        const ropewaySisId = (ropeway && ropeway.sisId) || undefined;

        meteostations.push(
          new MeteoStation({
            guid: m.guid,
            ropewaySisId,
            tenantSisId: item.sisId,
            alias: m.alias,
            directionOffsetPanoMap: m.directionOffsetPanoMap,
            directionOffsetNorth: m.directionOffsetNorth,
            positionPanoMap: coords,
            meteoStationIndex: m.meteoStationIndex,
            configuration: configurations,
            ropeway,
            webcams,
            windDirection: m.windDirectionType,
            hasForecast: m.hasForecast,
            snowmakingWidgetOrder: m.snowmakingWidgetOrder,
            dataProvider: m.dataProvider,
            forecastTestActive: m.forecastTestActive,
          })
        );
      });
    }
    return meteostations;
  }

  private static createCustomMarkerList(item: any): CustomMarker[] {
    const customMarkers: CustomMarker[] = [];

    item.customMarkers?.forEach((element: any) =>
      customMarkers.push({
        guid: element.guid,
        sisId: element.sisId,
        alias: element.alias,
        fullname: element.fullname,
        iconName: element.iconName,
        webcams: this.getWebcams(element),
        positionPanoMap: JSON.parse(element.positionPanoMap) as Coords,
        type: element.type,
      })
    );

    return customMarkers;
  }

  private static setMeteoStations(ropeways: Ropeway[], meteoStations: MeteoStation[]): void {
    ropeways.forEach((ropeway) => {
      ropeway.meteoStations = meteoStations.filter((m) => m.ropewaySisId === ropeway.sisId);
    });
  }

  private static getConfigurations(e: any): Configuration[] {
    const configurations: Configuration[] = [];
    if (e && e.configuration != null) {
      e.configuration.forEach((c: any) => {
        configurations.push(
          new Configuration({
            id: c.id,
            configKey: c.configKey,
            nominalValue: Number(c.nominalValue),
            warnRangeLow: c.warnRangeLow,
            warnRangeHigh: c.warnRangeHigh,
            alarmRangeLow: c.alarmRangeLow,
            alarmRangeHigh: c.alarmRangeHigh,
            negativeValues: c.negativeValues,
          })
        );
      });
    }
    return configurations;
  }

  private static getWebcams(e: any): Webcam[] {
    const webcams: Webcam[] = [];
    if (e && e.webcams != null) {
      e.webcams.forEach((webcam: any) => {
        webcams.push(new Webcam(webcam.id, webcam.url, webcam.title, webcam.displayOrder));
      });
    }
    return webcams.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  private static createGinConfigurationList(e: any): GinConfiguration[] {
    const configurations: GinConfiguration[] = [];
    if (e && e.ginConfigurations != null) {
      e.ginConfigurations.forEach((c: any) => {
        configurations.push({
          id: c.id,
          warnRangeHigh: c.warnRangeHigh,
          alarmRangeHigh: c.alarmRangeHigh,
          externalLink: c.externalLink,
          detailViewOrder: c.detailViewOrder,
        });
      });
    }
    return configurations;
  }
}
