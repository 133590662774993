import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaItemIconOptions } from 'src/app/sismedia/domain/sismedia-item-icon-options.model';
import { SisMediaRouteParams } from 'src/app/sismedia/domain/sismedia-route-params.model';
import { SisMediaAssetOverrideComponent } from 'src/app/sismedia/sismedia-asset-override/sismedia-asset-override.page';
import { SisMediaEditModalComponent } from 'src/app/sismedia/sismedia-edit-modal/sismedia-edit-modal.component';
import { SisMediaExportsEditModalComponent } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-edit-modal.component';
import { SisMediaItemService } from 'src/app/sismedia/sismedia-item/sismedia-item.service';
import { SisMediaOperatingHoursModalComponent } from 'src/app/sismedia/sismedia-operating-hours-modal/sismedia-operating-hours-modal.component';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-sismedia-item',
  templateUrl: './sismedia-item.component.html',
  styleUrls: ['./sismedia-item.component.scss'],
})
export class SisMediaItemComponent extends Unsubscriber implements OnInit, OnChanges {
  static readonly letterWidthMobileView = 11;
  static readonly letterWidthDesktopView = 23;

  @Input() item: SisMediaItem;
  @Input() options: SisMediaRouteParams;
  @Input() bigScreenMode: boolean;
  @Input() showInfoCol: boolean;
  @Input() showSisMapsCol: boolean;
  @Input() showButtons: boolean = true;
  @Input() labelLength: number;
  @Input() hasMySqlRouter: boolean;

  color: string;
  icon: string;
  writePermission: boolean;
  assetOverrideReadPermission: boolean;
  operatingHoursReadPermission: boolean;
  assetEditPermission: boolean;
  isStatusUpdating: boolean;
  hasAssetOverride: boolean;
  hasOperatingHours: boolean;
  hasExportsEditPermission: boolean = false;
  hasExports: boolean;
  exportTooltipText: string;
  hasSisMaps: boolean;
  sisMapsTooltipText: string;
  showingLabelLength: string;

  sisMapIconClass: string;

  private requiredFeature: Feature;

  constructor(
    private sisMediaItemService: SisMediaItemService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private userSettingsService: UserSettingsService
  ) {
    super();
  }

  ngOnInit() {
    if (this.item) {
      this.item
        .getUpdating(SisMediaItem.AssetEdit)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          const iconName = IconHelper.getIconName(this.item.type);
          this.icon =
            this.item.assetCategory === SisMediaAssetCategory.Poi
              ? `${environment.baseUrlPublicBlobStorage}/public-assets/icons/sismedia/styles/sisag/${iconName.substring(4)}.svg`
              : `${environment.baseUrlCockpitBlobStorage}/public-assets/icons/sismedia/${iconName}.svg`;
          this.setSisMapIconClassAndTooltip();
        });

      this.item
        .getUpdating(SisMediaItem.Status)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isUpdating) => {
          this.isStatusUpdating = isUpdating;
          this.item.iconOptions.forEach((option) => {
            option.activeClasses = this.getStatusIconClasses(this.item, option);
          });
        });

      this.item
        .getUpdating(SisMediaItem.OperatingHours)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.item.iconOptions.forEach((option) => {
            option.activeClasses = this.getStatusIconClasses(this.item, option);
          });
        });

      this.item
        .getUpdating(SisMediaItem.ExportMappings)
        .pipe(
          takeUntil(this.onDestroy$),
          filter((updating) => !updating)
        )
        .subscribe(() => {
          this.hasExports = this.item.exports.length > 0;
          this.exportTooltipText = ':\n\n' + this.item.exports.map((x) => `- ${x.name}`).join('\n');
        });

      this.setSisMapIconClassAndTooltip();
      this.hasSisMaps = this.item.sisMapNames.length > 0;
    }

    this.requiredFeature = new Feature(this.options.featureId, FeatureAccessLevel.WRITE);

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature));
      this.assetOverrideReadPermission =
        features.some((f) => f.hasMinimumRequirementFor(new Feature(this.requiredFeature.featureId, FeatureAccessLevel.READ))) &&
        features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_ASSETOVERRIDE, FeatureAccessLevel.READ)));

      this.assetEditPermission =
        features.some((f) => f.hasMinimumRequirementFor(new Feature(this.requiredFeature.featureId, FeatureAccessLevel.READ))) &&
        features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_ASSET_EDIT, FeatureAccessLevel.READ)));

      this.operatingHoursReadPermission =
        features.some((f) => f.hasMinimumRequirementFor(new Feature(this.requiredFeature.featureId, FeatureAccessLevel.READ))) &&
        features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_OPERATINGHOURS, FeatureAccessLevel.READ)));

      this.userSettingsService.userSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((userSettings) => {
        this.hasExportsEditPermission = userSettings.isAdministrator;
      });

      this.item.iconOptions.forEach((option) => {
        option.activeClasses = this.getStatusIconClasses(this.item, option);
      });
    });

    this.hasAssetOverride =
      this.item.assetCategory === SisMediaAssetCategory.Lift ||
      this.item.assetCategory === SisMediaAssetCategory.Slope ||
      this.item.assetCategory === SisMediaAssetCategory.Trail;

    this.hasOperatingHours = this.item.assetCategory === SisMediaAssetCategory.Gastro || this.item.assetCategory === SisMediaAssetCategory.Poi;
  }

  private setSisMapIconClassAndTooltip(): void {
    if (!this.item || this.item.sisMapInfo.length === 0 || !Array.isArray(this.item.sisMapInfo)) {
      return;
    }

    const sisMapInfo = this.item.sisMapInfo;
    const itemSeason = this.item.season;

    const isSeasonMatching = itemSeason === 0 || sisMapInfo.every((mapInfo) => mapInfo.season === 0 || mapInfo.season === itemSeason);

    this.sisMapIconClass = isSeasonMatching ? 'sis-sismap-icon-default' : 'sis-sismap-icon-warning';

    this.translateService.onLangChange
      .pipe(startWith(this.translateService.currentLang), takeUntil(this.onDestroy$))
      .subscribe(async () => this.updateSisMapTooltipText());
  }

  private async updateSisMapTooltipText(): Promise<void> {
    const sisMapInfo = this.item.sisMapInfo;
    const itemSeason = this.item.season;

    const titleText = await firstValueFrom(this.translateService.get('sismedia.phrase.sisMapsTooltipTitle'));
    const missingText = await firstValueFrom(this.translateService.get('sismedia.phrase.missingSeasonMapping'));

    const mapNamesWithCorrectSeason: string[] = [];
    const mapNamesWithMismatchedSeason: string[] = [];

    let tooltipText = '';

    this.item.sisMapNames.forEach((name, index) => {
      const mapInfo = sisMapInfo[index];
      if (itemSeason !== 0 && mapInfo.season !== 0 && mapInfo.season !== itemSeason) {
        mapNamesWithMismatchedSeason.push(name);
      } else {
        mapNamesWithCorrectSeason.push(name);
      }
    });

    if (mapNamesWithCorrectSeason.length) {
      tooltipText += titleText + ':\n' + mapNamesWithCorrectSeason.join('\n');

      if (mapNamesWithMismatchedSeason.length) {
        tooltipText += '\n';
      }
    }

    if (mapNamesWithMismatchedSeason.length) {
      tooltipText += missingText + ':\n' + mapNamesWithMismatchedSeason.join('\n');
    }

    this.sisMapsTooltipText = tooltipText;
  }

  ngOnChanges() {
    this.item.iconOptions.forEach((option) => {
      option.activeClasses = this.getStatusIconClasses(this.item, option);
    });

    this.showingLabelLength = this.bigScreenMode
      ? `${this.labelLength * SisMediaItemComponent.letterWidthDesktopView}px`
      : `${Math.min(3, this.labelLength) * SisMediaItemComponent.letterWidthMobileView}px`;
  }

  iconClick(status: SisMediaAssetStatus) {
    if (!this.writePermission || this.item.status === null || this.item.operatingHoursEnabled || !this.showButtons) {
      return;
    }

    if (this.item.status != status) {
      this.item.setUpdating(SisMediaItem.Status, true);
      this.sisMediaItemService.updateStatus(this.item.assetGuid, this.options.assetCategory, status);
    }
  }

  async openOverrideModal() {
    if (this.assetOverrideReadPermission) {
      const modal = await this.modalCtrl.create({
        component: SisMediaAssetOverrideComponent,
        componentProps: {
          item: this.item,
          featureId: this.options.featureId,
        },
        cssClass: 'sis-sismedia-asset-override-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async openEditModal() {
    if (this.assetEditPermission) {
      const modal = await this.modalCtrl.create({
        component: SisMediaEditModalComponent,
        componentProps: {
          isNewItem: false,
          sisMediaItem: this.item,
          category: this.options.titleTranslationString.split('.')[2],
        },
        cssClass: `sis-sismedia-edit-modal-${SisMediaAssetCategory[this.item.assetCategory].toLowerCase()}`,
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async openOperatingHoursModal() {
    if (this.operatingHoursReadPermission) {
      const modal = await this.modalCtrl.create({
        component: SisMediaOperatingHoursModalComponent,
        componentProps: {
          sisMediaItem: this.item,
        },
        cssClass: 'sis-sismedia-operating-hours-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async openExportsEditModal() {
    if (this.hasExportsEditPermission) {
      const modal = await this.modalCtrl.create({
        component: SisMediaExportsEditModalComponent,
        componentProps: {
          sisMediaItem: this.item,
        },
        cssClass: 'sis-sismedia-exports-edit-modal',
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  async presentActionSheet(): Promise<void> {
    const buttons = [];

    if (this.hasOperatingHours && this.operatingHoursReadPermission) {
      const operatingHoursText = await firstValueFrom(this.translateService.get('general.term.operatingTime'));
      buttons.push({
        text: operatingHoursText,
        icon: 'sis-operating-hours',
        handler: () => {
          this.openOperatingHoursModal();
        },
      });
    }

    if (this.assetEditPermission) {
      const editText = await firstValueFrom(this.translateService.get('general.term.edit'));
      buttons.push({
        text: editText,
        icon: 'create-outline',
        handler: () => {
          this.openEditModal();
        },
      });
    }

    const cancelText = await firstValueFrom(this.translateService.get('general.term.cancel'));
    buttons.push({
      text: cancelText,
      icon: 'close',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.item.name,
      buttons,
      cssClass: 'sis-action-sheet-header',
    });

    await actionSheet.present();
  }

  getStatusIconClasses(item: SisMediaItem, option: SisMediaItemIconOptions): string[] {
    const statusIconClasses: string[] = [];
    if (item.status == null) {
      statusIconClasses.push('sis-no-status');
      return statusIconClasses;
    }

    if (item.operatingHoursEnabled) {
      if (item.status === option.sisMediaStatus) {
        statusIconClasses.push(option.colorEnabledClassName);
        statusIconClasses.push('sis-global-default-cursor');
      } else {
        statusIconClasses.push('sis-no-status');
      }
      return statusIconClasses;
    }

    item.status === option.sisMediaStatus
      ? statusIconClasses.push(option.colorEnabledClassName)
      : this.writePermission && this.showButtons
      ? statusIconClasses.push(option.colorDisabledClassName)
      : statusIconClasses.push('sis-disabled');

    item.statusOverrideActive && item.activeStatus === option.sisMediaStatus ? statusIconClasses.push('sis-active-override-status') : '';

    this.writePermission && item.status === option.sisMediaStatus ? statusIconClasses.push('sis-global-default-cursor') : '';

    !this.writePermission ? statusIconClasses.push('sis-not-allowed') : '';

    if (!this.showButtons) {
      statusIconClasses.push('sis-reduced-opacity');
      statusIconClasses.push('sis-global-default-cursor');
    }

    return statusIconClasses;
  }

  presentToast() {
    this.userMessageService.presentToast(
      new UserMessage({
        message: 'sismedia.term.noManualSwitching',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'bottom',
        color: UserMessageColor.blue,
      })
    );
  }
}
