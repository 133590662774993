<ion-header (click)="this.hideModals()">
  <ion-toolbar color="default">
    <ion-buttons slot="start">
      <ion-menu-button *ngIf="this.isUserAuthenticated"></ion-menu-button>
      <ion-icon
        *ngIf="this.hasUnreadNews$ | async"
        name="sis-news-dot"
        class="sis-red-dot"
        data-test="header-red-dot"
      ></ion-icon>
    </ion-buttons>
    <ion-title *ngIf="this.isUserAuthenticated && !this.isGlobalPage">
      {{ title }}
    </ion-title>
    <ion-title *ngIf="this.isGlobalPage">{{ 'global.title' | translate }}</ion-title>
    <ion-chip
      *ngIf="this.isUserAuthenticated && !this.isGlobalPage && this.activeAlarmCount"
      class="sis-alarm-icon-container"
      [ngStyle]="{ pointerEvents: this.isAlarmlistPage ? 'none' : null }"
      slot="end"
      (click)="this.alarmListIconOnClick($event)"
    >
      <sis-alarm-icon
        [iconSize]="24"
        [componentClass]="'sis-header-alarm-text'"
        [displayedAlarmLevels]="this.warningIconLevels"
        class="sis-header-alarm-icon"
        style="margin-right: 20px"
      ></sis-alarm-icon>
      <sis-alarm-icon
        [iconSize]="24"
        [componentClass]="'sis-header-alarm-text'"
        [displayedAlarmLevels]="this.alarmIconLevels"
        class="sis-header-alarm-icon"
      ></sis-alarm-icon>
    </ion-chip>

    <a
      class="sis-connection-icon"
      [ngClass]="this.internetConnected ? 'sis-icon-hidden' : 'sis-icon-shown'"
      slot="end"
      [matTooltipDisabled]="this.internetConnected"
      matTooltip="{{ 'general.phrase.noInternet' | translate }}"
    >
      <ion-icon name="close-outline"></ion-icon>
    </a>
    <a
      class="sis-api-connection-icon"
      [ngClass]="this.apiConnected ? 'sis-icon-hidden' : 'sis-icon-shown'"
      slot="end"
      [matTooltipDisabled]="this.apiConnected"
      matTooltip="{{ 'general.phrase.apiError' | translate }}"
    >
      <ion-icon name="eye-off-outline"></ion-icon>
    </a>
    <a
      class="sis-reload-icon"
      [ngClass]="this.apiConnected && this.internetConnected ? 'sis-icon-hidden' : 'sis-icon-shown'"
      slot="end"
      [matTooltipDisabled]="this.apiConnected && this.internetConnected"
      matTooltip="{{ 'general.phrase.reloadPage' | translate }}"
      (click)="this.reloadApp()"
    >
      <ion-icon name="refresh-outline"> </ion-icon>
    </a>
  </ion-toolbar>
</ion-header>
<sis-maintenance-banner
  *ngIf="this.underMaintenance && !this.maintenanceBannerHidden"
  (hide)="this.hideMaintenanceBanner()"
></sis-maintenance-banner>
<!-- TODO: use multi-layout routing: https://stackoverflow.com/questions/46527826/best-method-to-set-different-layout-for-different-pages-in-angular-4 -->
