import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TimetableDirection } from 'src/app/timetable/domain/timetable-direction.enum';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { TimetableStation } from 'src/app/timetable/domain/timetable-station.model';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  templateUrl: './journey-editor.component.html',
  styleUrls: ['./journey-editor.component.scss'],
})
export class JourneyEditorComponent extends Unsubscriber implements OnInit {
  journey: TimetableJourney;
  stations: TimetableStation[];
  season: TimetableSeason;
  journeyFormsGroup: FormGroup;
  hasMoreThanTwoStations: boolean;
  isNewJourney: boolean;
  dateIsEnabled: boolean;
  isSaving: boolean;

  constructor(private modalCtrl: ModalController, private timetableService: TimetableService) {
    super();
  }

  ngOnInit() {
    this.stations = this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
    this.hasMoreThanTwoStations = this.stations.length > 2;
    this.dateIsEnabled = !!this.journey?.date;

    this.journeyFormsGroup = new FormGroup(
      {
        vehicleNumber: new FormControl(this.journey?.vehicleNumber, [
          Validators.pattern('^[a-zA-Z0-9äÄöÖüÜ\\s-&+]*$'),
          Validators.maxLength(10),
        ]),
        direction: new FormControl(
          this.hasMoreThanTwoStations ? this.journey?.direction ?? TimetableDirection.up : this.journey?.direction
        ),
        date: new FormControl(this.journey?.date ? moment(new Date(this.journey?.date)).format('YYYY-MM-DD') : null),
        mo: new FormControl({ value: this.journey?.mo, disabled: true }),
        tu: new FormControl({ value: this.journey?.tu, disabled: true }),
        we: new FormControl({ value: this.journey?.we, disabled: true }),
        th: new FormControl({ value: this.journey?.th, disabled: true }),
        fr: new FormControl({ value: this.journey?.fr, disabled: true }),
        sa: new FormControl({ value: this.journey?.sa, disabled: true }),
        su: new FormControl({ value: this.journey?.su, disabled: true }),
      },
      {
        validators: [this.validateDepartureTime()],
      }
    );

    this.setUsabilityWeekdays(this.dateIsEnabled);

    if (this.hasMoreThanTwoStations) {
      this.stations.forEach((station) => {
        this.journeyFormsGroup.addControl(
          `departureTime_${station.guid}`,
          new FormControl(
            this.journey?.items?.find((i) => i.stationGuids.some((g) => g === station.guid))?.departureTime
          )
        );
      });
    } else {
      this.journeyFormsGroup.addControl(
        'departureTime',
        new FormControl(this.journey?.items ? this.journey.items[0]?.departureTime : null)
      );
    }
  }

  setUsabilityWeekdays(dateIsEnabled: boolean): void {
    if (dateIsEnabled) {
      this.journeyFormsGroup.controls.mo.disable();
      this.journeyFormsGroup.controls.tu.disable();
      this.journeyFormsGroup.controls.we.disable();
      this.journeyFormsGroup.controls.th.disable();
      this.journeyFormsGroup.controls.fr.disable();
      this.journeyFormsGroup.controls.sa.disable();
      this.journeyFormsGroup.controls.su.disable();
    } else {
      this.journeyFormsGroup.controls.mo.enable();
      this.journeyFormsGroup.controls.tu.enable();
      this.journeyFormsGroup.controls.we.enable();
      this.journeyFormsGroup.controls.th.enable();
      this.journeyFormsGroup.controls.fr.enable();
      this.journeyFormsGroup.controls.sa.enable();
      this.journeyFormsGroup.controls.su.enable();
    }
  }

  clearDate(): void {
    this.journeyFormsGroup.controls.date.setValue(null, { emitEvent: true });
    this.journeyFormsGroup.updateValueAndValidity();

    if (!this.journeyFormsGroup.dirty) {
      this.journeyFormsGroup.markAsDirty();
    }

    this.onDateChanged();
  }

  onDateChanged(): void {
    this.dateIsEnabled = !!this.journeyFormsGroup.value.date;
    this.setUsabilityWeekdays(this.dateIsEnabled);
  }

  validateDepartureTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex1 = /^\d{2}:\d{2}:\d{2}$/g;
      const regex2 = /^\d{2}:\d{2}$/g;
      const departureTimes = [];
      if (this.hasMoreThanTwoStations) {
        this.stations.forEach((station) => {
          departureTimes.push(control.get(`departureTime_${station.guid}`)?.value);
        });
        return departureTimes.every(
          (entry) => entry?.match(regex1) != null || entry?.match(regex2) != null || entry == null || entry == ''
        )
          ? null
          : { departureTimesInvalid: true };
      } else {
        departureTimes.push(control.get('departureTime')?.value);
        return departureTimes.every((entry) => entry?.match(regex1) != null || entry?.match(regex2) != null)
          ? null
          : { departureTimesInvalid: true };
      }
    };
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    this.isSaving = true;
    await this.timetableService.updateJourney(this.rejoinJourneyData());
    this.close();
  }

  private rejoinJourneyData() {
    const journeyPostData: TimetableJourney = {
      guid: this.isNewJourney ? undefined : this.journey.guid,
      seasonGuid: this.isNewJourney ? this.season.guid : this.journey.seasonGuid,
      direction: this.journeyFormsGroup.value.direction,
      vehicleNumber: this.journeyFormsGroup.value.vehicleNumber,
      date: this.journeyFormsGroup.value.date ? this.journeyFormsGroup.value.date : null,
      mo: this.journeyFormsGroup.getRawValue().mo,
      tu: this.journeyFormsGroup.getRawValue().tu,
      we: this.journeyFormsGroup.getRawValue().we,
      th: this.journeyFormsGroup.getRawValue().th,
      fr: this.journeyFormsGroup.getRawValue().fr,
      sa: this.journeyFormsGroup.getRawValue().sa,
      su: this.journeyFormsGroup.getRawValue().su,
      imported: false,
      items: [],
    };

    if (this.hasMoreThanTwoStations) {
      journeyPostData.direction = TimetableDirection.up;

      this.stations.forEach((station) => {
        const departureTime = this.journeyFormsGroup.value[`departureTime_${station.guid}`];

        if (departureTime) {
          journeyPostData.items.push({
            guid: this.isNewJourney
              ? undefined
              : this.journey.items.find((i) => i.stationGuids[0] === station.guid)?.guid,
            journeyGuid: this.isNewJourney ? undefined : this.journey.guid,
            departureTime: departureTime,
            stationGuids: [station.guid],
          });
        }
      });
    } else {
      let stationGuids = this.stations.map((s) => s.guid);

      if (this.journeyFormsGroup.value.direction === TimetableDirection.up) {
        stationGuids = [stationGuids[0]];
      }

      if (this.journeyFormsGroup.value.direction === TimetableDirection.down) {
        stationGuids = [stationGuids[1]];
      }

      journeyPostData.items.push({
        guid: this.isNewJourney ? undefined : this.journey.items[0].guid,
        journeyGuid: this.isNewJourney ? undefined : this.journey.guid,
        departureTime: this.journeyFormsGroup.value.departureTime,
        stationGuids: stationGuids,
      });
    }

    return journeyPostData;
  }
}
