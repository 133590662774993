import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingService } from 'src/app/parking/domain/parking.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-parking-edit-modal',
  templateUrl: './parking-edit-modal.component.html',
  styleUrls: ['./parking-edit-modal.component.scss'],
})
export class ParkingEditModalComponent extends Unsubscriber implements OnInit {
  @Input() parking: Parking;

  editedParking: Parking;

  writePermission = false;
  isSaving = false;
  hasUnsavedChanges = false;

  formGroup: FormGroup;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(
    private modalCtrl: ModalController,
    private parkingService: ParkingService,
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.editedParking = JSON.parse(JSON.stringify(this.parking));

    this.formGroup = new FormGroup({
      name: new FormControl<string>(this.editedParking.name, [Validators.required]),
      nameEn: new FormControl<string>(this.editedParking.nameEn),
      label: new FormControl<string>(this.editedParking.label),
      custom: new FormControl<string>(this.editedParking.custom),
      info: new FormControl<string>(this.editedParking.info),
    });

    this.setValues();

    this.formGroup.valueChanges.pipe(debounceTime(50), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.editedParking.name = value.name === '' || value.name == null ? null : value.name;
      this.editedParking.nameEn = value.nameEn === '' || value.nameEn == null ? null : value.nameEn;
      this.editedParking.label = value.label === '' || value.label == null ? null : value.label;
      this.editedParking.custom = value.custom === '' || value.custom == null ? null : value.custom;
      this.editedParking.info = value.info === '' || value.info == null ? null : value.info;

      this.checkForChanges();
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.PARKING, FeatureAccessLevel.WRITE)));
      this.setDisabled();
    });
  }

  async save(): Promise<void> {
    if (this.formGroup.invalid || !this.hasUnsavedChanges) {
      return;
    }

    this.isSaving = true;

    this.setDisabled();

    await this.parkingService.editParking([this.editedParking]);

    await this.modalCtrl.dismiss();
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  async copyToClipboard(text: string): Promise<void> {
    await navigator.clipboard.writeText(text);
    const toast = await this.userMessageService.createSingleToast(
      new UserMessage({
        message: 'general.phrase.copiedToClipboard',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.white,
      })
    );
    await toast.present();
  }

  private checkForChanges(): void {
    this.hasUnsavedChanges = JSON.stringify(this.parking) !== JSON.stringify(this.editedParking);
  }

  private setValues(): void {
    this.formGroup.controls.name.setValue(this.editedParking.name);
    this.formGroup.controls.nameEn.setValue(this.editedParking.nameEn);
    this.formGroup.controls.label.setValue(this.editedParking.label);
    this.formGroup.controls.custom.setValue(this.editedParking.custom);
    this.formGroup.controls.info.setValue(this.editedParking.info);
  }

  private setDisabled(): void {
    if (!this.writePermission || this.isSaving) {
      this.formGroup.controls.name.disable();
      this.formGroup.controls.nameEn.disable();
      this.formGroup.controls.label.disable();
      this.formGroup.controls.custom.disable();
      this.formGroup.controls.info.disable();
    } else {
      this.formGroup.controls.name.enable();
      this.formGroup.controls.nameEn.enable();
      this.formGroup.controls.label.enable();
      this.formGroup.controls.custom.enable();
      this.formGroup.controls.info.enable();
    }
  }
}
