import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { CKEditorService } from 'src/app/media-center/ckeditor/ckeditor.service';
import { FileSettings } from 'src/app/media-center/ckeditor/file-settings.model';

@Component({
  templateUrl: './video-browse.component.html',
  styleUrls: ['./video-browse.component.scss'],
})
export class VideoBrowseModal extends Unsubscriber implements OnInit {
  readonly acceptedFileFormats = '.mp4, .avi, .mov, .webm';
  readonly maxDisplayedFileSizeBytes = 5 * Math.pow(2, 20);

  videos: FileSettings[];

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private ckEditorService: CKEditorService
  ) {
    super();
  }

  ngOnInit() {
    this.ckEditorService.videos$
      .pipe(
        takeUntil(this.onDestroy$),
        map((fileSettings) => fileSettings.sort((a, b) => a.name.localeCompare(b.name)))
      )
      .subscribe((fileSettings) => {
        this.videos = fileSettings;
      });
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  videoSelected(fileSettings: FileSettings): void {
    if (fileSettings.url) {
      this.modalCtrl.dismiss({
        videoUrl: fileSettings.url,
        videoWidth: fileSettings.width,
        videoHeight: fileSettings.height,
      });
    }
  }

  deleteFile(file: FileSettings, event: any): void {
    event.stopPropagation();
    this.presentConfirmationDialog('mediacenter.phrase.deleteFile', 'mediacenter.term.delete').then((confirm) => {
      if (confirm) {
        file.deleted = true;
        this.ckEditorService.deleteFile(file);
      }
    });
  }

  handleFileInput(event: any): void {
    const files: FileList = event.target.files;
    const file = files.item(0);
    event.target.value = '';

    if (
      !this.acceptedFileFormats.includes(
        file.type.replace('video/', '').replace('+xml', '').replace('quicktime', '.mov').toLocaleLowerCase()
      )
    ) {
      return;
    }

    if (
      this.videos.find(
        (video) => video.name.slice(0, -4).replace(CKEditorService.convertingPrefix, '') === file.name.slice(0, -4)
      )
    ) {
      this.presentConfirmationDialog('mediacenter.phrase.overwriteFile', 'mediacenter.term.overwrite').then(
        (confirm) => {
          if (confirm) {
            this.ckEditorService.uploadVideo(file);
          }
        }
      );
    } else {
      this.ckEditorService.uploadVideo(file);
    }
  }

  itemId(_: number, item: FileSettings): string {
    return `${item.url}${item.progress}`;
  }

  private presentConfirmationDialog(confirmationText: string, confirmationButton: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.alertCtrl
        .create({
          header: await this.getTranslatedString(confirmationText),
          buttons: [
            {
              text: await this.getTranslatedString(confirmationButton),
              handler: () => {
                resolve(true);
              },
            },
            {
              text: await this.getTranslatedString('general.term.cancel'),
              role: 'cancel',
              handler: () => {
                resolve(false);
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  private getTranslatedString(translateKey: string): Promise<string> {
    return firstValueFrom(this.translateService.get(translateKey));
  }
}
