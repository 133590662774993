import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaOperatingHoursEntry } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-entry.model';
import { SisMediaOperatingHoursPostData } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-postdata.model';
import { SisMediaOperatingHoursService } from 'src/app/sismedia/sismedia-operating-hours-modal/sismedia-operating-hours-modal.service';

@Component({
  selector: 'sis-sismedia-operating-hours-modal',
  templateUrl: './sismedia-operating-hours-modal.component.html',
  styleUrls: ['./sismedia-operating-hours-modal.component.scss'],
})
export class SisMediaOperatingHoursModalComponent extends Unsubscriber implements OnInit {
  @Input() sisMediaItem: SisMediaItem;

  bigScreenMode: boolean;

  writePermission: boolean;
  readPermission: boolean;

  isSaving: boolean = false;
  isEdited: boolean[] = [];
  hasUnsavedChanges: boolean;
  operatingHoursEntries: SisMediaOperatingHoursEntry[];
  operatingHoursEntriesEdit: SisMediaOperatingHoursEntry[];
  operatingHoursEnabled: boolean;

  private requiredFeatureWrite: Feature;
  private requiredFeatureRead: Feature;

  constructor(
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private operatingHoursService: SisMediaOperatingHoursService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.isEdited.fill(false);
    this.requiredFeatureWrite = new Feature(FeatureId.SISMEDIA_OPERATINGHOURS, FeatureAccessLevel.WRITE);
    this.requiredFeatureRead = new Feature(FeatureId.SISMEDIA_OPERATINGHOURS, FeatureAccessLevel.READ);

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(this.requiredFeatureWrite));
      this.readPermission = features.some((f) => f.hasMinimumRequirementFor(this.requiredFeatureRead));
    });

    this.operatingHoursService
      .getOperatingHours(this.sisMediaItem.assetGuid, this.sisMediaItem.assetCategory)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((operatingHours) => {
        this.operatingHoursEntries = operatingHours.entries;
        this.operatingHoursEntriesEdit = operatingHours.entries;
        this.sisMediaItem.operatingHoursEnabled = operatingHours.enabled;

        this.reset();
        this.isSaving = false;
      });
  }

  setIsEdited(weekday: number) {
    this.isEdited[weekday] = true;
    this.checkForChanges();
  }

  checkForChanges(): void {
    this.hasUnsavedChanges = this.isEdited.includes(true);
  }

  toggleChanged(value: boolean): void {
    this.operatingHoursEnabled = value;
    this.isEdited[7] = true;
    this.checkForChanges();
  }

  save(): void {
    this.isSaving = true;

    const postData: SisMediaOperatingHoursPostData = {
      assetGuid: this.sisMediaItem.assetGuid,
      assetCategory: this.sisMediaItem.assetCategory,
      operatingHoursEnabled: this.operatingHoursEnabled,
      operatingHours: this.operatingHoursEntriesEdit,
    };

    this.operatingHoursService.postOperatingHours(postData).subscribe();
  }

  reset(): void {
    this.operatingHoursEntriesEdit = JSON.parse(JSON.stringify(this.operatingHoursEntries));
    this.operatingHoursEnabled = this.sisMediaItem.operatingHoursEnabled;
    this.isEdited.fill(false);
    this.checkForChanges();
  }

  cancel(): void {
    this.reset();
    this.modalCtrl.dismiss();
  }
}
