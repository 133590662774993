import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { Webcam } from 'src/app/maps/domain/webcam.model';

export class MeteoStation {
  guid: string;
  ropewaySisId: string;
  tenantSisId: string;
  alias: string;
  directionOffsetPanoMap: number;
  directionOffsetNorth: number;
  positionPanoMap: Coords;
  meteoStationIndex: number = 0;
  configuration: Configuration[] = [];
  ropeway: Ropeway;
  webcams: Webcam[] = [];
  windDirection: string;
  hasForecast: boolean = false;
  snowmakingWidgetOrder: number;
  dataProvider: string;
  forecastTestActive: boolean = false;

  get meteoDeviceSisId(): string {
    if (this.ropewaySisId) {
      return `${this.ropewaySisId}_meteo${this.meteoStationIndex}`;
    }

    if (this.tenantSisId) {
      return `${this.tenantSisId}_meteo${this.meteoStationIndex}`;
    }

    return undefined;
  }

  constructor(init?: Partial<MeteoStation>) {
    Object.assign(this, init);
  }
}
