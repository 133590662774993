<ion-row class="sis-feature-management-item sis-global-hover">
  <ion-col size="2" style="min-width: 30px">
    <ion-checkbox
      [(ngModel)]="feature.active"
      (ionChange)="onChanged()"
      [disabled]="this.activeDisabled"
      [attr.data-test]="'feature-' + feature.id + '-checkbox'"
    ></ion-checkbox>
  </ion-col>
  <ion-col size="2" style="min-width: 30px">
    <ion-checkbox
      [(ngModel)]="feature.adminOnly"
      (ionChange)="onChanged()"
      [attr.data-test]="'feature-adminOnly-' + feature.id + '-checkbox'"
    ></ion-checkbox>
  </ion-col>
  <ion-col size="8">
    <ion-row>
      <ion-col size="auto" style="padding-left: 0">({{ feature.id }})</ion-col>
      <ion-col size="9">{{ feature.nameTranslationString | translate }}</ion-col>
    </ion-row>
  </ion-col>
</ion-row>
