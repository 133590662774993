<ion-grid class="detailview-container sis-global-no-scrollbar">
  <div class="sis-global-sticky">
    <sis-sidepane-alarmlist-item
      *ngIf="this.hasActiveAlarm"
      [alarm]="this.efa"
      [clickable]="false"
      data-test="active-efa-item"
    ></sis-sidepane-alarmlist-item>
    <ion-row *ngIf="!this.hasActiveAlarm">
      <ion-col class="no-alarm-message sis-global-center" data-test="no-alarm-message">
        {{ 'alarming.phrase.noAlarms' | translate }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngIf="this.hasHistoryItems" class="sis-history-header" data-test="efa-history-header">
        {{ 'alarming.term.history' | translate }}
      </ion-col>
    </ion-row>
  </div>
  <div *ngIf="this.hasHistoryItems">
    <div *ngFor="let efa of this.efaHistory; index as i; trackBy: this.itemId">
      <sis-sidepane-alarmlist-item
        [alarm]="efa"
        [clickable]="false"
        [contextMenuDisabled]="true"
        [cssStyle]="i % 2 == 1 ? { 'background-color': '#f4f4f4' } : {}"
        [boldTitle]="false"
        data-test="efa-history-item"
      ></sis-sidepane-alarmlist-item>
    </div>
  </div>
  <ion-row class="sis-empty-row"></ion-row>
</ion-grid>
<ion-chip
  *ngIf="this.canAccessAlarmLibrary"
  class="sis-alarmlibrary-button"
  color="secondary"
  (click)="openAlarmLibrary()"
>
  <ion-label>{{ 'alarming.term.alarmLibrary' | translate }}</ion-label>
</ion-chip>
