<ion-header translucent data-test="sismedia-screen-editor">
  <ion-toolbar>
    <ion-title data-test="sis-screeneditor-title">
      <ion-label>
        {{ this.isNewScreen ? ('mediacenter.phrase.newScreen' | translate) : this.screen.location }}</ion-label
      >
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5"> {{ 'general.term.name' | translate }}: </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="location"
            maxlength="50"
            data-test="sis-screeneditor-name-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.location.errors }"
            [matTooltip]="this.formGroup.controls.location.errors?.tooltip | translate"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5"> {{ 'mediacenter.term.label' | translate }}: </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="label"
            maxlength="50"
            data-test="sis-screeneditor-label-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.label.errors }"
            [matTooltip]="this.formGroup.controls.label.errors?.tooltip | translate"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5">{{ 'mediacenter.term.screenSize' | translate }}: </ion-col>
        <ion-col style="max-width: 100px">
          <ion-input
            type="text"
            style="max-width: 100px"
            class="sis-modal-input sis-modal-text-input"
            formControlName="screenWidth"
            maxlength="50"
            data-test="sis-screeneditor-screenwidth-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.screenWidth.errors }"
          ></ion-input>
        </ion-col>
        <ion-col size="auto" style="padding: 5px 15px 5px 15px">x</ion-col>
        <ion-col style="max-width: 100px">
          <ion-input
            style="max-width: 100px"
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="screenHeight"
            maxlength="50"
            data-test="sis-screeneditor-screenheight-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.screenHeight.errors }"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5">{{ 'mediacenter.term.slideshowSize' | translate }}: </ion-col>
        <ion-col style="max-width: 100px">
          <ion-input
            type="text"
            style="max-width: 100px"
            class="sis-modal-input sis-modal-text-input"
            formControlName="slideshowWidth"
            maxlength="50"
            data-test="sis-screeneditor-slideshowwidth-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.slideshowWidth.errors }"
          ></ion-input>
        </ion-col>
        <ion-col size="auto" style="padding: 5px 15px 5px 15px">x</ion-col>
        <ion-col style="max-width: 100px">
          <ion-input
            type="text"
            style="max-width: 100px"
            class="sis-modal-input sis-modal-text-input"
            formControlName="slideshowHeight"
            maxlength="50"
            data-test="sis-screeneditor-slideshowheight-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.slideshowHeight.errors }"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5"> {{ 'IPv4' | translate }}: </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="ip"
            maxlength="30"
            data-test="sis-screeneditor-ip-input"
            placeholder="255.255.255.255"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.ip.errors }"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5">{{ 'mediacenter.term.group' | translate }}:</ion-col>
        <ion-col>
          <input
            type="text"
            style="min-width: 180px"
            class="sis-modal-text-input sis-global-text-overflow-ellipsis"
            [matAutocomplete]="autoGroup"
            formControlName="group"
            (ngModelChange)="this.updateFilter($event, 'group')"
            data-test="sis-screeneditor-group-input"
          />
          <mat-autocomplete
            #autoGroup="matAutocomplete"
            (optionSelected)="selectGroup($event)"
            [displayWith]="this.formatInputValue.bind(this)"
          >
            <mat-option *ngFor="let group of this.groupsFiltered" [value]="group" data-test="sis-screeneditor-group">
              {{ group.name }}
            </mat-option>
          </mat-autocomplete>
        </ion-col>
        <ion-col
          size="auto"
          style="cursor: pointer"
          (click)="this.removeSelection('group')"
          data-test="sis-screeneditor-reset-group-button"
        >
          <ion-icon class="sis-clear-icon" name="close"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5">{{ 'mediacenter.term.playlist' | translate }}:</ion-col>
        <ion-col>
          <input
            type="text"
            style="min-width: 180px"
            class="sis-modal-text-input sis-global-text-overflow-ellipsis"
            [matAutocomplete]="autoPlaylist"
            formControlName="playlist"
            (ngModelChange)="this.updateFilter($event, 'playlist')"
            data-test="sis-screeneditor-playlist-input"
          />
          <mat-autocomplete
            #autoPlaylist="matAutocomplete"
            (optionSelected)="selectPlaylist($event)"
            [displayWith]="this.formatInputValue.bind(this)"
          >
            <mat-option
              *ngFor="let playlist of this.playlistsFiltered"
              [value]="playlist"
              data-test="sis-screeneditor-playlist"
            >
              {{ playlist.name }}
            </mat-option>
          </mat-autocomplete>
        </ion-col>
        <ion-col
          size="auto"
          style="cursor: pointer"
          (click)="this.removeSelection('playlist')"
          data-test="sis-screeneditor-reset-playlist-button"
        >
          <ion-icon class="sis-clear-icon" name="close"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5"> {{ 'mediacenter.term.previewUrl' | translate }}: </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="previewUrl"
            maxlength="300"
            data-test="sis-screeneditor-previewUrl-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.previewUrl.errors }"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-modal-row-35">
        <ion-col class="sis-modal-input-label" size="5">{{ 'mediacenter.term.screen.type' | translate }}:</ion-col>
        <ion-col>
          <input
            type="text"
            class="sis-modal-text-input sis-global-text-overflow-ellipsis"
            style="min-width: 180px"
            [matAutocomplete]="autoScreenType"
            formControlName="screenType"
            (ngModelChange)="this.updateFilter($event, 'screenType')"
            data-test="sis-screeneditor-screen-type-input"
          />
          <mat-autocomplete
            #autoScreenType="matAutocomplete"
            (optionSelected)="selectScreenType($event)"
            [displayWith]="this.formatInputValue.bind(this)"
          >
            <mat-option
              *ngFor="let screenType of this.screenTypesFiltered"
              [value]="screenType"
              data-test="sis-screeneditor-screen-type"
            >
              {{ screenType.type }}
            </mat-option>
          </mat-autocomplete>
        </ion-col>
        <ion-col
          size="auto"
          style="cursor: pointer"
          (click)="this.removeSelection('screenType')"
          data-test="sis-screeneditor-reset-screen-type-button"
        >
          <ion-icon class="sis-clear-icon" name="close"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="this.showInfoBannerAllowedCheckbox"
        class="sis-modal-row-35"
        data-test="sis-screeneditor-infobannerAllowed-row"
      >
        <ion-col size="5" class="sis-allow-infobanner sis-modal-input-label">
          {{ 'mediacenter.term.infoBannerAllowed' | translate }}:
        </ion-col>
        <ion-col style="padding-top: 5px">
          <ion-checkbox
            formControlName="infoBannerAllowed"
            labelPlacement="start"
            color="secondary"
            data-test="sis-screeneditor-infobannerAllowed-checkbox"
          ></ion-checkbox>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [disabled]="!this.formGroup.dirty || this.formGroup.invalid"
      [btnType]="'Secondary'"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      (sisClick)="this.save()"
      [showSpinner]="this.screen.updating"
      data-test="sis-screeneditor-save-btn"
    >
    </sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      (sisClick)="this.close()"
    >
    </sis-general-button>
  </div>
</ion-footer>
