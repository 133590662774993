<ion-header>
  <ion-toolbar>
    <ion-title data-test="sis-journey-editor-title">
      {{ (this.journey.items == null ? 'timetable.phrase.newJourney' : 'timetable.phrase.editJourney') | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()" data-test="timetable-close-modal-button"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-global-modal-grid">
    <ion-row>
      <form [formGroup]="this.journeyFormsGroup">
        <ion-row class="sis-timetable-editor-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{ 'timetable.term.vehicleNumberAbbr' | translate }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-input
              type="text"
              class="sis-global-input sis-global-text-input"
              formControlName="vehicleNumber"
              maxlength="10"
              size="10"
              [ngClass]="{ 'sis-global-input-error': this.journeyFormsGroup.controls.vehicleNumber?.errors }"
              data-test="timetable-vehicle-number-input"
            ></ion-input>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!this.hasMoreThanTwoStations" class="sis-timetable-editor-row" data-test="sis-timetable-editor-direction-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{ 'timetable.term.direction' | translate }}</ion-label>
          </ion-col>
          <ion-col>
            <ion-row class="sis-timetable-editor-row">
              <ion-radio-group formControlName="direction" style="width: 100%">
                <ion-col size="2">
                  <ion-col size="3" style="padding-left: 0px; margin-left: -5px">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="1"
                      [disabled]="!this.isNewJourney"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.up' | translate }}"
                    >
                      <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
                <ion-col size="2">
                  <ion-col size="3">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="2"
                      [disabled]="!this.isNewJourney"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.down' | translate }}"
                    >
                      <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
                <ion-col size="2">
                  <ion-col size="3">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="0"
                      [disabled]="!this.isNewJourney"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.both' | translate }}"
                    >
                      <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
                      <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
              </ion-radio-group>
            </ion-row>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!this.hasMoreThanTwoStations" class="sis-timetable-editor-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{ 'timetable.term.departureTime' | translate }}</ion-label>
          </ion-col>
          <ion-col>
            <ion-label class="sis-journey-editor-label" data-test="timetable-station-name" fixed>
              <span *ngIf="this.journeyFormsGroup.value.direction === 0"> {{ this.stations[0].name }} / {{ this.stations[1].name }} </span>
              <span *ngIf="this.journeyFormsGroup.value.direction === 1">
                {{ this.stations[0].name }}
              </span>
              <span *ngIf="this.journeyFormsGroup.value.direction === 2">
                {{ this.stations[1].name }}
              </span>
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-input
              type="time"
              formControlName="departureTime"
              class="sis-global-input"
              data-test="timetable-departure-time-input"
              [ngClass]="{ 'sis-global-input-error': this.journeyFormsGroup.errors?.departureTimesInvalid }"
              required
            ></ion-input>
          </ion-col>
        </ion-row>

        <ng-container *ngIf="this.hasMoreThanTwoStations">
          <ion-row *ngFor="let station of this.stations; let i = index" class="sis-timetable-editor-row" data-test="sis-timetable-time-input">
            <ion-col size="4">
              <ion-label *ngIf="i == 0" class="sis-journey-editor-label" fixed>{{ 'timetable.term.departureTime' | translate }}</ion-label>
            </ion-col>
            <ion-col>
              <ion-label class="sis-journey-editor-label" data-test="timetable-station-name" fixed>
                {{ station.name }}
              </ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-input
                type="time"
                [formControlName]="'departureTime_' + station.guid"
                class="sis-global-input"
                [ngClass]="{ 'sis-global-input-error': this.journeyFormsGroup.errors?.departureTimesInvalid }"
                data-test="timetable-departure-time-input"
                required
              ></ion-input>
            </ion-col>
          </ion-row>
        </ng-container>
      </form>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [disabled]="!this.journeyFormsGroup.dirty || this.isSaving"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [showSpinner]="this.isSaving"
      width="120px"
      data-test="timetable-save-button"
    >
    </sis-general-button>
    <sis-general-button [text]="'general.term.cancel'" [btnType]="'Secondary'" (sisClick)="this.close()" width="120px"> </sis-general-button>
  </div>
</ion-footer>
