<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ sisMediaItem.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="sis-global-row-60 vertical-align-content">
      <ion-col size="1.5" style="padding-left: 20px">
        {{ 'operatinghours.term.active' | translate }}
      </ion-col>
      <ion-col size="auto">
        <ion-toggle
          color="primary"
          *ngIf="this.readPermission || this.writePermission"
          class="sis-toggle-input"
          [ngModel]="this.operatingHoursEnabled"
          (ngModelChange)="toggleChanged($event)"
          [disabled]="!this.writePermission || this.isSaving"
        ></ion-toggle>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-grid *ngIf="this.bigScreenMode" class="sis-operating-hours-table-grid">
          <sis-sismedia-operating-hours-item
            class="sis-alternate-background"
            *ngFor="let operatingHoursEntry of this.operatingHoursEntriesEdit"
            [disabled]="!this.writePermission || !this.operatingHoursEnabled"
            [operatingHoursEntry]="operatingHoursEntry"
            [bigScreenMode]="bigScreenMode"
            (isEdited)="this.setIsEdited($event)"
          ></sis-sismedia-operating-hours-item>
        </ion-grid>
        <ion-grid *ngIf="!this.bigScreenMode" class="sis-operating-hours-table-grid-small">
          <sis-sismedia-operating-hours-item
            class="sis-alternate-background-small"
            *ngFor="let operatingHoursEntry of this.operatingHoursEntriesEdit"
            [disabled]="!this.writePermission || !this.operatingHoursEnabled"
            [operatingHoursEntry]="operatingHoursEntry"
            [bigScreenMode]="bigScreenMode"
            (isEdited)="this.setIsEdited($event)"
          ></sis-sismedia-operating-hours-item>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      class="sis-global-button-padding-right"
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.writePermission || !this.hasUnsavedChanges || this.isSaving"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="sismedia-operating-hours-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [disabled]="this.isSaving"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="sismedia-operating-hours-cancel-btn"
    ></sis-general-button>
  </div>
</ion-footer>
