import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { TimetableDirection } from 'src/app/timetable/domain/timetable-direction.enum';
import { TimetableInterval } from 'src/app/timetable/domain/timetable-interval.model';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { TimetableStation } from 'src/app/timetable/domain/timetable-station.model';
import { TimetableTrack } from 'src/app/timetable/domain/timetable-track.model';
import { TimetableType } from 'src/app/timetable/domain/timetable-type.enum';
import { JourneyEditorComponent } from 'src/app/timetable/journey-editor-modal/journey-editor.component';
import { SeasonEditorComponent } from 'src/app/timetable/season-editor/season-editor.component';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  templateUrl: './timetable.page.html',
  styleUrls: ['./timetable.page.scss'],
})
export class TimetablePage extends Unsubscriber implements OnInit {
  private readonly thirtyDaysInMs: number = 30 * 24 * 60 * 60 * 1000;

  private readonly seasonWarning$ = new ReplaySubject<{ from: Date; until: Date }>(1);
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  bigScreenMode: boolean;
  tracks: TimetableTrack[];
  activeTrack: TimetableTrack;
  activeSeason: TimetableSeason;
  activeInterval: TimetableInterval;
  activeStations: TimetableStation[] = [];
  writePermission: boolean;
  readPermission: boolean;
  showSeasonWarning: boolean = false;
  showSeasonWarningColor: boolean = true;
  seasonWarningText: string;
  refreshed = true;

  featureId = FeatureId.SISMEDIA_TIMETABLE;

  displayedJourneyElements: TimetableJourney[] = [];
  showDaySeparator: boolean;
  isImporting: boolean;

  constructor(
    private screenSizeService: ScreenSizeService,
    private timetableService: TimetableService,
    private destinationService: DestinationService,
    private modalCtrl: ModalController,
    private confirmationDialogService: ConfirmationDialogService,
    private translateService: TranslateService,
    private logbookModalService: LogbookModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    this.timetableService.timetables$.pipe(takeUntil(this.onDestroy$)).subscribe((tracks) => {
      this.tracks = tracks.sort((a, b) => a.displayOrder - b.displayOrder);
      if (!this.activeTrack) {
        this.activeTrack = tracks[0];
        this.onTrackSelected();
        this.activeTrack.seasons.sort((a, b) => Number(a.isEmergencyTimetable) - Number(b.isEmergencyTimetable));
      } else {
        this.activeTrack = tracks.find((t) => t.guid === this.activeTrack.guid);
        this.onSeasonSelected();
      }

      this.refreshed = false;
      setTimeout(() => (this.refreshed = true));
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.WRITE))
      );
      this.readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.READ))
      );
    });

    this.translateService
      .stream([
        'timetable.phrase.seasonWarningFrom',
        'timetable.phrase.seasonWarningFromUntil',
        'timetable.phrase.seasonWarning',
      ])
      .pipe(
        switchMap((translations: string[]) =>
          this.seasonWarning$.pipe(map((warningDates) => ({ translations, warningDates })))
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ translations, warningDates }) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (warningDates.until == null) {
          if (warningDates.from < today) {
            this.seasonWarningText = translations['timetable.phrase.seasonWarning'].format(
              moment(warningDates.from).format('DD.MM.YYYY')
            );
          } else {
            this.seasonWarningText = translations['timetable.phrase.seasonWarningFrom'].format(
              moment(warningDates.from).format('DD.MM.YYYY')
            );
          }
        } else {
          this.seasonWarningText = translations['timetable.phrase.seasonWarningFromUntil'].format(
            moment(warningDates.from).format('DD.MM.YYYY'),
            moment(warningDates.until).format('DD.MM.YYYY')
          );
        }
      });

    this.timetableService.timetables$
      .pipe(
        switchMap(() => this.timetableService.isImportingMap$),
        takeUntil(this.onDestroy$)
      )
      .subscribe((isImportingMap) => {
        if (this.activeTrack) {
          const isImporting = isImportingMap.get(this.activeTrack.guid);
          this.isImporting = isImporting == null ? false : isImporting;
        }
      });
  }

  onTrackSelected() {
    const now = new Date();
    this.activeStations = this.activeTrack.stations.sort((a, b) => a.stationOrder - b.stationOrder);
    this.activeSeason =
      this.activeTrack.seasons.find((s) => now >= new Date(s.validFrom) && now <= new Date(s.validUntil)) ||
      this.activeTrack.seasons[0];
    this.activeInterval = this.activeTrack.intervals[0];
    this.onSeasonSelected();
    this.onIntervalSelected();
  }

  onSeasonSelected() {
    if (this.activeSeason) {
      this.sortJourneys(this.activeSeason.journeys);

      let journeysTodayAndOthers: TimetableJourney[] = [];
      let journeysTomorrow: TimetableJourney[] = [];

      if (this.activeSeason.isImportTimetable) {
        journeysTodayAndOthers = this.activeSeason.journeys.filter(
          (journey) =>
            this.timetableService.isValidJourneyOfTodayOrTomorrow(journey, 'today') ||
            (!this.timetableService.isValidJourneyOfTodayOrTomorrow(journey, 'today') &&
              !this.timetableService.isValidJourneyOfTodayOrTomorrow(journey, 'tomorrow'))
        );
        journeysTodayAndOthers.forEach((j) => (j.datatest = 'journey-row-today'));
        journeysTomorrow = this.activeSeason.journeys.filter((journey) =>
          this.timetableService.isValidJourneyOfTodayOrTomorrow(journey, 'tomorrow')
        );
        journeysTomorrow.forEach((j) => (j.datatest = 'journey-row-tomorrow'));
      } else {
        journeysTodayAndOthers = this.activeSeason.journeys;
        journeysTomorrow = [];
      }

      this.showDaySeparator = journeysTomorrow.length > 0;

      const displayedJourneyElements = [...journeysTodayAndOthers];
      if (this.showDaySeparator) {
        displayedJourneyElements.push({ guid: null, vehicleNumber: 'day-separator' });
        displayedJourneyElements.push(...journeysTomorrow);
      }

      this.displayedJourneyElements = displayedJourneyElements;
      this.displayedJourneyElements.forEach(
        (j) =>
          (j.isValid = !(
            !this.timetableService.isValidJourneyOfTodayOrTomorrow(j, 'today') &&
            !this.timetableService.isValidJourneyOfTodayOrTomorrow(j, 'tomorrow')
          ))
      );
    }

    this.updateSeasonWarning(this.activeTrack);
  }

  onIntervalSelected() {
    if (this.activeInterval) {
      this.updateSeasonWarning(this.activeTrack);
    }
  }

  async deleteJourney(journey: TimetableJourney): Promise<void> {
    if (!this.writePermission) {
      return;
    }

    const confirmed = await this.confirmationDialogService.presentAlert(
      'timetable.phrase.deleteJourney',
      'general.term.delete'
    );

    if (confirmed) {
      await this.timetableService.deleteJourney(journey.guid);
    }
  }

  openJourneyModal(journey?: TimetableJourney): void {
    if (!this.writePermission) {
      return;
    }
    let isNewJourney = false;
    if (!journey) {
      isNewJourney = true;
      journey = {
        seasonGuid: this.activeSeason.guid,
        direction: TimetableDirection.both,
        vehicleNumber: '',
        items: null,
        date: null,
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: true,
        su: true,
        imported: false,
      };
    }

    const stations = this.activeTrack.stations.sort((a, b) => a.stationOrder - b.stationOrder);
    const season = this.activeSeason;

    this.modalCtrl
      .create({
        component: JourneyEditorComponent,
        cssClass: stations.length > 5 ? 'sis-journey-many-stations-editor-modal' : 'sis-journey-editor-modal',
        backdropDismiss: false,
        componentProps: {
          journey: journey,
          stations: stations,
          season: season,
          isNewJourney: isNewJourney,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  openSeasonModal(): void {
    this.modalCtrl
      .create({
        component: SeasonEditorComponent,
        cssClass: 'sis-season-editor-modal',
        backdropDismiss: false,
        componentProps: {
          season: this.activeSeason,
          seasons: this.activeTrack.seasons,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  async requestTimetableImport(): Promise<void> {
    if (this.writePermission) {
      await this.timetableService.requestTimetableImport(this.activeTrack.guid);
    }
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.SISMEDIA_TIMETABLE]]);
  }

  private updateSeasonWarning(timetableTrack: TimetableTrack): void {
    if (timetableTrack.type !== TimetableType.Default) {
      this.showSeasonWarning = false;
      return;
    }

    const seasons = timetableTrack.seasons;
    let noActiveSeason = true;

    if (seasons.length == 0 || seasons.some((s) => s.isImportTimetable)) {
      this.showSeasonWarning = false;
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const activeSeason = this.activeTrack.seasons.find((s) => {
      const from = new Date(s.validFrom);
      from.setHours(0, 0, 0, 0);
      const until = new Date(s.validUntil);
      until.setHours(0, 0, 0, 0);
      return today.getTime() >= from.getTime() && today.getTime() <= until.getTime();
    });

    let nextGap = activeSeason ? undefined : today;

    if (!nextGap) {
      noActiveSeason = false;
      let currentSeason = activeSeason;
      const nextSeasons = this.activeTrack.seasons.filter((s) => new Date(s.validFrom).getTime() > today.getTime());

      while (!nextGap) {
        const nextFrom = new Date(currentSeason.validUntil);
        nextFrom.setDate(nextFrom.getDate() + 1);
        nextFrom.setHours(0, 0, 0, 0);
        currentSeason = nextSeasons.find((s) => {
          const seasonValidFrom = new Date(s.validFrom);
          seasonValidFrom.setHours(0, 0, 0, 0);
          return seasonValidFrom.getTime() === nextFrom.getTime();
        });

        if (!currentSeason) {
          nextGap = nextFrom;
        }
      }
    }

    if (nextGap.getTime() - today.getTime() > this.thirtyDaysInMs) {
      this.showSeasonWarning = false;
      return;
    }

    this.showSeasonWarning = true;
    this.showSeasonWarningColor = true;

    const firstSeasonAfterGap = [...this.activeTrack.seasons]
      .filter((s) => !s.isEmergencyTimetable)
      .sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime())
      .find((s) => new Date(s.validFrom).getTime() > nextGap.getTime());
    const firstSeasonAfterGapValidFrom = firstSeasonAfterGap ? new Date(firstSeasonAfterGap.validFrom) : undefined;

    if (noActiveSeason) {
      if (!firstSeasonAfterGap && !firstSeasonAfterGapValidFrom) {
        const sortedSeasons = [...this.activeTrack.seasons]
          .filter((s) => !s.isEmergencyTimetable)
          .sort((a, b) => new Date(a.validUntil).getTime() - new Date(b.validUntil).getTime());
        const latestSeasonInThePast = sortedSeasons[sortedSeasons.length - 1];
        nextGap = new Date(latestSeasonInThePast.validUntil);
        nextGap.setHours(0, 0, 0, 0);
        nextGap.setDate(nextGap.getDate() + 1);
      } else {
        const closestSeasonInThePast = [...this.activeTrack.seasons]
          .filter((s) => !s.isEmergencyTimetable)
          .sort((a, b) => new Date(b.validUntil).getTime() - new Date(a.validUntil).getTime())
          .find((s) => new Date(s.validFrom).getTime() < nextGap.getTime());
        nextGap = new Date(closestSeasonInThePast.validUntil);
        nextGap.setHours(0, 0, 0, 0);
        nextGap.setDate(nextGap.getDate() + 1);
      }
      this.showSeasonWarningColor = false;
    }

    this.seasonWarning$.next({ from: nextGap, until: firstSeasonAfterGapValidFrom });
  }

  private sortJourneys(journeys: TimetableJourney[]): void {
    journeys.forEach((journey) => {
      journey.items.sort((a, b) => (a.departureTime > b.departureTime ? 1 : -1));
    });
    journeys.sort((a, b) => (a.items[0].departureTime.trim() > b.items[0].departureTime.trim() ? 1 : -1));
  }
}
