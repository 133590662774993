import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { LedTickerDisplayed } from 'src/app/led-ticker/domain/led-ticker-displayed.model';
import { LedTickerItem } from 'src/app/led-ticker/domain/led-ticker-item.model';

@Component({
  selector: 'sis-led-ticker-group',
  templateUrl: './led-ticker-group.component.html',
  styleUrls: ['./led-ticker-group.component.scss'],
})
export class LedTickerGroupComponent extends Unsubscriber implements OnInit {
  @Input() ledTickerPageGuid: string;
  @Input() ledTickerDisplayed$: Observable<LedTickerDisplayed[]>;

  @Output() formDataModified: EventEmitter<void> = new EventEmitter<void>();

  editableLedTickerItems: LedTickerItem[];
  pageNr: number;
  alignment: 'left' | 'middle' | 'right';
  active: boolean;
  duration: number;
  maxTextLength: number;
  writePermission: boolean;
  formGroup: FormGroup;

  constructor(private destinationService: DestinationService) {
    super();
  }

  ngOnInit(): void {
    this.ledTickerDisplayed$
      .pipe(
        map((ledTickerPages) => ledTickerPages.find((page) => page.ledTickerPageGuid === this.ledTickerPageGuid)),
        filter((item) => !!item),
        takeUntil(this.onDestroy$)
      )
      .subscribe((item) => {
        this.updateValues(item);
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_LEDTICKER, FeatureAccessLevel.WRITE))
      );

      this.setDisabled(this.writePermission);
    });
  }

  private updateValues(item: LedTickerDisplayed) {
    this.editableLedTickerItems = item.clonedItems.sort((a, b) => a.rowNr - b.rowNr);
    this.maxTextLength = item.maxTextLength;

    const firstItem = this.editableLedTickerItems[0];

    if (firstItem) {
      this.pageNr = firstItem.pageNr;
      this.alignment = firstItem.alignment;
      this.active = firstItem.active;
      this.duration = firstItem.duration;
    }

    this.formGroup = new FormGroup({
      alignment: new FormControl<'left' | 'middle' | 'right'>(this.alignment),
      active: new FormControl<boolean>(this.active),
      duration: new FormControl<number>(this.duration),
    });

    this.editableLedTickerItems.forEach((item, i) =>
      this.formGroup.addControl('text' + i, new FormControl<string>(item.text))
    );

    this.setValues();

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const changedItem = this.formGroup.getRawValue();
      this.editableLedTickerItems.forEach((item, i) => {
        item.active = changedItem.active;
        item.alignment = changedItem.alignment;
        item.duration = this.getNumberOrNull(changedItem.duration);
        item.text = changedItem['text' + i];
      });

      this.formDataModified.emit();
    });
  }

  private setValues(): void {
    const values = {
      alignment: this.alignment,
      active: this.active,
      duration: this.duration,
    };

    this.editableLedTickerItems.forEach((item, i) => {
      const key = 'text' + i;
      values[key] = item.text;
    });
    this.formGroup?.setValue(values);
  }

  private setDisabled(writePermission: boolean): void {
    if (writePermission) {
      Object.values(this.formGroup.controls).forEach((control) => control.enable());
    } else {
      Object.values(this.formGroup.controls).forEach((control) => control.disable());
    }
  }

  private getNumberOrNull(item: string | number): number {
    return Number.parseInt(item + '');
  }
}
