<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ 'sismedia.phrase.assetoverride' | translate }}</ion-title>
    <sis-logbook-button
      *ngIf=" this.bigScreenMode && this.logbookAvailable$ | async"
      class="sis-logbook-button"
      (click)="openLogbook()"
    >
    </sis-logbook-button>
  </ion-toolbar>
</ion-header>

<ion-content class="sis-content">
  <ion-grid>
    <ion-row>
      <ion-col style="margin-top: 10px; padding: 0px">
        <sis-general-button
          [text]="'assetoverride.phrase.newEntry'"
          [btnAlign]="this.bigScreenMode ? 'Left' : ''"
          [btnType]="'Secondary'"
          [width]="this.bigScreenMode ? '120px' : '100%'"
          (sisClick)="addEntry()"
          [disabled]="!this.writePermission || (this.insertingEntry$ | async)"
          data-test="sismedia-asset-override-new-entry-btn"
        ></sis-general-button>
      </ion-col>
    </ion-row>

    <sis-sismedia-asset-override-item
      *ngFor="let assetOverrideItem of this.assetOverrideDatas; index as i"
      class="sis-global-center"
      [assetOverrideItem]="assetOverrideItem"
      [assetOverrideItems]="assetOverrideDatas"
      [sisMediaItem]="this.item"
      (hasChangedEmitter)="changed($event)"
      [showHeader]="i === 0"
      [writePermission]="this.writePermission"
    ></sis-sismedia-asset-override-item>

    <ion-row *ngIf="(this.insertingEntry$ | async)">
      <ion-col>
        <ion-spinner name="dots"></ion-spinner>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!this.hasEntries && !(this.insertingEntry$ | async)" class="sis-no-entry">
      <ion-col> {{ 'assetoverride.phrase.noEntry' | translate }} </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
