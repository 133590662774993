<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'ecomode.term.settings' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid *ngIf="this.editableSettings" class="sis-global-modal-grid sis-ion-grid">
    <form [formGroup]="this.formGroup">
      <ion-row>
        <ion-col style="text-align: center">
          {{ 'ecomode.phrase.thresholdSliderText' | translate }}
          (max. {{ this.maxQuantityPerMinute }} Pers/min.)
        </ion-col>
      </ion-row>
      <ion-row class="sis-threshold-row" style="position: relative">
        <ion-col>
          <ion-range
            formControlName="thresholds"
            [pin]="true"
            [pinFormatter]="pinFormatter"
            [dualKnobs]="true"
          ></ion-range>
          <ion-label class="sis-slider-label sis-slider-label-0">0%</ion-label>
          <ion-label class="sis-slider-label sis-slider-label-100">100%</ion-label>
          <ion-label
            class="sis-slider-label sis-slider-threshold"
            [ngStyle]="{ left: 'calc(' + this.editableSettings.thresholdLow + '% - 34px)' }"
            >{{ this.thresholdLowText }}</ion-label
          >
          <ion-label
            class="sis-slider-label sis-slider-threshold"
            [ngStyle]="{ left: 'calc(' + this.editableSettings.thresholdMedium + '% - 34px)' }"
            >{{ this.thresholdMediumText }}</ion-label
          >
        </ion-col>
      </ion-row>

      <!-- Big Screen -->
      <ng-container *ngIf="this.bigScreenMode$ | async">
        <ion-row style="padding-top: 50px">
          <ion-col></ion-col>
          <ion-col size="6"> {{ 'ecomode.term.empty' | translate }} </ion-col><ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedEmpty"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col size="1" class="sis-unit-col"> m/s </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <ion-row [ngClass]="{ 'sis-threshold-disabled': this.editableSettings.thresholdLow === 0 }">
          <ion-col></ion-col>
          <ion-col size="2"> {{ 'ecomode.term.threshold' | translate }} 1 </ion-col>
          <ion-col size="1" class="sis-text-right">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="1" class="sis-text-right"> 0 % </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ this.editableSettings.thresholdLow }} %:</ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedLow"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col size="1" class="sis-unit-col"> m/s </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <ion-row
          [ngClass]="{
            'sis-threshold-disabled': this.editableSettings.thresholdLow === this.editableSettings.thresholdMedium
          }"
        >
          <ion-col></ion-col>
          <ion-col size="2"> {{ 'ecomode.term.threshold' | translate }} 2 </ion-col>
          <ion-col size="1" class="sis-text-right">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ this.editableSettings.thresholdLow }} % </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ this.editableSettings.thresholdMedium }} %:</ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedMedium"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col size="1" class="sis-unit-col"> m/s </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <ion-row [ngClass]="{ 'sis-threshold-disabled': this.editableSettings.thresholdMedium === 100 }">
          <ion-col></ion-col>
          <ion-col size="2"> {{ 'ecomode.term.threshold' | translate }} 3 </ion-col>
          <ion-col size="1" class="sis-text-right">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ this.editableSettings.thresholdMedium }} % </ion-col>
          <ion-col size="1" class="sis-text-right"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="1" class="sis-text-right"> 100 %:</ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedHigh"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col size="1" class="sis-unit-col"> m/s </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ng-container>

      <!-- Small Screen -->
      <ng-container *ngIf="!(this.bigScreenMode$ | async)">
        <ion-row class="sis-row-mobile" style="padding-top: 50px">
          <ion-col size="3">
            {{ 'ecomode.term.empty' | translate }}
          </ion-col>
          <ion-col size="auto" class="sis-percent-col"></ion-col>
          <ion-col size="auto" class="sis-percent-col"></ion-col>
          <ion-col size="auto" class="sis-from-col"></ion-col>
          <ion-col size="auto" class="sis-to-col"></ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedEmpty"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col class="sis-unit-col"> m/s </ion-col>
        </ion-row>

        <ion-row
          class="sis-row-mobile"
          [ngClass]="{ 'sis-threshold-disabled': this.editableSettings.thresholdLow === 0 }"
        >
          <ion-col size="3"> {{ 'ecomode.term.threshold' | translate }} 1 </ion-col>
          <ion-col size="auto" class="sis-text-right sis-from-col">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col"> 0 % </ion-col>
          <ion-col size="auto" class="sis-text-right sis-to-col"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col">
            {{ this.editableSettings.thresholdLow }} %:
          </ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedLow"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col class="sis-unit-col"> m/s </ion-col>
        </ion-row>

        <ion-row
          class="sis-row-mobile"
          [ngClass]="{
            'sis-threshold-disabled': this.editableSettings.thresholdLow === this.editableSettings.thresholdMedium
          }"
        >
          <ion-col size="3"> {{ 'ecomode.term.threshold' | translate }} 2 </ion-col>
          <ion-col size="auto" class="sis-text-right sis-from-col">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col">
            {{ this.editableSettings.thresholdLow }} %
          </ion-col>
          <ion-col size="auto" class="sis-text-right sis-to-col"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col">
            {{ this.editableSettings.thresholdMedium }} %:
          </ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedMedium"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col class="sis-unit-col"> m/s </ion-col>
        </ion-row>

        <ion-row
          class="sis-row-mobile"
          [ngClass]="{ 'sis-threshold-disabled': this.editableSettings.thresholdMedium === 100 }"
        >
          <ion-col size="3"> {{ 'ecomode.term.threshold' | translate }} 3 </ion-col>
          <ion-col size="auto" class="sis-text-right sis-from-col">
            {{ 'general.term.from' | translate }}
          </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col">
            {{ this.editableSettings.thresholdMedium }} %
          </ion-col>
          <ion-col size="auto" class="sis-text-right sis-to-col"> {{ 'general.term.to' | translate }} </ion-col>
          <ion-col size="auto" class="sis-text-right sis-percent-col"> 100 %: </ion-col>
          <ion-col size="0.5"></ion-col>
          <ion-col size="auto" class="sis-text-right">
            <ion-input
              formControlName="speedHigh"
              class="sis-input"
              maxlength="3"
              [ngClass]="{ 'sis-input-error': this.formGroup.errors?.speed }"
            />
          </ion-col>
          <ion-col class="sis-unit-col"> m/s </ion-col>
        </ion-row>
      </ng-container>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="this.saving || !this.edited || this.formGroup.invalid"
      [showSpinner]="this.saving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : 'calc(50% - 3px)'"
      class="sis-global-button-padding-right"
      width="120px"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [btnAlign]="'Right'"
      (sisClick)="this.close()"
      [showSpinner]="this.saving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : 'calc(50% - 3px)'"
    ></sis-general-button>
  </div>
</ion-footer>
