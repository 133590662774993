<ion-grid class="detailview-container sis-global-no-scrollbar">
  <ion-row class="title-row">
    <ion-col size="1"></ion-col>
    <ion-col size="3">{{ 'ropeway.term.vehicleId' | translate }}</ion-col>
    <ion-col class="value-list-item">{{ 'ropeway.term.transportWeightTonne' | translate }}</ion-col>
    <ion-col size="1"></ion-col>
  </ion-row>
  <hr />
  <ion-row *ngFor="let id of vehicleId; let i = index" class="data-row" [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
    <ion-col size="1"></ion-col>
    <ion-col size="2">{{ id }}</ion-col>
    <ion-col class="value-list-item">
      {{ transportWeightTons[i] }}
    </ion-col>
    <ion-col size="1"></ion-col>
  </ion-row>
</ion-grid>
