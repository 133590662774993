import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { SlopesOperatingTimes } from 'src/app/slopes-operating-times/domain/slopes-operating-times.model';
import { SlopesOperatingTimesService } from 'src/app/slopes-operating-times/slopes-operating-times.service';

@Component({
  selector: 'sis-slopes-operating-times-item',
  templateUrl: './slopes-operating-times-item.component.html',
  styleUrls: ['./slopes-operating-times-item.component.scss'],
})
export class SlopesOperatingTimesItemComponent extends Unsubscriber implements OnInit {
  @Input() guid: string;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  private originalItem: SlopesOperatingTimes;
  editableItem: SlopesOperatingTimes;
  formGroup: FormGroup;

  constructor(
    private slopesOperatingTimesService: SlopesOperatingTimesService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      firstRide: new FormControl(),
      lastRide: new FormControl(),
      lastSlopeControl: new FormControl(),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.editableItem.firstRide = value.firstRide;
      this.editableItem.lastRide = value.lastRide;
      this.editableItem.lastSlopeControl = value.lastSlopeControl;

      this.setEdited();
    });

    this.slopesOperatingTimesService.slopesOperatingTimes$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((slopesOperatingTimes) => {
        this.originalItem = slopesOperatingTimes.find((s) => s.guid === this.guid);
        this.reset();
      });

    this.slopesOperatingTimesService.requestReset$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.reset());
  }

  reset(): void {
    this.editableItem = JSON.parse(JSON.stringify(this.originalItem));
    this.setValues(this.editableItem);
  }

  private setValues(item: SlopesOperatingTimes): void {
    this.formGroup.setValue({
      firstRide: item.firstRide,
      lastRide: item.lastRide,
      lastSlopeControl: item.lastSlopeControl,
    });
  }

  private setEdited(): void {
    const item = this.hasChanges() ? this.editableItem : null;
    this.slopesOperatingTimesService.setEdited(this.guid, item);
  }

  private hasChanges(): boolean {
    return (
      this.editableItem.firstRide !== this.originalItem.firstRide ||
      this.editableItem.lastRide !== this.originalItem.lastRide ||
      this.editableItem.lastSlopeControl !== this.originalItem.lastSlopeControl
    );
  }
}
