<ion-grid
  class="sis-global-page-grid"
  [ngStyle]="{ 'padding-bottom': this.bigScreenMode ? null : '60px', 'height': '100%'}"
>
  <sis-page-title
    [title]="'general.term.emaildelivery'"
    [featureId]="[[this.featureId]]"
  >
  </sis-page-title>

  <ion-segment
    [value]="this.selectedTab"
    (ionChange)="selectionChanged($event.detail.value)"
    *ngIf="bigScreenMode"
    [disabled]="!this.controlsEnabled"
  >
    <ion-segment-button value="0" data-test="email-delivery-createemail-tab">
      <ion-label>{{'emaildelivery.phrase.createEmail' | translate}}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="1" data-test="email-delivery-addressmanagement-tab">
      <ion-label>{{'emaildelivery.term.addressManagement' | translate}}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div *ngIf="!this.emailDelivery" class="sis-spinner-div">
    <ion-spinner></ion-spinner>
  </div>

  <ng-container *ngIf="this.selectedTab === '0' && this.emailDelivery">
    <ion-row>
      <ion-col style="margin-top: 10px">
        <ion-input
          type="text"
          maxlength="100"
          [value]="this.subjectText"
          debounce="100"
          class="sis-global-input sis-global-text-input"
          [ngClass]="{'sis-illegal-value': this.isEmpty(this.subjectText)}"
          [placeholder]="'emaildelivery.term.subjectPlaceHolder' | translate"
          (ionChange)="this.subjectTextChanged($event.detail.value)"
          rows="1"
          inputmode="text"
          data-test="email-delivery-subjectinput"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-textarea
          type="text"
          [value]="this.emailText"
          debounce="100"
          class="sis-global-textarea-no-scrollbar sis-global-textarea sis-global-input"
          [ngClass]="{'sis-illegal-value': this.isEmpty(this.emailText)}"
          [placeholder]="'emaildelivery.term.emailPlaceHolder' | translate"
          (ionChange)="this.emailTextChanged($event.detail.value)"
          [rows]="22"
          data-test="email-delivery-textarea"
        ></ion-textarea>
      </ion-col>
    </ion-row>

    <!-- Big Screen -->
    <ion-row *ngIf="this.bigScreenMode">
      <ion-col class="sis-select-col">
        <ion-select
          *ngIf="!this.rerender"
          [(ngModel)]="selectedAttachment"
          interface="popover"
          [interfaceOptions]="{ cssClass: 'sis-global-popover', size:'cover' }"
          class="sis-global-input"
          (ionChange)="this.attachmentChanged($event.detail.value)"
          data-test="email-delivery-attachment-selector"
        >
          <ion-select-option
            *ngFor="let item of this.attachments"
            data-test="email-delivery-attachment-selector-option"
            [value]="item"
          >
            {{item.name | translate}}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col
        size="0.5"
        matTooltip="{{(this.selectedAttachment?.outdated ? 'emaildelivery.phrase.warning' : '') | translate}}"
        style="text-align: center"
      >
        <ion-icon
          *ngIf="this.selectedAttachment?.outdated"
          class="sis-emaildelivery-icon"
          name="warning-outline"
          data-test="emaildelivery-warning-icon"
        ></ion-icon>
      </ion-col>

      <ion-col size="auto" style="margin-top: 5px">
        <sis-general-button
          [text]="'emaildelivery.phrase.generatePdf'"
          class="sis-general-button"
          [btnType]="'Secondary'"
          [disabled]="!this.writePermission || !this.selectedAttachment || this.selectedAttachment.pdfUrl == null || this.isPdfCreating"
          [showSpinner]="this.isPdfCreating"
          width="120px"
          (click)="this.writePermission && createPdf()"
          data-test="emaildelivery-createpdf-btn"
        ></sis-general-button>
        <sis-general-button
          [text]="'general.term.download'"
          class="sis-general-button"
          [btnType]="'Secondary'"
          [disabled]="!this.writePermission || this.selectedAttachment?.outdated || this.selectedAttachment?.pdfUrl == null"
          width="120px"
          (click)="this.writePermission && downloadPdf()"
          data-test="emaildelivery-downloadpdf-btn"
        ></sis-general-button>
      </ion-col>
      <ion-col style="text-align: right; margin-top: 5px">
        <sis-general-button
          [text]="'emaildelivery.phrase.sendTestEmail' | translate"
          class="sis-general-button"
          [btnType]="'Secondary'"
          [disabled]="!this.writePermission || this.isSending || this.emptySubject || this.emptyText || this.selectedAttachment?.outdated"
          [showSpinner]="this.isSending"
          width="120px"
          (click)="this.writePermission && openTestEmailDialog()"
          data-test="emaildelivery-sendtestemail-btn"
        ></sis-general-button>
        <sis-general-button
          [text]="'emaildelivery.phrase.sendEmail' | translate"
          class="sis-general-button"
          [btnType]="'Secondary'"
          [disabled]="!this.writePermission || this.isSending || this.emptySubject || this.emptyText || this.selectedAttachment?.outdated"
          [showSpinner]="this.isSending"
          width="120px"
          (click)="this.writePermission && sendEmail()"
          data-test="emaildelivery-sendemail-btn"
        ></sis-general-button>
      </ion-col>
    </ion-row>

    <!-- Small Screen -->
    <ion-grid *ngIf="!this.bigScreenMode">
      <ion-row>
        <ion-col style="padding-left: 0px" class="sis-attachment-selector-col">
          <ion-select
            *ngIf="!this.rerender"
            [(ngModel)]="selectedAttachment"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover', size:'cover' }"
            class="sis-global-input"
            (ionChange)="this.attachmentChanged($event.detail.value)"
            data-test="email-delivery-attachment-selector"
          >
            <ion-select-option
              *ngFor="let item of this.attachments"
              data-test="email-delivery-attachment-selector-option"
              [value]="item"
            >
              {{item.name | translate}}
            </ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col
          size="auto"
          matTooltip="{{(this.selectedAttachment?.outdated ? 'emaildelivery.phrase.warning' : '') | translate}}"
          style="text-align: center; padding: 0px; min-width: 20px"
        >
          <ion-icon
            *ngIf="this.selectedAttachment?.outdated"
            class="sis-emaildelivery-icon-small"
            name="warning-outline"
            data-test="emaildelivery-warning-icon"
          ></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col style="padding: 2px 3px 3px 0px">
          <sis-general-button
            [text]="'emaildelivery.phrase.generatePdf'"
            [btnType]="'Secondary'"
            [disabled]="!this.writePermission || !this.selectedAttachment || this.selectedAttachment.pdfUrl == null || this.isPdfCreating"
            [showSpinner]="this.isPdfCreating"
            width="100%"
            (click)="this.writePermission && createPdf()"
            data-test="emaildelivery-createpdf-btn"
          ></sis-general-button>
        </ion-col>
        <ion-col style="padding: 2px 0px 3px 3px">
          <sis-general-button
            [text]="'general.term.download'"
            [btnType]="'Secondary'"
            [disabled]="!this.writePermission || this.selectedAttachment?.outdated || this.selectedAttachment?.pdfUrl == null"
            width="100%"
            (click)="this.writePermission && downloadPdf()"
            data-test="emaildelivery-downloadpdf-btn"
          ></sis-general-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col style="padding: 0px 3px 3px 0px">
          <sis-general-button
            [text]="'emaildelivery.phrase.sendTestEmail' | translate"
            [btnType]="'Secondary'"
            [disabled]="!this.writePermission || this.isSending || this.emptySubject || this.emptyText || this.selectedAttachment?.outdated"
            [showSpinner]="this.isSending"
            width="100%"
            (click)="this.writePermission && openTestEmailDialog()"
            data-test="emaildelivery-sendtestemail-btn"
          ></sis-general-button>
        </ion-col>
        <ion-col style="padding: 0px 0px 3px 3px">
          <sis-general-button
            [text]="'emaildelivery.phrase.sendEmail' | translate"
            [btnType]="'Secondary'"
            [disabled]="!this.writePermission || this.isSending || this.emptySubject || this.emptyText || this.selectedAttachment?.outdated"
            [showSpinner]="this.isSending"
            width="100%"
            (click)="this.writePermission && sendEmail()"
            data-test="emaildelivery-sendemail-btn"
          ></sis-general-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-row *ngIf="this.isPdfCreating" style="margin-bottom: 50px">
      <ion-col>
        <ion-item style="--border-style: none; --padding-start: 0px">
          <ion-icon
            name="information-circle-outline"
            [style.font-size]="this.bigScreenMode ? null : '30pt'"
            style="color: var(--ion-color-secondary); pointer-events: none"
          ></ion-icon>
          <span class="sis-pdf-creating-text">{{ 'emaildelivery.phrase.pdfGenerating' | translate }}</span>
        </ion-item>
      </ion-col>
    </ion-row>
  </ng-container>

  <!-- Address management -->
  <ng-container *ngIf="this.selectedTab === '1'">
    <!-- BigScreen -->
    <div *ngIf="this.bigScreenMode">
      <ion-row style="padding-top: 20px">
        <ion-col size="4" class="ion-no-padding">
          <ion-searchbar
            placeholder="{{ 'general.term.filter' | translate }}"
            type="text"
            [formControl]="searchControl"
            data-test="email-delivery-address-filter"
          >
          </ion-searchbar> </ion-col
        ><ion-col></ion-col>
        <ion-col size="auto" class="ion-no-padding">
          <sis-general-button
            class="sis-recipient-new-button"
            [text]="'emaildelivery.term.newRecipient'"
            [btnType]="'Secondary'"
            (sisClick)="this.writePermission && this.controlsEnabled && this.addRecipient()"
            [disabled]="!this.writePermission || !this.controlsEnabled"
            data-test="email-delivery-recipient-new-button"
          ></sis-general-button>
        </ion-col>
      </ion-row>
      <ion-row class="sis-emaildelivery-header">
        <ion-col size="4">{{ 'general.term.name' | translate }}</ion-col>
        <ion-col size="3">{{ 'destination.term.location' | translate }}</ion-col>
        <ion-col size="4">{{ 'general.term.email' | translate }}</ion-col>
      </ion-row>
    </div>

    <!-- SmallScreen -->
    <div *ngIf="!this.bigScreenMode">
      <ion-row class="ion-no-padding">
        <ion-col style="padding: 5px 0px">
          <sis-general-button
            class="sis-recipient-new-button"
            [text]="'emaildelivery.term.newRecipient'"
            [btnAlign]="'Right'"
            [btnType]="'Secondary'"
            (sisClick)="this.writePermission && this.controlsEnabled && this.addRecipient()"
            [disabled]="!this.writePermission || !this.controlsEnabled"
            data-test="email-delivery-recipient-new-button"
          ></sis-general-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col style="padding: 5px 0px">
          <ion-searchbar
            placeholder="{{ 'general.term.filter' | translate }}"
            style="height: 40px"
            type="text"
            [formControl]="searchControl"
            data-test="email-delivery-address-filter"
          >
          </ion-searchbar>
        </ion-col>
      </ion-row>
    </div>

    <!-- Small and BigScreen -->
    <ion-item
      *ngIf="this.newRecipient"
      [ngClass]="this.bigScreenMode ? 'sis-recipient-item' : 'sis-recipient-item-small'"
    >
      <ion-grid style="padding: 0px">
        <sis-recipient-item
          [recipient]="recipient"
          [controlsEnabled]="this.writePermission && this.controlsEnabled"
          [isEditing]="true"
          (editing)="this.editItem($event)"
          data-test="email-delivery-new-recipient"
        ></sis-recipient-item>
      </ion-grid>
    </ion-item>

    <ng-container *ngIf="this.recipients$ | async; else loading" class="sis-global-no-scrollbar">
      <ion-item
        *ngFor="let recipient of this.recipients$ | async; let odd = odd"
        [ngClass]="odd ? 'sis-white-background' : 'sis-colored-background'"
        [class]="this.bigScreenMode ? 'sis-recipient-item' : 'sis-recipient-item-small'"
      >
        <ion-grid style="padding: 0px">
          <sis-recipient-item
            [recipient]="recipient"
            [controlsEnabled]="this.writePermission && this.controlsEnabled"
            (editing)="this.editItem($event)"
            data-test="email-delivery-recipient"
          ></sis-recipient-item>
        </ion-grid>
      </ion-item>
    </ng-container>

    <ng-template #loading>
      <div class="sis-spinner-div">
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>
  </ng-container>

  <!-- tab bar -->
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-tab-grid">
    <ion-row>
      <ion-col (click)="this.controlsEnabled && this.selectionChanged('0')" class="sis-tab-col" size="6">
        <ion-icon
          name="mail-outline"
          class="sis-tab-icon"
          [ngClass]="this.selectedTab === '0' ? 'sis-selected': 'sis-not-selected'"
          [ngStyle]="{'opacity': this.controlsEnabled ? '1' : '0.5'}"
        ></ion-icon>
        <span class="sis-tab-span" [ngClass]="this.selectedTab === '0' ? 'sis-selected': 'sis-not-selected'"
          >{{ 'emaildelivery.phrase.createEmail' | translate }}</span
        >
      </ion-col>
      <ion-col
        (click)="this.controlsEnabled && this.selectionChanged('1')"
        class="sis-tab-col"
        size="6"
        [disabled]="!this.controlsEnabled"
      >
        <ion-icon
          name="book-outline"
          class="sis-tab-icon"
          [ngClass]="this.selectedTab === '1' ? 'sis-selected': 'sis-not-selected'"
          [ngStyle]="{'opacity': this.controlsEnabled ? '1' : '0.5'}"
        ></ion-icon>
        <span class="sis-tab-span" [ngClass]="this.selectedTab === '1' ? 'sis-selected': 'sis-not-selected'"
          >{{ 'emaildelivery.term.addressManagement' | translate }}</span
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-grid>
