<!-- Big Screen -->
<ion-row *ngIf="this.bigScreenMode" class="sis-ion-row vertical-align-content sis-global-pointer-cursor sis-global-hover">
  <ion-col size="auto" class="ion-align-items-center" style="margin-right: 10px" (click)="this.openLink()" data-test="weblink-item-icon-desktop">
    <ion-icon class="sis-icon" [name]="this.item.iconName"></ion-icon>
  </ion-col>
  <ion-col style="min-width: 600px" class="sis-truncate" data-test="weblink-item-description-desktop" (click)="this.openLink()">
    <div class="sis-truncate" style="width: 100%">
      {{ this.item.description }}
    </div>
  </ion-col>
  <ion-col class="sis-truncate sis-link" (click)="this.openLink()" data-test="weblink-item-url-desktop">
    <div class="sis-truncate" style="width: 100%">
      {{ this.item.url }}
    </div>
  </ion-col>
  <ion-col
    *ngIf="this.showButtons"
    size="auto"
    class="sis-copy"
    (click)="this.copyUrl()"
    matTooltip="{{ 'weblinkcollection.phrase.copyUrl' | translate }}"
    data-test="weblink-copy-url-button"
  >
    <ion-icon class="sis-icon sis-color" name="copy-outline"></ion-icon>
  </ion-col>

  <ion-col
    *ngIf="this.showButtons"
    size="auto"
    class="sis-copy"
    (click)="this.openEditModal()"
    [ngClass]="this.editPermission ? 'sis-color' : 'sis-color-disabled'"
    [style.cursor]="this.editPermission ? 'pointer' : 'default'"
    matTooltip="{{ 'general.term.edit' | translate }}"
    [disabled]="!this.editPermission"
    [matTooltipDisabled]="!this.editPermission"
    [attr.data-test]="'weblink-edit-button-' + this.item.category"
  >
    <ion-icon class="sis-icon" name="create-outline"> </ion-icon>
  </ion-col>
</ion-row>

<!-- Small Screen -->
<ion-row *ngIf="!this.bigScreenMode" class="sis-ion-row-small vertical-align-content" (press)="this.openEditModal()">
  <ion-col size="auto" class="ion-align-items-center">
    <ion-icon class="sis-icon-small" [name]="this.item.iconName"></ion-icon>
  </ion-col>
  <ion-col (click)="this.openLink()">
    <ion-grid class="sis-text-grid-small">
      <ion-row>
        <ion-col class="sis-truncate" style="margin-top: 2px">
          {{ this.item.description }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="sis-truncate sis-link" style="font-size: 10pt; padding-top: 2px">
          {{ this.item.url }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
</ion-row>
