<ion-header>
  <ion-toolbar>
    <ion-title data-test="sis-journey-editor-title">
      {{ 'timetable.phrase.editIntervalDay' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()" data-test="timetable-close-modal-button"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <form [formGroup]="this.formGroup">
    <ion-grid class="sis-global-modal-grid">
      <ion-row class="sis-timetable-editor-row">
        <ion-col size="4">
          {{ 'timetable.term.date' | translate }}
        </ion-col>
        <ion-col size="auto"> {{ this.day.date | weekday | translate }}, {{ this.day.date | date : 'dd.MM.yy' }} </ion-col>
      </ion-row>

      <ion-row class="sis-timetable-editor-row">
        <ion-col size="4">
          {{ 'timetable.term.operatingStart' | translate }}
        </ion-col>
        <ion-col>
          <ion-input type="time" formControlName="operatingStart" class="sis-global-input" data-test="timetable-operating-start-input" required>
          </ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-timetable-editor-row">
        <ion-col size="4">
          {{ 'timetable.term.operatingEnd' | translate }}
        </ion-col>
        <ion-col>
          <ion-input type="time" formControlName="operatingEnd" class="sis-global-input" data-test="timetable-operating-end-input" required>
          </ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [disabled]="this.isSaving || !this.isEdited"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [showSpinner]="this.isSaving"
      width="120px"
      data-test="timetable-save-button"
    >
    </sis-general-button>
    <sis-general-button [text]="'general.term.cancel'" [btnType]="'Secondary'" (sisClick)="this.close()" width="120px"> </sis-general-button>
  </div>
</ion-footer>
