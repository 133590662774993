<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.close()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title *ngIf="this.oldItem" data-test="sis-operating-info-entry-edit-header">{{
      'general.term.edit' | translate
    }}</ion-title>
    <ion-title *ngIf="!this.oldItem" data-test="sis-operating-info-entry-edit-header">{{
      'general.term.newEntry' | translate
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding sis-global-noscrollbar">
  <!-- Big Screen -->
  <ion-grid class="sis-modal-grid" *ngIf="this.bigScreenMode">
    <form [formGroup]="formGroup">
      <ion-row class="sis-global-row-50">
        <ion-col size="3"> {{ 'general.term.title' | translate }} </ion-col>
        <ion-col>
          <input
            maxlength="20"
            formControlName="titleDe"
            class="sis-global-input sis-global-text-input"
            [disabled]="this.formGroup.titleDe?.disabled"
            data-test="sis-operating-info-entry-title-input"
          />
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-50">
        <ion-col size="3"> {{ 'general.term.description' | translate }} </ion-col>
        <ion-col>
          <ion-input
            maxlength="50"
            formControlName="descriptionDe"
            class="sis-global-input sis-global-text-input"
            data-test="sis-operating-info-entry-description-input"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row sis-operating-text-row">
        <ion-col class="sis-modal-input sis-operating-text-label" size="3">
          {{ 'general.term.text' | translate }}
        </ion-col>
        <ion-col>
          <ion-textarea
            maxlength="1000"
            rows="6"
            formControlName="textDe"
            class="sis-global-textarea-no-scrollbar sis-global-textarea sis-global-input"
            data-test="sis-operating-info-entry-text-input"
          ></ion-textarea>
        </ion-col>
      </ion-row>
    </form>

    <ion-row>
      <ion-col>
        <div class="sis-label">{{ 'general.term.priority' | translate }}</div>
        <ion-radio-group mode="md" [(ngModel)]="this.libraryItem.priority">
          <ion-item
            style="height: 40px"
            *ngFor="let priority of this.priorities"
            lines="none"
            data-test="sis-operating-info-priority-selector"
          >
            <ion-radio slot="start" [value]="priority.priority"></ion-radio>
            <div class="sis-state-color" [style.background-color]="priority.color"></div>
            <ion-label>{{ priority.description | translate }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid class="sis-modal-grid" *ngIf="!this.bigScreenMode">
    <form [formGroup]="formGroup">
      <div>
        <ion-row class="sis-global-row-30">
          <ion-col> {{ 'general.term.title' | translate }} </ion-col>
        </ion-row>
        <ion-row class="sis-global-row-50">
          <ion-col>
            <input
              maxlength="20"
              class="sis-global-input sis-global-text-input"
              [disabled]="this.formGroup.titleDe?.disabled"
              formControlName="titleDe"
            />
          </ion-col>
        </ion-row>
      </div>
      <ion-row class="sis-global-row-30">
        <ion-col> {{ 'general.term.description' | translate }} </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-50">
        <ion-col>
          <ion-input
            maxlength="50"
            class="sis-global-input sis-global-text-input"
            formControlName="descriptionDe"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-30">
        <ion-col> {{ 'general.term.text' | translate }} </ion-col>
      </ion-row>
      <ion-row class="sis-global-row sis-operating-text-row">
        <ion-col>
          <ion-textarea
            maxlength="1000"
            rows="6"
            formControlName="textDe"
            class="sis-global-textarea-no-scrollbar sis-global-textarea sis-global-input"
          ></ion-textarea>
        </ion-col>
      </ion-row>
    </form>

    <ion-row>
      <ion-col>
        <div>{{ 'general.term.priority' | translate }}</div>
        <ion-radio-group mode="md" [(ngModel)]="this.libraryItem.priority">
          <ion-item
            style="height: 40px"
            *ngFor="let priority of this.priorities"
            lines="none"
            data-test="sis-operating-info-priority-selector"
          >
            <ion-radio slot="start" [value]="priority.priority"></ion-radio>
            <div class="sis-state-color" [style.background-color]="priority.color"></div>
            <ion-label>{{ priority.description | translate }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [ngClass]="{ 'sis-global-button-padding-right': this.bigScreenMode }"
      [btnType]="'Secondary'"
      (sisClick)="this.oldItem ? this.update() : this.insert()"
      [disabled]="!this.formGroup.valid"
      [width]="this.bigScreenMode ? '150px' : '100%'"
      data-test="sis-operating-info-entry-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.close()"
      [width]="this.bigScreenMode ? '150px' : '100%'"
      data-test="sis-operating-info-entry-edit-cancel-btn"
    ></sis-general-button>
  </div>
</ion-footer>
