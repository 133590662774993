<ion-grid *ngIf="this.bigScreenMode$ | async" class="sis-global-page-grid sis-page-grid">
  <sis-page-title
    [title]="this.options.titleTranslationString"
    [featureId]="this.featureId"
    [reorderingEnabled]="this.reorderingEnabled"
    [showAddButton]="this.hasAssetEditPermission"
    [showReorderButton]="this.hasAssetEditPermission"
    [filterControl]="this.filterControl"
    (onReorderClick)="this.onReorderClick()"
    (onReorderCancelClick)="this.onReorderCancelClick()"
    (openEditModal)="this.openEditModal()"
  >
  </sis-page-title>

  <ion-row>
    <ion-col style="padding: 0px">
      <div>
        <ion-reorder-group
          (ionItemReorder)="doReorder($event)"
          [disabled]="!this.reorderingEnabled"
          data-test="sis-sismedia-reorder-group"
        >
          <ion-item
            *ngFor="let item of this.displayedItems; let odd = odd"
            [ngClass]="odd ? 'sis-white-background' : 'sis-colored-background'"
            class="sis-reorder-group-item"
            lines="none"
          >
            <ion-grid style="padding: 0px">
              <sis-sismedia-item
                [item]="item"
                [options]="this.options"
                [bigScreenMode]="true"
                [showInfoCol]="this.anyItemHasInfo"
                [showSisMapsCol]="this.anyItemHasSisMaps"
                [showButtons]="!this.reorderingEnabled"
                [labelLength]="this.labelMaxLength"
                [hasMySqlRouter]="this.hasMySqlRouter"
                (lastChange)="this.lastChange = $event"
                data-test="sismedia-item"
              >
              </sis-sismedia-item>
            </ion-grid>
            <ion-reorder slot="end">
              <ion-icon name="reorder-two" class="sis-sismedia-reorder-handle"></ion-icon>
            </ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="sis-last-change">
    <ion-col *ngIf="this.lastChange">
      {{ 'general.phrase.lastChange' | translate }}: {{ this.lastChange.name }} ({{
        this.lastChange.date | dateTimeFormat
      }})
    </ion-col>
  </ion-row>
</ion-grid>

<nav *ngIf="this.bigScreenMode$ | async" class="sis-floating-menu">
  <ion-grid class="sis-floating-menu-border">
    <ion-row *ngFor="let seasonIcon of this.seasonIcons">
      <ion-col
        *ngIf="!this.reorderingEnabled"
        (click)="this.seasonSelected(seasonIcon.season)"
        class="sis-floating-menu-col"
        size="auto"
      >
        <ion-icon
          [name]="
            (this.selectedSeason$ | async) === seasonIcon.season
              ? this.seasonIcon.iconNameSelected
              : this.seasonIcon.iconName
          "
          class="sis-floating-menu-icon"
        ></ion-icon>
        <span
          class="sis-floating-menu-span"
          [ngClass]="(this.selectedSeason$ | async) === seasonIcon.season ? 'sis-selected' : 'sis-not-selected'"
          >{{ seasonIcon.translateKey | translate }}</span
        >
      </ion-col>
      <ion-col *ngIf="this.reorderingEnabled" class="sis-floating-menu-col" size="auto">
        <ion-icon
          [name]="seasonIcon.season === 0 ? this.seasonIcon.iconNameSelected : this.seasonIcon.iconName"
          class="sis-floating-menu-icon"
          style="cursor: default"
        ></ion-icon>
        <span
          class="sis-floating-menu-span"
          [ngClass]="seasonIcon.season === 0 ? 'sis-selected' : 'sis-not-selected'"
          >{{ seasonIcon.translateKey | translate }}</span
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</nav>

<!-- Small screen -->
<ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-global-page-grid" style="padding-bottom: 60px">
  <sis-page-title
    [title]="this.options.titleTranslationString"
    [featureId]="this.options.featureId"
    [reorderingEnabled]="this.reorderingEnabled"
    [editPermission]="this.hasAssetEditPermission"
    [filterControl]="this.filterControl"
    (onReorderClick)="this.onReorderClick()"
    (onReorderCancelClick)="this.onReorderCancelClick()"
    (openEditModal)="this.openEditModal()"
  >
  </sis-page-title>
  <sis-sismedia-item
    *ngFor="let item of this.displayedItems"
    [item]="item"
    [options]="this.options"
    [bigScreenMode]="false"
    [labelLength]="this.labelMaxLength"
    class="sis-alternate-background"
    (lastChange)="this.lastChange = $event"
  >
  </sis-sismedia-item>

  <ion-row class="sis-last-change">
    <ion-col *ngIf="this.lastChange">
      {{ 'general.phrase.lastChange' | translate }}: {{ this.lastChange.date | dateTimeFormat }}
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-tab-grid">
  <ion-row style="padding: 0px">
    <ion-col
      *ngFor="let seasonIcon of this.seasonIcons"
      (click)="this.seasonSelected(seasonIcon.season)"
      class="sis-tab-col"
      size="4"
    >
      <ion-icon
        [name]="
          (this.selectedSeason$ | async) === seasonIcon.season
            ? this.seasonIcon.iconNameSelected
            : this.seasonIcon.iconName
        "
        class="sis-tab-icon"
      ></ion-icon>
      <span
        class="sis-tab-span"
        [ngClass]="(this.selectedSeason$ | async) === seasonIcon.season ? 'sis-selected' : 'sis-not-selected'"
        >{{ seasonIcon.translateKey | translate }}</span
      >
    </ion-col>
  </ion-row>
</ion-grid>
