<ion-row
  *ngIf="group.guid"
  [class]="this.bigScreenMode$ ? 'sis-mediacenter-group-header' : 'sis-mediacenter-group-header-mobile'"
  data-test="sis-mediacenter-group-header"
  [attr.data-test-group-name]="group.name"
>
  <ion-col>{{ group.name }}</ion-col>
</ion-row>

<!-- Big Screen -->
<ng-container *ngIf="this.bigScreenMode$ | async">
  <ion-reorder-group [disabled]="!this.reorderingEnabled" (ionItemReorder)="doReorder($event)">
    <ion-reorder *ngFor="let screen of this.screens">
      <ion-item class="sis-reorder-group-item">
        <ion-grid class="sis-grid"
          ><ion-row
            class="sis-mediacenter-screens-row sis-global-hover"
            data-test="screens-row"
            [attr.data-test-screen-group]="group.guid"
          >
            <ion-col size="2.5" class="sis-mediacenter-padding-left" data-test="sis-mediacenter-screen-location">
              {{ screen.location }}
              <span style="font-size: 13px; padding-left: 5px; color: #37879c">{{ screen.screenTypeName }}</span
              ><br />
              <span style="font-size: 13px">{{ screen.label }}</span>
            </ion-col>
            <ion-col size="1.2" class="sis-mediacenter-padding-left">
              <span *ngIf="screen.screenWidth !== 0; else noResolution"
                >{{ screen.screenWidth }}x{{ screen.screenHeight }}</span
              >
              <ng-template #noResolution>-</ng-template>
            </ion-col>
            <ion-col
              class="sis-mediacenter-padding-left sis-mediacenter-playlist sis-select-col sis-mediacenter-col-min-200"
            >
              <ion-select
                class="sis-global-input sis-playlist-selector"
                [ngModel]="screen.playlistGuid"
                [ngModelOptions]="{ standalone: true }"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
                (ngModelChange)="selectPlaylist($event, screen)"
                [selectedText]="screen.playlistName"
                matTooltip="{{
                  screen.playlistName ? screen.playlistName : ('mediacenter.term.noPlaylist' | translate)
                }}"
                matTooltipClass="sis-global-tooltip"
              >
                <ion-select-option *ngFor="let item of this.playlists" [value]="item?.guid">{{
                  item?.name
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ng-container *ngIf="!this.reorderingEnabled">
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <a
                  *ngIf="!screen.updating && screen.startTime && screen.endTime"
                  [ngClass]="
                    this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'
                  "
                  (click)="this.openOperatinghourModal(screen)"
                  data-test="operatinghour-button"
                >
                  <ion-icon
                    [ngClass]="
                      screen.continuousOperation
                        ? 'sis-mediacenter-operatinghour-inactive'
                        : 'sis-mediacenter-operatinghour-active'
                    "
                    name="time-outline"
                    matTooltip="{{
                      ('mediacenter.term.operatinghour' | translate) +
                        ':\n' +
                        (screen.continuousOperation
                          ? ('mediacenter.term.operatingThroughout' | translate)
                          : screen.startTime + ' - ' + screen.endTime)
                    }}"
                    matTooltipClass="sis-global-tooltip"
                  ></ion-icon>
                </a>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <ion-icon
                  *ngIf="screen.connectionStatus === 'online'"
                  name="sis-wifi-high"
                  class="sis-mediacenter-connection-status"
                  data-test="screen-connection-status"
                  matTooltip="{{ 'mediacenter.phrase.screenConnectionOnline' | translate }}"
                ></ion-icon>
                <ion-icon
                  *ngIf="screen.connectionStatus === 'offline'"
                  name="sis-wifi-off"
                  class="sis-mediacenter-connection-status"
                  data-test="screen-connection-status"
                  matTooltip="{{ 'mediacenter.phrase.screenConnectionOffline' | translate }}"
                ></ion-icon>
                <div
                  *ngIf="screen.connectionStatus === 'unknown'"
                  matTooltip="{{ 'mediacenter.phrase.screenConnectionUnknown' | translate }}"
                >
                  <span data-test="screen-connection-status">?</span>
                </div>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <a
                  *ngIf="screen.remoteAccess"
                  class="sis-mediacenter-clickable"
                  [ngClass]="{ 'sis-mediacenter-remoteaccess-offline': !screen.remoteAccessOnline }"
                  (click)="screen.remoteAccessOnline && this.openRemoteAccess(screen)"
                  data-test="screen-remoteaccess-button"
                  [matTooltip]="
                    screen.remoteAccessOnline
                      ? ('mediacenter.phrase.openRemoteAccess' | translate)
                      : ('mediacenter.phrase.remoteAccessOffline' | translate)
                  "
                >
                  <ion-icon class="sis-mediacenter-icon" name="sis-remoteaccess"></ion-icon>
                </a>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <a
                  *ngIf="!screen.updating && screen.infoBannerAllowed && this.infoBannerReadPermission"
                  class="sis-mediacenter-clickable"
                  [ngClass]="!screen.hasInfoBannerMapping ? 'sis-mediacenter-infobanner-icon-disabled' : ''"
                  (click)="this.openInfoBannerModal(screen)"
                  data-test="screen-infobanner-button"
                  [matTooltip]="
                    screen.hasInfoBannerMapping
                      ? ('mediacenter.phrase.activeInfoBanner' | translate) + screen.infoBannerName
                      : ('mediacenter.phrase.selectInfoBanner' | translate)
                  "
                >
                  <ion-icon class="sis-mediacenter-icon" name="tv-outline"> </ion-icon>
                </a>
                <ion-spinner *ngIf="screen.updating" name="bubbles"></ion-spinner>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <a
                  *ngIf="!screen.updating"
                  class="sis-mediacenter-clickable"
                  (click)="
                    openSlideShowPreview(screen.label, screen.screenWidth, screen.screenHeight, screen.previewUrl)
                  "
                  matTooltip="{{ 'mediacenter.phrase.openSlideShowPreview' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="open-outline"> </ion-icon>
                </a>
                <ion-spinner *ngIf="screen.updating" name="bubbles"></ion-spinner>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-50">
                <a
                  *ngIf="!screen.updating"
                  class="sis-mediacenter-clickable"
                  (click)="this.copyUrl(screen.label, screen.previewUrl)"
                  matTooltip="{{ 'mediacenter.phrase.copyPlayerUrl' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="copy-outline"> </ion-icon>
                </a>
                <ion-spinner *ngIf="screen.updating" name="bubbles"></ion-spinner>
              </ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100">
                <a
                  *ngIf="!screen.updating"
                  [ngClass]="
                    this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'
                  "
                  (click)="this.openEditscreen(screen)"
                  data-test="screen-edit-button"
                  matTooltip="{{ 'general.term.edit' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="create-outline"> </ion-icon>
                </a>
                <a
                  *ngIf="!screen.updating"
                  [ngClass]="
                    this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'
                  "
                  (click)="this.deleteScreen(screen)"
                  data-test="screen-delete-button"
                  matTooltip="{{ 'general.term.delete' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="trash-outline"> </ion-icon>
                </a>
                <ion-spinner *ngIf="screen.updating" name="bubbles"></ion-spinner>
              </ion-col>
            </ng-container>
            <ng-container *ngIf="this.reorderingEnabled">
              <ion-col class="sis-mediacenter-col-max-550"></ion-col>
              <ion-col class="sis-global-center sis-mediacenter-col-max-100" style="text-align: right">
                <ion-icon name="reorder-two" class="sis-reorder-handle"></ion-icon>
              </ion-col>
            </ng-container>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-reorder>
  </ion-reorder-group>
</ng-container>

<!-- Small Screen -->
<ng-container *ngIf="!(this.bigScreenMode$ | async)">
  <ion-row *ngFor="let screen of this.screens" class="sis-mediacenter-row" style="height: 55px">
    <ion-col class="sis-global-text-overflow-ellipsis"> {{ screen.location }} </ion-col>
    <ion-col class="sis-global-center" size="auto">
      <a
        *ngIf="!screen.updating && screen.infoBannerAllowed"
        class="sis-mediacenter-clickable"
        [ngClass]="!screen.hasInfoBannerMapping ? 'sis-mediacenter-infobanner-icon-disabled' : ''"
        (click)="this.openInfoBannerModal(screen)"
        data-test="screen-infobanner-button"
        matTooltip="{{ 'mediacenter.phrase.selectInfoBanner' | translate }}"
      >
        <ion-icon class="sis-mediacenter-icon" name="tv-outline"> </ion-icon>
      </a>
    </ion-col>

    <ion-col class="sis-global-center" size="auto">
      <a
        *ngIf="!screen.updating && screen.startTime && screen.endTime"
        [ngClass]="this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'"
        (click)="this.openOperatinghourModal(screen)"
        data-test="operatinghour-button"
        matTooltip="{{
          ('mediacenter.term.operatinghour' | translate) +
            ':\n' +
            (screen.continuousOperation
              ? ('mediacenter.term.operatingThroughout' | translate)
              : screen.startTime + ' - ' + screen.endTime)
        }}"
        matTooltipClass="sis-global-tooltip"
      >
        <ion-icon
          class="sis-mediacenter-icon"
          [ngClass]="
            screen.continuousOperation
              ? 'sis-mediacenter-operatinghour-inactive'
              : 'sis-mediacenter-operatinghour-active'
          "
          name="time-outline"
        ></ion-icon>
      </a>
    </ion-col>

    <ion-col class="sis-global-center" size="auto">
      <a
        *ngIf="!screen.updating"
        [ngClass]="this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'"
        (click)="this.writePermission && this.openEditscreen(screen)"
        data-test="screen-edit-button"
        matTooltip="{{ 'general.term.edit' | translate }}"
      >
        <ion-icon class="sis-mediacenter-icon" name="create-outline"> </ion-icon>
      </a>
    </ion-col>
  </ion-row>
</ng-container>
