import { ExportAdapter } from 'src/app/domain/export/export.adapter';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';

export class SisMediaItemAdapter {
  static adapt(items: any): SisMediaItem[] {
    const result: SisMediaItem[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push(
          new SisMediaItem({
            id: e.id,
            assetGuid: e.assetGuid,
            name: e.name,
            label: e.label,
            type: e.type?.toLowerCase(),
            status: e.status,
            activeStatus: e.activeStatus,
            hasActiveOverride: e.hasActiveOverride,
            statusOverrideActive: e.statusOverrideActive,
            lastStatusChange: new Date(e.lastStatusChange),
            cockpitOrder: e.cockpitOrder == null ? 999 : e.cockpitOrder,
            assetCategory: e.assetCategory,
            nameEn: e.nameEn,
            contact: e.contact,
            location: e.location,
            phone: e.phone,
            email: e.email,
            website: e.website,
            info: e.info,
            custom: e.custom,
            lengthMeter: e.lengthMeter,
            throughput: e.throughput,
            firstRide: e.firstRide,
            lastRide: e.lastRide,
            travelTime: e.travelTime,
            elevation: e.elevation,
            duration: e.duration,
            season: e.season,
            exports: ExportAdapter.adapt(e.exports),
            operatingHoursEnabled: e.operatingHoursEnabled,
            sisMapNames: e.sisMaps?.map((s) => s.name) ?? [],
            sisMapInfo: e.sisMaps ?? [],
          })
        );
      });
    }

    return result;
  }
}
