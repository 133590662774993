import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { NewsService } from 'src/app/domain/news/news.service';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { TenantService } from 'src/app/domain/tenant/tenant.service';
import { UserService } from 'src/app/domain/user/user.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { MainMenuEntry } from 'src/app/main-menu/main-menu-entry.model';
import { MainMenuNewsComponent } from 'src/app/main-menu/main-menu-news/main-menu-news.component';
import { SelectDestinationModal } from 'src/app/main-menu/select-destination/select-destination.component';
import { SelectLanguageModal } from 'src/app/main-menu/select-language/select-language.component';
import { TenantEditModal } from 'src/app/main-menu/tenant-edit-modal/tenant-edit.component';

@Component({
  selector: 'sis-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent extends Unsubscriber implements OnInit {
  private bigScreenMode: boolean;
  private menuEntries: MainMenuEntry[] = [];

  @ViewChild(MainMenuNewsComponent) newsComponent: MainMenuNewsComponent;

  userActions = [
    {
      title: 'general.term.destination',
      icon: 'swap-horizontal-outline',
      dataTest: 'destination-select-button',
      action: () => this.onUserActionDestination(),
      subActions: [
        {
          tooltip: 'general.term.edit',
          icon: 'create-outline',
          dataTest: 'edit-tenant-button',
          action: () => this.onTenantEdit(false),
        },
        {
          tooltip: 'general.term.add',
          icon: 'add-circle-outline',
          dataTest: 'add-tenant-button',
          action: () => this.onTenantEdit(true),
        },
      ],
    },
    {
      title: 'general.term.language',
      icon: 'globe-outline',
      dataTest: 'language-select-button',
      action: () => this.onUserActionLanguage(),
    },
    {
      title: 'general.term.logout',
      icon: 'log-out-outline',
      dataTest: 'logout-button',
      action: () => this.onUserActionLogout(),
    },
  ];

  tenant: Tenant;
  hasTenants: boolean;
  pages: MainMenuEntry[] = [];
  userName: string;
  newsOnTop: boolean;
  userIsAdministrator: boolean;

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    public menuCtrl: MenuController,
    private destinationService: DestinationService,
    private tenantdataService: TenantService,
    private screenSizeService: ScreenSizeService,
    private userSettingsService: UserSettingsService,
    private newsService: NewsService
  ) {
    super();
  }

  ngOnInit() {
    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((tenant) => {
      this.tenant = tenant;
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.userService.userName$.pipe(takeUntil(this.onDestroy$)).subscribe((userName) => (this.userName = userName));

    combineLatest([
      this.destinationService.selectedTenantFeatures$,
      this.userSettingsService.userSettings$.pipe(distinctUntilChanged((x, y) => x.isAdministrator === y.isAdministrator)),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([features, userSettings]) => {
        this.userIsAdministrator = userSettings.isAdministrator;
        if (!features) {
          return;
        }

        const menuEntries = [
          {
            title: 'general.term.cockpit',
            iconName: 'sis-icon-menu-map',
            enabled: true,
            url: 'default/maps',
            order: 0,
            requiredFeatures: [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)],
          },
          {
            title: 'alarming.term.alarms',
            iconName: 'sis-icon-menu-notifications',
            enabled: true,
            url: 'default/alarmlist',
            order: 1,
            requiredFeatures: [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)],
          },
          {
            title: 'SisMedia',
            iconName: 'sis-icon-menu-sismedia',
            enabled: true,
            url: '',
            order: 2,
            requiredFeatures: [],
            submenu: [
              {
                title: 'general.term.mediacenter',
                iconName: 'sis-icon-menu-mediacenter',
                enabled: true,
                url: `default/${SisRoute.MEDIACENTER}`,
                order: 1,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.lifts',
                iconName: 'sis-icon-menu-ropeways',
                enabled: true,
                url: `default/sismedia/${SisRoute.Lift}`,
                order: 2,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_LIFT, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.slopes',
                iconName: 'sis-icon-menu-slope',
                enabled: true,
                url: `default/sismedia/${SisRoute.Slope}`,
                order: 3,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_SLOPE, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.crossCountry',
                iconName: 'sis-icon-menu-crosscountry',
                enabled: true,
                url: `default/sismedia/${SisRoute.CrossCountry}`,
                order: 4,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_CROSSCOUNTRY, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.sledging',
                iconName: 'sis-icon-menu-sledge',
                enabled: true,
                url: `default/sismedia/${SisRoute.Sledging}`,
                order: 5,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_SLEDGING, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.gastro',
                iconName: 'sis-icon-menu-gastro',
                enabled: true,
                url: `default/sismedia/${SisRoute.Gastro}`,
                order: 6,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_GASTRO, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.trails',
                iconName: 'sis-icon-menu-trail',
                enabled: true,
                url: `default/sismedia/${SisRoute.Trail}`,
                order: 7,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_TRAIL, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.bike',
                iconName: 'sis-icon-menu-bike',
                enabled: true,
                url: `default/sismedia/${SisRoute.Bike}`,
                order: 8,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_BIKE, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.pois',
                iconName: 'sis-icon-menu-poi',
                enabled: true,
                url: `default/sismedia/${SisRoute.Poi}`,
                order: 9,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_POI, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.parking',
                iconName: 'sis-icon-menu-parking',
                enabled: true,
                url: `default/${SisRoute.PARKING}`,
                order: 10,
                requiredFeatures: [new Feature(FeatureId.PARKING, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.lastSlopeControl',
                iconName: 'sis-icon-menu-lastslopecontrol',
                enabled: true,
                url: `default/${SisRoute.LASTSLOPECONTROL}`,
                order: 11,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_LASTSLOPECONTROL, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.slopesOperatingTimes',
                iconName: 'sis-icon-menu-slopes-operating-times',
                enabled: true,
                url: `default/${SisRoute.SLOPESOPERATINGTIMES}`,
                order: 12,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_SLOPESOPERATINGTIMES, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.infotext',
                iconName: 'sis-icon-menu-infotext',
                enabled: true,
                url: `default/${SisRoute.INFOTEXT}`,
                order: 13,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_INFOTEXT, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.meteoinfo',
                iconName: 'sis-icon-menu-meteoinfo',
                enabled: true,
                url: `default/${SisRoute.METEOINFO}`,
                order: 14,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_METEOINFO, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.stnet',
                iconName: 'sis-icon-menu-stnet',
                enabled: true,
                url: `default/${SisRoute.STNET}`,
                order: 15,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_STNET, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.timetable',
                iconName: 'sis-icon-menu-timetable',
                enabled: true,
                url: `default/${SisRoute.TIMETABLE}`,
                order: 16,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.ledticker',
                iconName: 'sis-icon-menu-ledticker',
                enabled: true,
                url: `default/${SisRoute.LEDTICKER}`,
                order: 17,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_LEDTICKER, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.emaildelivery',
                iconName: 'sis-icon-menu-emaildelivery',
                enabled: true,
                url: `default/${SisRoute.EMAILDELIVERY}`,
                order: 18,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_EMAILDELIVERY, FeatureAccessLevel.READ)],
              },
              {
                title: 'general.term.sismediaSetting',
                iconName: 'sis-icon-menu-sismedia-setting',
                enabled: true,
                url: `default/${SisRoute.SISMEDIASETTING}`,
                order: 17,
                requiredFeatures: [new Feature(FeatureId.SISMEDIA_ASSET_EDIT, FeatureAccessLevel.READ)],
              },
            ],
          },
          {
            title: 'general.term.sisitservices',
            iconName: 'sis-icon-menu-itservices',
            enabled: true,
            url: 'default/sisitservices',
            order: 3,
            requiredFeatures: [new Feature(FeatureId.SISITSERVICES, FeatureAccessLevel.READ)],
          },
          {
            title: 'general.term.global',
            iconName: 'sis-icon-menu-map',
            enabled: true,
            url: 'default/global',
            order: 4,
            requiredFeatures: [new Feature(FeatureId.GLOBALVIEW, FeatureAccessLevel.READ)],
          },
          {
            title: 'general.term.breakdowninfo',
            iconName: 'sis-icon-menu-breakdowninfo',
            enabled: true,
            url: 'default/breakdowninfo',
            order: 5,
            requiredFeatures: [new Feature(FeatureId.BREAKDOWNINFO, FeatureAccessLevel.READ)],
          },
          {
            title: 'general.term.operatinginfo',
            iconName: 'sis-icon-menu-operating-info',
            enabled: true,
            url: 'default/operatinginfo',
            order: 6,
            requiredFeatures: [new Feature(FeatureId.OPERATINGINFO, FeatureAccessLevel.READ)],
          },
          {
            title: 'general.term.weblinkcollection',
            iconName: 'sis-icon-menu-weblink-collection',
            enabled: true,
            url: 'default/weblinkcollection',
            order: 7,
            requiredFeatures: [new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.READ)],
          },
          {
            title: 'general.term.usermanagement',
            iconName: 'sis-icon-menu-user-management',
            enabled: this.bigScreenMode,
            url: 'default/usermanagement',
            order: 9,
            requiredFeatures: [new Feature(FeatureId.USERMANAGEMENT, FeatureAccessLevel.READ)],
          },
        ];

        if (userSettings.isAdministrator) {
          menuEntries.push({
            title: 'general.term.featuremanagement',
            iconName: 'sis-icon-menu-feature-management',
            enabled: true,
            url: 'default/featuremanagement',
            order: 8,
            requiredFeatures: [],
          });
          menuEntries.push({
            title: 'Sisag Admin',
            iconName: 'sis-icon-menu-sisag-admin',
            enabled: true,
            url: 'default/admin',
            order: 10,
            requiredFeatures: [],
          });
        }

        this.menuEntries = menuEntries;

        this.setMenuEntriesVisibleState(this.menuEntries, features);

        this.pages = this.menuEntries.sort((a, b) => a.order - b.order);
      });

    this.tenantdataService.tenants$.pipe(take(1)).subscribe((tenants) => {
      this.hasTenants = tenants.length > 1;
      if (!this.hasTenants) {
        this.userActions.splice(
          this.userActions.findIndex((u) => u.title === 'general.term.destination'),
          1
        );
      }
    });
  }

  onUserActionDestination() {
    this.modalCtrl
      .create({
        component: SelectDestinationModal,
        cssClass: 'sis-main-menu-destination-selection-modal',
      })
      .then((modal) => modal.present());
  }

  onUserActionLanguage() {
    this.modalCtrl
      .create({
        component: SelectLanguageModal,
        cssClass: 'sis-main-menu-selection-modal',
      })
      .then((modal) => modal.present());
  }

  onTenantEdit(createNew: boolean) {
    this.modalCtrl
      .create({
        component: TenantEditModal,
        cssClass: 'sis-tenant-edit-modal',
        componentProps: {
          title: createNew ? 'Tenant erstellen' : 'Tenant editieren',
          tenant: createNew ? null : this.tenant,
        },
      })
      .then((modal) => modal.present());
  }

  onUserActionLogout() {
    this.userService.logout();
  }

  onUserActionPreferences() {}

  onMenuItemClick(): void {
    this.newsComponent.open = false;
  }

  async onMenuOpen(): Promise<void> {
    this.newsComponent.open = false;
    this.newsOnTop = await firstValueFrom(this.newsService.hasUnreadNews$);
  }

  private setMenuEntriesVisibleState(menuEntries: MainMenuEntry[], allowedFeatures: Feature[]): void {
    menuEntries.forEach((menuEntry) => {
      if (menuEntry.submenu?.length > 0) {
        this.setMenuEntriesVisibleState(menuEntry.submenu, allowedFeatures);

        menuEntry.visible = menuEntry.submenu.some((submenu) => submenu.visible);
      } else {
        menuEntry.visible = menuEntry.requiredFeatures.every((r) => allowedFeatures.some((a) => a.hasMinimumRequirementFor(r)));
      }
    });
  }
}
