<!-- Big Screen -->
<ion-radio-group *ngIf="this.bigScreenMode" (ionChange)="radioGroupChange()" [(ngModel)]="editItem.activeTime">
  <ion-item>
    <ion-row class="sis-global-row-50 sis-zone-row">
      <ion-col>
        <ion-label class="sis-label">{{ 'lastSlopeControl.term.zone' | translate }} {{ editItem.zone }}</ion-label>
      </ion-col>
      <div *ngFor="let time of editItem.times; index as i" style="margin-left: 80px">
        <ion-col>
          <ion-radio
            class="sis-radio-button"
            [value]="i"
            [disabled]="!this.writePermission"
            data-test="lastslopecontrol-zone-radio-btn"
          ></ion-radio>
        </ion-col>
        <ion-col class="sis-global-input sis-input-time-col-desktop">
          <input
            class="sis-global-input"
            [value]="editItem.times[i]"
            type="time"
            (blur)="timeChanged($event, i)"
            [disabled]="!this.writePermission"
            data-test="lastslopecontrol-zone-time-input"
            required
          />
        </ion-col>
      </div>
      <ion-col size="2">
        <a
          style="margin-left: 50px"
          color="secondary"
          class="sis-asset-override-clickable"
          (click)="saveEntry()"
          *ngIf="this.writePermission && this.hasChanged"
          matTooltip="{{ 'general.term.save' | translate }}"
          data-test="sismedia-asset-override-item-save"
        >
          <ion-label *ngIf="!this.isSaving">
            <ion-icon class="sis-ion-icon-asset" name="checkmark-outline"></ion-icon>
          </ion-label>
        </a>

        <a
          color="secondary"
          class="sis-asset-override-clickable"
          (click)="reset()"
          *ngIf="this.writePermission && this.hasChanged"
          matTooltip="{{ 'general.term.reset' | translate }}"
          data-test="sismedia-asset-override-item-reset"
        >
          <ion-label *ngIf="!this.isSaving">
            <ion-icon class="sis-ion-icon-asset" name="close-outline"></ion-icon>
          </ion-label>
        </a>
      </ion-col>
    </ion-row>
  </ion-item>
</ion-radio-group>

<!-- Small Screen -->
<ion-row *ngIf="!this.bigScreenMode" class="sis-global-row" style="padding: 5px; border-bottom: 1px solid gray">
  <ion-col size="9" class="ion-no-padding">
    <ion-radio-group
      (ionChange)="radioGroupChange()"
      [(ngModel)]="editItem.activeTime"
      style="width: 100%"
      class="ion-no-padding"
    >
      <ion-grid *ngFor="let time of editItem.times; index as i" class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col class="sis-top-bottom-padding" style="text-align: left; padding-left: 20px">
            <span *ngIf="i == 0" class="sis-label"
              >{{ 'lastSlopeControl.term.zone' | translate }} {{ editItem.zone }}</span
            >
          </ion-col>
          <ion-col size="1" class="sis-top-bottom-padding">
            <ion-radio class="sis-radio-button-small" [value]="i" [disabled]="!this.writePermission"></ion-radio>
          </ion-col>
          <ion-col size="4" class="sis-top-bottom-padding">
            <ion-input
              class="sis-global-input"
              [value]="editItem.times[i]"
              [disabled]="!this.writePermission"
              (ionChange)="timeChanged($event, i)"
              type="time"
            ></ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-radio-group>
  </ion-col>
  <ion-col class="ion-no-padding">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding">
          <a
            color="secondary"
            class="sis-asset-override-clickable"
            (click)="saveEntry()"
            *ngIf="this.writePermission && this.hasChanged"
            matTooltip="{{ 'general.term.save' | translate }}"
            data-test="sismedia-asset-override-item-save"
          >
            <ion-label *ngIf="!this.isSaving">
              <ion-icon class="sis-ion-icon-asset" name="checkmark-outline"></ion-icon>
            </ion-label>
          </a>
        </ion-col>
        <ion-col class="ion-no-padding">
          <a
            color="secondary"
            class="sis-asset-override-clickable"
            (click)="reset()"
            *ngIf="this.writePermission && this.hasChanged"
            matTooltip="{{ 'general.term.reset' | translate }}"
            data-test="sismedia-asset-override-item-reset"
          >
            <ion-label *ngIf="!this.isSaving">
              <ion-icon class="sis-ion-icon-asset" name="close-outline"></ion-icon>
            </ion-label>
          </a>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
</ion-row>
