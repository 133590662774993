import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { AccessLevelSelector } from 'src/app/user-management/access-level-selector/access-level-selector.component';
import { UserManagementPage } from 'src/app/user-management/user-management.page';
import { UserManagementEditModalComponent } from 'src/app/user-management/user-management-edit-modal/user-management-edit-modal.component';
import { UserManagementItemComponent } from 'src/app/user-management/user-management-item/user-management-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    MatTableModule,
    RouterModule.forChild([
      {
        path: '',
        component: UserManagementPage,
      },
    ]),
    TranslateModule,
    ScrollingModule,
  ],
  declarations: [
    UserManagementPage,
    UserManagementItemComponent,
    AccessLevelSelector,
    UserManagementEditModalComponent,
  ],
})
export class UserManagementPageModule {}
