<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title>{{ this.title | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid class="sis-no-padding sis-page-grid">
    <form *ngIf="this.formGroup" [formGroup]="formGroup">
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">SisId</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="sisId"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.sisId?.errors }"
            maxlength="6"
            data-test="sis-tenant-edit-sisId"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">Alias</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="alias"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.alias?.errors }"
            maxlength="50"
            data-test="sis-tenant-edit-alias"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">Fullname</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="fullname"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.fullname?.errors }"
            maxlength="100"
            data-test="sis-tenant-edit-fullname"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">GatewayId</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="gatewayId"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.gatewayId?.errors }"
            maxlength="10"
            data-test="sis-tenant-edit-gatewayId"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">PanoMapFilename</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="panoMapFilename"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.panoMapFilename?.errors }"
            maxlength="50"
            data-test="sis-tenant-edit-panoMapFilename"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">LogoFilename</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="logoFilename"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.logoFilename?.errors }"
            maxlength="50"
            data-test="sis-tenant-edit-logoFilename"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">WeatherTrendLink</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="weatherTrendLink"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.weatherTrendLink?.errors }"
            maxlength="200"
            data-test="sis-tenant-edit-weatherTrendLink"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">PositionGeoMap</ion-col>
        <ion-col class="sis-no-padding" style="max-width: 130px">
          <ion-input
            style="max-width: 126px"
            formControlName="lat"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.lat?.errors }"
            maxlength="23"
            data-test="sis-tenant-edit-lat"
          ></ion-input> </ion-col
        ><ion-col style="max-width: 130px">
          <ion-input
            style="max-width: 126px"
            formControlName="lon"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.lon?.errors }"
            maxlength="23"
            data-test="sis-tenant-edit-lon"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">MaxUsers</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="maxUsers"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.maxUsers?.errors }"
            data-test="sis-tenant-edit-maxUsers"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-text-row sis-no-padding">
        <ion-col size="auto" class="sis-property-name sis-modal-input-label">TimeZoneId</ion-col>
        <ion-col class="sis-no-padding">
          <ion-input
            style="max-width: 255px"
            formControlName="timeZoneId"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls?.timeZoneId?.errors }"
            maxlength="35"
            data-test="sis-tenant-edit-timeZoneId"
          ></ion-input>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="this.isSaving || this.formGroup.invalid || !this.formGroup.dirty"
      [showSpinner]="this.isSaving"
      width="120px"
      data-test="sis-tenant-edit-tenant-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.closeModal()"
      [showSpinner]="this.isSaving"
      width="120px"
      data-test="sis-tenant-edit-tenant-edit-cancel-btn"
    ></sis-general-button>
  </div>
</ion-footer>
