<ion-header data-test="sis-ropeway-settings-modal">
  <ion-toolbar>
    <ion-title>
      {{ 'general.term.ropewaySettings' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.close()"><ion-icon class="sis-close-button" name="close"></ion-icon></ion-button>
  </ion-toolbar>
</ion-header>

<!-- desktop -->
<ion-content class="ion-no-padding">
  <ng-container *ngIf="this.bigScreenMode$ | async">
    <ion-grid class="sis-global-no-scrollbar sis-grid">
      <form [formGroup]="this.formGroup">
        <ion-row>
          <ion-col class="sis-property">{{ 'general.term.fullname' | translate }}</ion-col>
          <ion-col>
            <ion-input
              formControlName="fullname"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.fullname?.errors }"
              data-test="sis-ropway-settings-modal-fullname-input"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-property">{{ 'general.term.alias' | translate }}</ion-col>
          <ion-col>
            <ion-input
              formControlName="alias"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.alias?.errors }"
              data-test="sis-ropway-settings-modal-alias-input"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-property">{{ 'general.term.constructedBy' | translate }}</ion-col>
          <ion-col>
            <ion-input
              formControlName="constructedBy"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.constructedBy?.errors }"
              data-test="sis-ropway-settings-modal-constructedBy-input"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-property">{{ 'general.term.controllerType' | translate }}</ion-col>
          <ion-col>
            <ion-input
              formControlName="controllerType"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.controllerType?.errors }"
              data-test="sis-ropway-settings-modal-controllerType-input"
            />
          </ion-col>
        </ion-row>
        <ng-container *ngIf="!this.ropeway.isPendelbahn">
          <ion-row>
            <ion-col class="sis-property">{{ 'general.term.transportQuantity' | translate }}</ion-col>
            <ion-col>
              <ion-input
                formControlName="maxTransportQuantityPerHour"
                class="sis-input"
                inputmode="numeric"
                type="number"
                min="0"
                [ngClass]="{ 'sis-input-error': this.formGroup.controls?.maxTransportQuantityPerHour?.errors }"
                data-test="sis-ropway-settings-modal-transportQuantity-input"
              />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="sis-property">{{ 'general.term.operatingTime' | translate }}</ion-col>
            <ion-col size="auto">
              <ion-input
                formControlName="operatingTimeFrom"
                class="sis-time-input"
                type="time"
                [ngClass]="{ 'sis-input-error': this.formGroup.errors?.operatingTimeFrom }"
                data-test="sis-ropway-settings-modal-operatingTimeFrom-input"
            /></ion-col>
            <ion-col size="auto">{{ 'general.term.toTimeOnly' | translate }}</ion-col>
            <ion-col
              ><ion-input
                formControlName="operatingTimeUntil"
                class="sis-time-input"
                type="time"
                [ngClass]="{ 'sis-input-error': this.formGroup.errors?.operatingTimeUntil }"
                data-test="sis-ropway-settings-modal-operatingTimeUntil-input"
              />
            </ion-col>
          </ion-row>
        </ng-container>
        <ion-row *ngIf="this.ropeway.availabilityIsAvailable && this.hasAvailabilityFeatureReadPermission$ | async">
          <ion-col class="sis-property">{{ 'general.term.availability' | translate }}</ion-col>
          <ion-col>
            <ion-checkbox formControlName="availability" class="sis-checkbox" data-test="sis-ropway-settings-modal-availability-checkbox" />
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </ng-container>

  <!-- mobile -->
  <ng-container *ngIf="!(this.bigScreenMode$ | async)">
    <ion-grid class="sis-global-no-scrollbar sis-grid">
      <form [formGroup]="this.formGroup">
        <ion-row class="sis-property-mobile">
          <ion-col>{{ 'general.term.fullname' | translate }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input formControlName="fullname" class="sis-input" [ngClass]="{ 'sis-input-error': this.formGroup.controls?.fullname?.errors }" />
          </ion-col>
        </ion-row>
        <ion-row class="sis-property-mobile">
          <ion-col>{{ 'general.term.alias' | translate }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input formControlName="alias" class="sis-input" [ngClass]="{ 'sis-input-error': this.formGroup.controls?.alias?.errors }" />
          </ion-col>
        </ion-row>
        <ion-row class="sis-property-mobile">
          <ion-col>{{ 'general.term.constructedBy' | translate }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              formControlName="constructedBy"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.constructedBy?.errors }"
            />
          </ion-col>
        </ion-row>
        <ion-row class="sis-property-mobile">
          <ion-col>{{ 'general.term.controllerType' | translate }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              formControlName="controllerType"
              class="sis-input"
              [ngClass]="{ 'sis-input-error': this.formGroup.controls?.controllerType?.errors }"
            />
          </ion-col>
        </ion-row>
        <ng-container *ngIf="!this.ropeway.isPendelbahn">
          <ion-row class="sis-property-mobile">
            <ion-col>{{ 'general.term.transportQuantity' | translate }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-input
                formControlName="maxTransportQuantityPerHour"
                class="sis-input"
                inputmode="numeric"
                type="number"
                min="0"
                [ngClass]="{ 'sis-input-error': this.formGroup.controls?.maxTransportQuantityPerHour?.errors }"
              />
            </ion-col>
          </ion-row>
          <ion-row class="sis-property-mobile">
            <ion-col>{{ 'general.term.operatingTime' | translate }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <ion-input
                formControlName="operatingTimeFrom"
                class="sis-time-input"
                type="time"
                [ngClass]="{ 'sis-input-error': this.formGroup.errors?.operatingTimeFrom }"
            /></ion-col>
            <ion-col size="auto">{{ 'general.term.toTimeOnly' | translate }}</ion-col>
            <ion-col>
              <ion-input
                formControlName="operatingTimeUntil"
                class="sis-time-input"
                type="time"
                [ngClass]="{ 'sis-input-error': this.formGroup.errors?.operatingTimeUntil }"
              />
            </ion-col>
          </ion-row>
        </ng-container>
        <ion-row
          *ngIf="this.ropeway.availabilityIsAvailable && this.hasAvailabilityFeatureReadPermission$ | async"
          class="sis-property-mobile"
          style="height: 49px"
        >
          <ion-col>{{ 'general.term.availability' | translate }}</ion-col>
          <ion-col>
            <ion-checkbox formControlName="availability" class="sis-checkbox" />
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </ng-container>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      [disabled]="false"
      [showSpinner]="this.saving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : 'calc(50% - 3px)'"
      [disabled]="this.saving || !this.edited || this.formGroup.invalid"
      (sisClick)="this.save()"
      class="sis-global-button-padding-right"
      width="120px"
      data-test="sis-save-button"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [btnAlign]="'Right'"
      (sisClick)="this.close()"
      [showSpinner]="this.saving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : 'calc(50% - 3px)'"
      data-test="sis-save-cancel"
    ></sis-general-button>
  </div>
</ion-footer>
