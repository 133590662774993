<ion-header>
  <ion-toolbar>
    <ion-title data-test="sis-infobanner-editor-title">
      {{ this.screenLocation }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid" *ngIf="this.editorType != undefined">
    <form [formGroup]="this.infoBannerTextSettingsForm" *ngIf="this.infoBannerTextSettingsForm">
      <!-- BannerType WYSIWYG-->
      <ng-container *ngIf="this.editorType === 0">
        <ion-row class="sis-infobanner-row">
          <ion-col size="1" class="sis-infobanner-col" style="min-width: 150px">
            <span class="sis-infobanner-label">{{ 'infobanner.term.bannerName' | translate }}:</span>
          </ion-col>
          <ion-col size="4" class="sis-infobanner-col">
            <ion-input
              type="text"
              class="sis-infobanner-input"
              formControlName="name"
              maxlength="50"
              size="50"
              style="max-width: 450px"
              data-test="sis-infobanner-name-input"
            ></ion-input>
          </ion-col>
          <ion-col class="sis-infobanner-col" style="margin-left: 10px">
            <ion-checkbox
              *ngIf="this.editor"
              color="secondary"
              class="sis-checkbox-input"
              formControlName="backgroundColorChecked"
              [checked]="this.colorPicker !== null"
              data-test="sis-infobanner-background-checkbox"
            ></ion-checkbox>
            <span class="sis-infobanner-label">{{ 'infobanner.term.bannerBackgroundColor' | translate }}:</span>
            <input
              *ngIf="this.editor && infoBannerTextSettingsForm.controls.backgroundColorChecked.value"
              [value]="editedInfoBanner.background ?? '#ffffff'"
              type="color"
              (input)="onColorChange($event)"
              style="cursor: pointer"
              data-test="sis-infobanner-background-input"
              class="sis-infobanner-input"
            />
          </ion-col>
        </ion-row>
        <ion-row class="sis-infobanner-row">
          <ion-col class="sis-infobanner-col" style="max-width: 150px">
            <ion-checkbox
              color="secondary"
              class="sis-checkbox-input"
              formControlName="fullscreenMode"
              [checked]="this.fullscreenMode"
              (ionChange)="this.checkboxValueChangedScreenMode()"
            ></ion-checkbox>
            <span class="sis-infobanner-label">{{ 'infobanner.term.fullscreen' | translate }}</span>
          </ion-col>
          <ng-container *ngIf="!this.fullscreenMode">
            <ion-col class="sis-infobanner-col" style="max-width: 500px">
              <span class="sis-infobanner-label">{{ 'infobanner.term.bannerPosition' | translate }}:</span>
              <ion-radio-group formControlName="position" class="sis-infobanner-col">
                <ion-radio [value]="2" mode="md" data-test="sis-infobanner-position-radio"></ion-radio>
                <span class="sis-infobanner-label">{{ 'infobanner.term.top' | translate }}</span>
                <ion-radio [value]="3" data-test="sis-infobanner-position-radio" style="margin-left: 10px"></ion-radio>
                <span class="sis-infobanner-label">{{ 'infobanner.term.bottom' | translate }}</span>
              </ion-radio-group>
            </ion-col>
            <ion-col class="sis-infobanner-col" style="max-width: 370px">
              <span class="sis-infobanner-label">{{ 'infobanner.term.bannerHeight' | translate }}:</span>
              <ion-input
                type="text"
                class="sis-infobanner-input"
                formControlName="height"
                maxlength="10"
                size="10"
                placeholder="100px"
                data-test="sis-infobanner-height-input"
                style="max-width: 80px"
              ></ion-input>
            </ion-col>
          </ng-container>
        </ion-row>
        <ion-row>
          <ckeditor
            class="ckeditor-infobanner"
            editorUrl="/assets/ckeditor/ckeditor.js"
            [data]="ckEditorData"
            (ready)="this.setEditor($event)"
            (change)="this.infobannerChangedEvent()"
            [config]="{ customConfig: '/assets/ckeditor/config-infobanner.js' }"
            data-test="sis-infobanner-content"
          ></ckeditor>
        </ion-row>
      </ng-container>

      <!-- BannerType PlainText-->
      <ng-container *ngIf="this.editorType === 1">
        <ion-row class="sis-infobanner-row">
          <ion-col size="1" class="sis-infobanner-col" style="min-width: 150px">
            <span class="sis-infobanner-label">{{ 'infobanner.term.bannerName' | translate }}:</span>
          </ion-col>
          <ion-col class="sis-infobanner-col">
            <ion-input
              type="text"
              class="sis-infobanner-input"
              formControlName="name"
              maxlength="50"
              size="50"
              data-test="sis-infobanner-name-input"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row style="margin-bottom: 10px">
          <ion-col size="1" class="sis-infobanner-col" style="min-width: 150px" *ngIf="this.editorType === 1">
            <span class="sis-infobanner-label"> {{ 'general.term.category' | translate }}: </span>
          </ion-col>
          <ion-col class="sis-infobanner-col">
            <ion-select
              class="sis-infobanner-input"
              formControlName="textCategory"
              interface="popover"
              [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
              style="width: 250px"
              data-test="sis-infobanner-textcategory-select"
            >
              <ion-select-option
                *ngFor="let item of this.textCategories"
                [value]="item.id"
                data-test="sis-infobanner-textcategory-select-option"
                >{{ item.translateKey | translate }}</ion-select-option
              >
            </ion-select>
          </ion-col>
        </ion-row>

        <ion-row *ngFor="let control of this.languageTextControls">
          <ion-col size="1" class="sis-infobanner-col" style="min-width: 150px">
            <span class="sis-infobanner-label"> {{ control.translateKey | translate }}: </span>
          </ion-col>
          <ion-col class="sis-infobanner-col sis-textarea-wrapper">
            <ion-textarea
              rows="3"
              class="sis-infobanner-input"
              [formControlName]="control.formControlName"
              style="max-height: 80px"
              data-test="sis-infobanner-languagetext-input"
            ></ion-textarea>

            <div class="sis-textarea-counter">
              {{ control.textCounter }}
            </div>
          </ion-col>
        </ion-row>
      </ng-container>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      [disabled]="this.btnIsDisabled"
      (sisClick)="this.save()"
      data-test="sis-infobanner-text-modal-save-btn"
      class="sis-global-button-padding-right"
    >
    </sis-general-button>
    <sis-general-button [text]="'general.term.cancel'" [btnType]="'Secondary'" (sisClick)="this.close()">
    </sis-general-button>
  </div>
</ion-footer>
