<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ 'parking.term.edit' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <form *ngIf="this.formGroup" [formGroup]="this.formGroup">
    <!-- Big Screen -->
    <ion-grid class="sis-modal-grid" *ngIf="this.bigScreenMode$ | async">
      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="3" class="sis-modal-input-label">
          {{ 'parking.term.nameDe' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.name?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="name"
            data-test="parking-edit-nameDe-input"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="3" class="sis-modal-input-label">
          {{ 'parking.term.nameEn' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.nameEn?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="nameEn"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="3" class="sis-modal-input-label">
          {{ 'parking.term.label' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.label?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="label"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-70">
        <ion-col size="3" class="sis-modal-input-label">
          {{ 'parking.term.info' | translate }}
        </ion-col>
        <ion-col>
          <ion-textarea
            type="text"
            class="sis-global-textarea-no-scrollbar sis-modal-input sis-modal-textarea-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.info?.errors }"
            rows="3"
            inputmode="text"
            maxlength="300"
            formControlName="info"
          ></ion-textarea>
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="3" class="sis-modal-input-label">
          {{ 'parking.term.custom' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.custom?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="custom"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35" style="color: grey; max-height: 30px; padding-top: 10px">
        <ion-col size="3" class="sis-modal-input-label">ID</ion-col>
        <ion-col class="sis-modal-input-label">
          {{ this.parking.id }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-modal-row-35" style="color: grey; max-height: 30px">
        <ion-col size="3" class="sis-modal-input-label">Guid</ion-col>
        <ion-col class="sis-modal-input-label">
          <span (click)="this.copyToClipboard(this.parking.guid)" style="cursor: copy">
            {{ this.parking.guid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Small Screen -->
    <ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-modal-grid">
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col class="sis-parking-mobile-label">
          {{ 'parking.term.nameDe' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.name?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="name"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-parking-mobile-row">
        <ion-col class="sis-parking-mobile-label">
          {{ 'parking.term.nameEn' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.nameEn?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="nameEn"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col class="sis-parking-mobile-label">
          {{ 'parking.term.label' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.label?.errors }"
            inputmode="text"
            maxlength="100"
            formControlName="label"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col class="sis-parking-mobile-label">
          {{ 'parking.term.info' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col>
          <ion-textarea
            type="text"
            class="sis-modal-input sis-modal-textarea-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.custom?.errors }"
            inputmode="text"
            maxlength="300"
            rows="3"
            formControlName="info"
          ></ion-textarea>
        </ion-col>
      </ion-row>

      <ion-row class="sis-parking-mobile-row">
        <ion-col class="sis-parking-mobile-label">
          {{ 'parking.term.custom' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-parking-mobile-row">
        <ion-col>
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.info?.errors }"
            inputmode="text"
            maxlength="300"
            formControlName="custom"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-parking-mobile-row" style="color: grey">
        <ion-col size="3">ID</ion-col>
        <ion-col>
          {{ this.parking.id }}
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-parking-mobile-row" style="color: grey">
        <ion-col size="3">Guid</ion-col>
        <ion-col>
          <span (click)="this.copyToClipboard(this.parking.guid)" style="cursor: copy">
            {{ this.parking.guid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.hasUnsavedChanges || this.isSaving || this.formGroup.invalid"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="parking-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="parking-edit-cancel-btn"
    ></sis-general-button>
  </div>
</ion-footer>
