import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, scan, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import {
  TimetableImportUpdatedEvent,
  TimetableIntervalDayUpdatedEvent,
  TimetableJourneyUpdatedEvent,
  TimetableRealTimeDataUpdatedEvent,
  TimetableSeasonUpdatedEvent,
} from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { TimetableIntervalDay } from 'src/app/timetable/domain/timetable-interval-day.model';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { TimetableTrackAdapter } from 'src/app/timetable/domain/timetable-track.adapter';
import { TimetableTrack } from 'src/app/timetable/domain/timetable-track.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimetableService {
  private readonly baseRequestUrl: string = '/timetable';
  private readonly intervalDayBasePostUrl: string = '/timetable/journey';
  private readonly seasonBasePostUrl: string = '/timetable/season';
  private readonly seasonImportBaseUrl: string = '/timetable/import';
  private readonly intervalDayPostBaseUrl: string = '/timetable/intervalday';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.READ);
  private readonly isImporting$ = new Subject<{ trackGuid: string; isImporting: boolean }>();

  private readonly timetableTracksRequest$: Observable<TimetableTrack[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
    map((items) => TimetableTrackAdapter.adapt(items)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly timetableImportUpdate$: Observable<TimetableTrack[]> = this.eventBus.observe(TimetableImportUpdatedEvent).pipe(
    switchMap((event) =>
      this.http
        .get(`${environment.baseUrlApi}${this.baseRequestUrl}`)
        .pipe(map<object, [TimetableImportUpdatedEvent, object]>((items) => [event, items]))
    ),
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([[event, items], userSettings]) => {
      const tracks = TimetableTrackAdapter.adapt(items);

      if (event.changedBy === userSettings.userGuid) {
        this.displayUserMessage(event.updateSuccessful, false, true);
      }
      this.isImporting$.next({ trackGuid: event.trackGuid, isImporting: false });

      return tracks;
    })
  );

  private readonly timetableTracks$: Observable<TimetableTrack[]> = merge(this.timetableTracksRequest$, this.timetableImportUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly timetableJourneyUpdate$: Observable<TimetableTrack[]> = this.eventBus.observe(TimetableJourneyUpdatedEvent).pipe(
    withLatestFrom(this.timetableTracks$),
    filter(([event, tracks]) => tracks.some((d) => d.tenantGuid === event.tenantGuid)),
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([[event, tracks], userSettings]) => {
      if (event.updateSuccessful) {
        const updatedTrack = tracks.find((d) => d.seasons.some((s) => s.guid === event.timetableJourney.seasonGuid));
        const updatedSeason = updatedTrack?.seasons.find((s) => s.guid === event.timetableJourney.seasonGuid);
        if (updatedSeason) {
          if (event.deleted) {
            const deletedJourney = updatedSeason.journeys.find((j) => j.guid === event.timetableJourney.guid);
            updatedSeason.journeys.splice(updatedSeason.journeys.indexOf(deletedJourney), 1);
          } else {
            const updatedJourney = updatedSeason.journeys.find((j) => j.guid === event.timetableJourney.guid);
            if (!updatedJourney) {
              updatedSeason.journeys.push(event.timetableJourney);
            } else {
              updatedJourney.vehicleNumber = event.timetableJourney.vehicleNumber;
              updatedJourney.items = event.timetableJourney.items;
            }
          }
        }
      }
      if (event.changedBy === userSettings.userGuid) {
        this.displayUserMessage(event.updateSuccessful, event.deleted);
      }

      return tracks;
    })
  );

  private readonly timetableWithJourneyUpdates$: Observable<TimetableTrack[]> = merge(this.timetableTracks$, this.timetableJourneyUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly timetableSeasonUpdate$: Observable<TimetableTrack[]> = this.eventBus.observe(TimetableSeasonUpdatedEvent).pipe(
    withLatestFrom(this.timetableWithJourneyUpdates$),
    filter(([event, tracks]) => tracks.some((d) => d.tenantGuid === event.tenantGuid)),
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([[event, tracks], userSettings]) => {
      const updatedTrack = tracks.find((d) => d.seasons.some((s) => s.guid === event.timetableSeason.guid));
      if (event.updateSuccessful) {
        const updatedSeason = updatedTrack?.seasons.find((s) => s.guid === event.timetableSeason.guid);
        if (updatedSeason) {
          Object.assign(updatedSeason, event.timetableSeason);
        }
      }
      if (event.changedBy === userSettings.userGuid) {
        this.displayUserMessage(event.updateSuccessful, false, event.timetableSeason.isImportTimetable);
      }
      if (event.timetableSeason.isImportTimetable) {
        this.isImporting$.next({ trackGuid: updatedTrack.guid, isImporting: false });
      }

      return tracks;
    })
  );

  private readonly timetableWithJourneyAndSeasonUpdates$: Observable<TimetableTrack[]> = merge(
    this.timetableWithJourneyUpdates$,
    this.timetableSeasonUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly timetableRealTimeUpdate$: Observable<TimetableTrack[]> = this.eventBus.observe(TimetableRealTimeDataUpdatedEvent).pipe(
    withLatestFrom(this.timetableWithJourneyAndSeasonUpdates$),
    filter(([event, tracks]) => tracks.some((track) => track.guid === event.trackGuid)),
    map(([event, tracks]) => {
      const updatedTrack = tracks.find((d) => d.guid === event.trackGuid);
      updatedTrack.seasons.find((s) => s.guid === event.seasonGuid).lastSuccessfulRealTimeImport = event.lastSuccessfulRealTimeImport;

      return tracks;
    })
  );

  private readonly timetableWithJourneyAndSeasonAndRealTimeUpdates$: Observable<TimetableTrack[]> = merge(
    this.timetableWithJourneyAndSeasonUpdates$,
    this.timetableRealTimeUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly timetableIntervalDayUpdate$: Observable<TimetableTrack[]> = this.eventBus.observe(TimetableIntervalDayUpdatedEvent).pipe(
    withLatestFrom(this.timetableWithJourneyAndSeasonAndRealTimeUpdates$),
    filter(([event, tracks]) => tracks.some((track) => track.tenantGuid === event.tenantGuid)),
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([[event, tracks], userSettings]) => {
      if (event.updateSuccessful) {
        tracks.some((track) => {
          const updatedInterval = track.intervals.find((interval) => interval.guid === event.timetableIntervalDay.intervalGuid);
          if (updatedInterval) {
            const updatedIntervalDay = updatedInterval.intervalDays.find((day) => day.guid === event.timetableIntervalDay.guid);
            if (updatedIntervalDay) {
              Object.assign(updatedIntervalDay, event.timetableIntervalDay);
            }

            return true;
          }

          return false;
        });
      }

      if (event.changedBy === userSettings.userGuid) {
        this.displayUserMessage(event.updateSuccessful, false);
      }

      return tracks;
    })
  );

  readonly timetables$ = merge(this.timetableWithJourneyAndSeasonAndRealTimeUpdates$, this.timetableIntervalDayUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly isImportingMap$ = this.isImporting$.pipe(
    scan((acc, value) => {
      if (value != null) {
        acc.set(value.trackGuid, value.isImporting);
      }
      return acc;
    }, new Map<string, boolean>()),
    startWith(new Map<string, boolean>()),
    shareReplay(1)
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService
  ) {}

  async updateJourney(updatedTimetableJourneyData: TimetableJourney): Promise<void> {
    await firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.intervalDayBasePostUrl}`, updatedTimetableJourneyData).pipe(
        catchError((error: HttpErrorResponse) => {
          this.displayUserMessage(false, false);
          throw error;
        })
      )
    );
  }

  async updateSeason(updatedSeason: TimetableSeason): Promise<void> {
    await firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.seasonBasePostUrl}`, updatedSeason).pipe(
        catchError((error: HttpErrorResponse) => {
          this.displayUserMessage(false, false);
          throw error;
        })
      )
    );
  }

  async deleteJourney(journeyGuid: string): Promise<void> {
    await firstValueFrom(
      this.http.delete(`${environment.baseUrlApi}${this.intervalDayBasePostUrl}/${journeyGuid}`).pipe(
        catchError((error: HttpErrorResponse) => {
          this.displayUserMessage(false, true);
          throw error;
        })
      )
    );
  }

  async updateIntervalDay(updatedIntervalDay: TimetableIntervalDay): Promise<void> {
    await firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.intervalDayPostBaseUrl}`, updatedIntervalDay).pipe(
        catchError((error: HttpErrorResponse) => {
          this.displayUserMessage(false, false);
          throw error;
        })
      )
    );
  }

  async requestTimetableImport(trackGuid: string): Promise<void> {
    this.isImporting$.next({ trackGuid, isImporting: true });

    const success = await firstValueFrom(
      this.http.get(`${environment.baseUrlApi}${this.seasonImportBaseUrl}/${trackGuid}`).pipe(
        map(() => true),
        catchError(() => {
          const userMessage = new UserMessage({
            message: 'timetable.phrase.importFailed',
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of(false);
        })
      )
    );

    this.isImporting$.next({ trackGuid, isImporting: success });
  }

  private displayUserMessage(updateSuccessful: boolean, deleted: boolean, imported = false): void {
    const message = deleted
      ? updateSuccessful
        ? 'timetable.phrase.entryDeletedSuccess'
        : 'timetable.phrase.entryDeletedFailed'
      : imported
      ? updateSuccessful
        ? 'timetable.phrase.importSuccessful'
        : 'timetable.phrase.importFailed'
      : updateSuccessful
      ? 'timetable.phrase.entrySavedSuccess'
      : 'timetable.phrase.entrySavedFailed';

    const icon = updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs: 2000,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }
}
