<ion-menu
  menuId="mainMenu"
  class="sis-ion-menu"
  side="start"
  type="overlay"
  [swipeGesture]="false"
  contentId="sis-main-content"
  (ionWillOpen)="this.onMenuOpen()"
>
  <ion-header>
    <ion-toolbar>
      <img src="assets\sis-logo\siscontrol-color.svg" class="sc-logo ion-text-start" />
    </ion-toolbar>
  </ion-header>
  <ion-content class="sis-global-noscrollbar">
    <ion-item lines="none" id="user-area" class="user-area">
      <ion-avatar slot="start">
        <img src="assets\icons-menu\sis-icon-menu-user-avatar.svg" />
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h2>{{ userName }}</h2>
        <p>{{ tenant?.alias }}</p>
      </ion-label>
    </ion-item>
    <ion-grid>
      <ion-row class="user-area-item" *ngFor="let u of userActions" [attr.data-test]="u.dataTest">
        <ion-col size="auto" (click)="u.action()"><ion-icon class="icon" [name]="u.icon"></ion-icon></ion-col>
        <ion-col (click)="u.action()">{{ u.title | translate }} </ion-col>
        <ng-container *ngIf="this.userIsAdministrator">
          <ion-col *ngFor="let subAction of u.subActions" size="auto" [attr.data-test]="subAction.dataTest">
            <ion-icon
              class="sub-button icon"
              [name]="subAction.icon"
              (click)="subAction.action()"
              [matTooltip]="subAction.tooltip | translate"
            ></ion-icon>
          </ion-col>
        </ng-container>
      </ion-row>
    </ion-grid>

    <ion-list lines="full" class="menu-page-menulist">
      <ion-item class="menu-page-divider">
        <ion-label>{{ 'general.term.pages' | translate }}</ion-label>
      </ion-item>
      <sis-main-menu-news *ngIf="this.newsOnTop"></sis-main-menu-news>
      <sis-main-menu-list [appPages]="this.pages" [menuCtrl]="this.menuCtrl" (menuItemClicked)="this.onMenuItemClick()"></sis-main-menu-list>
      <sis-main-menu-news *ngIf="!this.newsOnTop"></sis-main-menu-news>
    </ion-list>
  </ion-content>
</ion-menu>
