<div style="padding-top: 7px">
  <ion-row *ngIf="items.length" class="sis-weblink-group-header">
    <ion-col>{{
      this.category === eWebLinkItemCategory.tenant ? this.tenantAlias : ('weblinkcollection.term.private' | translate)
    }}</ion-col>
  </ion-row>
  <ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!this.canReorder">
    <ion-item *ngFor="let item of items" class="sis-reorder-group-item" lines="none">
      <ion-grid style="padding: 0px">
        <sis-weblink-item [item]="item" [showButtons]="!this.canReorder" class="sis-alternate-background">
        </sis-weblink-item>
      </ion-grid>
      <ion-reorder slot="end">
        <ion-icon name="reorder-two" class="sis-weblink-reorder-handle"></ion-icon>
      </ion-reorder>
    </ion-item>
  </ion-reorder-group>
</div>
