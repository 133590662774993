<ion-content sis-ion-content-scrollbar class="sis-mediacenter-content">
  <!-- Big Screen -->
  <ng-container *ngIf="this.bigScreenMode$ | async">
    <ion-grid class="sis-mediacenter-no-scroll sis-mediacenter-grid ion-no-padding">
      <ion-row class="sis-global-row-50">
        <ion-col size="3" class="ion-no-padding">
          <ion-searchbar
            placeholder="{{ 'general.term.filter' | translate }}"
            type="text"
            [formControl]="searchControl"
            (ionChange)="this.searching = true"
            data-test="searchbar"
          >
          </ion-searchbar>
        </ion-col>
        <ion-col class="sis-global-center" size="0.5">
          <ion-spinner *ngIf="searching" style="height: 34px" name="bubbles"></ion-spinner>
        </ion-col>
        <ion-col></ion-col>
        <ion-col style="align-self: center" size="auto">
          <sis-logbook-button
            *ngIf="this.logbookAvailable$ | async"
            class="sis-mediacenter-logbook-button"
            [showText]="true"
            (click)="openLogbook()"
          ></sis-logbook-button>
        </ion-col>
        <ion-col *ngIf="this.writePermission" size="auto">
          <ion-chip *ngIf="!this.reorderingEnabled" color="secondary" (click)="this.onReorderClick()">
            <ion-icon
              name="sis-reorder"
              data-test="mediacenter-screen-reorder-button"
              style="margin-left: -4px"
              matTooltip="{{ 'general.phrase.editOrder' | translate }}"
              matTooltipClass="sis-global-tooltip"
            ></ion-icon>
          </ion-chip>
          <ion-chip
            *ngIf="this.reorderingEnabled"
            color="secondary"
            data-test="mediacenter-screen-reorder-save-button"
            (click)="this.onReorderSaveClick()"
          >
            <ion-label>{{ 'general.term.save' | translate }}</ion-label>
          </ion-chip>
          <ion-chip
            *ngIf="this.reorderingEnabled"
            color="secondary"
            data-test="mediacenter-screen-reorder-cancel-button"
            (click)="this.onReorderCancelClick()"
          >
            <ion-label>{{ 'general.term.cancel' | translate }}</ion-label>
          </ion-chip></ion-col
        >
        <ion-col size="auto">
          <sis-general-button
            class="sis-mediacenter-new"
            [text]="'mediacenter.phrase.newScreen'"
            [btnType]="'Secondary'"
            (sisClick)="this.writePermission && openEditscreen()"
            [disabled]="!this.writePermission || this.reorderingEnabled"
            data-test="screen-new-button"
          ></sis-general-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!this.reorderingEnabled" class="sis-mediacenter-header">
        <ion-col size="2.5">{{ 'general.term.name' | translate }} / {{ 'mediacenter.term.label' | translate }}</ion-col>
        <ion-col size="1.2">{{ 'mediacenter.term.screenSize' | translate }}</ion-col>
        <ion-col class="sis-mediacenter-col-min-200">{{ 'mediacenter.term.playlist' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'mediacenter.term.operatinghour' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'mediacenter.term.network' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'general.term.remoteaccess' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100" *ngIf="this.infoBannerReadPermission">{{
          'mediacenter.term.infoBanner' | translate
        }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'mediacenter.term.slideshow' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-50">{{ 'mediacenter.term.url' | translate }}</ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'general.term.edit' | translate }}</ion-col>
      </ion-row>
      <ion-row *ngIf="this.reorderingEnabled" class="sis-mediacenter-header">
        <ion-col size="2.5">{{ 'general.term.name' | translate }} / {{ 'mediacenter.term.label' | translate }}</ion-col>
        <ion-col size="1.2">{{ 'mediacenter.term.screenSize' | translate }}</ion-col>
        <ion-col class="sis-mediacenter-col-min-200">{{ 'mediacenter.term.playlist' | translate }}</ion-col>
        <ion-col class="sis-mediacenter-col-max-550"></ion-col>
        <ion-col class="sis-global-center sis-mediacenter-col-max-100">{{ 'general.term.edit' | translate }}</ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid *ngIf="this.filteredScreens$ | async" class="sis-mediacenter-grid" style="padding-top: 0px">
      <sis-media-center-screen-group
        *ngFor="let screenGroup of this.screenGroups$ | async"
        [group]="screenGroup"
        [infoBannerReadPermission]="this.infoBannerReadPermission"
        [writePermission]="this.writePermission"
        [openEditscreen]="this.openEditscreen"
        [reorderingEnabled]="this.reorderingEnabled"
      ></sis-media-center-screen-group>
    </ion-grid>
  </ng-container>

  <!-- Small Screen -->
  <ng-container *ngIf="!(this.bigScreenMode$ | async)">
    <ion-grid class="sis-mediacenter-no-scroll sis-mediacenter-grid-mobile ion-no-padding">
      <ion-row>
        <ion-col>
          <sis-general-button
            class="sis-mediacenter-new"
            [text]="'mediacenter.phrase.newScreen'"
            [btnType]="'Secondary'"
            (sisClick)="this.writePermission && openEditscreen()"
            [disabled]="!this.writePermission"
            data-test="screen-new-button"
          ></sis-general-button>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-center sis-global-row">
        <ion-col class="ion-no-padding">
          <ion-searchbar
            placeholder="{{ 'general.term.filter' | translate }}"
            type="text"
            [formControl]="searchControl"
            (ionChange)="this.searching = true"
          >
          </ion-searchbar>
        </ion-col>
        <ion-col size="auto" style="min-width: 40px">
          <ion-spinner *ngIf="searching" name="bubbles" style="height: 1.5em"></ion-spinner>
        </ion-col>
        <ion-col size="auto">
          <sis-logbook-button
            *ngIf="this.logbookAvailable$ | async"
            class="sis-mediacenter-logbook-button"
            [showText]="false"
            (click)="openLogbook()"
          ></sis-logbook-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid *ngIf="this.filteredScreens$ | async" class="sis-mediacenter-grid-mobile sis-screens-grid-mobile" style="padding-top: 0px">
      <div class="sis-screens-container-mobile">
        <sis-media-center-screen-group
          *ngFor="let screenGroup of this.screenGroups$ | async"
          [group]="screenGroup"
          [infoBannerReadPermission]="this.infoBannerReadPermission"
          [writePermission]="this.writePermission"
          [openEditscreen]="this.openEditscreen"
          [reorderingEnabled]="this.reorderingEnabled"
        ></sis-media-center-screen-group>
      </div>
    </ion-grid>
  </ng-container>
</ion-content>
