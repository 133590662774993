import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ContextMenuItem } from 'src/app/core/components/context-menu/context-menu-item.model';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';
import { User } from 'src/app/user-management/domain/user.model';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { UserManagementEditModalComponent } from 'src/app/user-management/user-management-edit-modal/user-management-edit-modal.component';

@Component({
  selector: 'sis-user-management',
  templateUrl: './user-management.page.html',
  styleUrls: ['./user-management.page.scss'],
})
export class UserManagementPage extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.USERMANAGEMENT, FeatureAccessLevel.WRITE);

  featureId = FeatureId.USERMANAGEMENT;

  users: User[] = [];

  selectedTenant: ManagedTenant;
  managedTenants: ManagedTenant[];

  tenantFeatures: Feature[];
  featureIdTranslatedFeatureNameMapping: Map<number, string> = new Map<number, string>();

  searchControl: FormControl;
  searching: boolean = false;
  searchTerm: string;

  writePermission: boolean;
  isAdministrator: boolean;
  usersLoaded: boolean = false;
  maxUsersReached: boolean = false;

  contextMenuX: number;
  contextMenuY: number;
  showContextMenu: boolean;
  contextMenuItems: ContextMenuItem[];
  contextMenuTitle: string;
  displayedColumns: string[] = [];

  private usersFiltered: User[] = [];

  constructor(
    private userManagementService: UserManagementService,
    private logbookModalService: LogbookModalService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {
    super();

    this.searchControl = new FormControl();
  }

  ngOnInit() {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features.some((f) => f.hasMinimumRequirementFor(UserManagementPage.requiredFeature)) ?? false;
    });

    combineLatest([
      this.userManagementService.tenantFeatures$,
      this.userManagementService.featureTranslationMap$,
      this.userSettingsService.userSettings$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([features, featureTranslationMap, userSettings]) => {
        this.tenantFeatures = features.sort((a, b) => a.featureId - b.featureId);
        if (!userSettings.isAdministrator) {
          this.tenantFeatures = this.tenantFeatures.filter((f) => !f.adminOnly);
        }
        this.featureIdTranslatedFeatureNameMapping = new Map(
          this.tenantFeatures
            .sort((a, b) => a.featureOrder - b.featureOrder)
            .map((feature) => [feature.featureId, featureTranslationMap.get(feature.featureId)])
        );

        this.displayedColumns = ['displayName'].concat(this.tenantFeatures.map((f) => f.featureId.toString()));
      });

    combineLatest([
      this.destinationService.selectedTenant$,
      this.userManagementService.userManagementData$,
      this.userSettingsService.userSettings$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([tenant, userManagementData, userSettings]) => {
        this.users = userManagementData.users.sort((a, b) => a.displayName?.localeCompare(b.displayName));
        this.managedTenants = userManagementData.managedTenants.sort((a, b) => {
          return a.guid === tenant.guid ? -1 : a.alias?.localeCompare(b.alias);
        });

        this.selectedTenant = this.managedTenants.find((t) => t.guid === tenant.guid);
        this.maxUsersReached = this.selectedTenant?.activeUsers >= this.selectedTenant?.maxUsers;
        this.isAdministrator = userSettings.isAdministrator;
        this.filterUserList();
        this.usersLoaded = true;
      });

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.searching = false;
      this.searchTerm = search.toLowerCase();
      this.filterUserList();
    });
  }

  trackUsers(_: any, item: User) {
    return item.userGuid.toString();
  }

  openBatchFeaturePermissionContextMenu(event: any, index: number): void {
    const tenantFeature = this.tenantFeatures[index];
    if (tenantFeature.featureId === FeatureId.USERMANAGEMENT) {
      return;
    }

    const contextMenuItems: ContextMenuItem[] = [];
    const requiredFeature = new Feature(FeatureId.USERMANAGEMENT, FeatureAccessLevel.WRITE);

    if (tenantFeature.featureAccessLevel >= FeatureAccessLevel.NONE) {
      contextMenuItems.push({
        translationString: 'usermanagement.accesslevel.none',
        action: 'batch',
        requiredFeature,
        item: new Feature(tenantFeature.featureId, FeatureAccessLevel.NONE),
      });
    }

    if (tenantFeature.featureAccessLevel >= FeatureAccessLevel.READ) {
      contextMenuItems.push({
        translationString: 'usermanagement.accesslevel.read',
        action: 'batch',
        requiredFeature,
        item: new Feature(tenantFeature.featureId, FeatureAccessLevel.READ),
      });
    }

    if (tenantFeature.featureAccessLevel >= FeatureAccessLevel.WRITE) {
      contextMenuItems.push({
        translationString: 'usermanagement.accesslevel.write',
        action: 'batch',
        requiredFeature,
        item: new Feature(tenantFeature.featureId, FeatureAccessLevel.WRITE),
      });
    }

    this.contextMenuItems = contextMenuItems;

    this.contextMenuX = event.x - 70;
    this.contextMenuY = event.y - 50;
    this.contextMenuTitle = this.featureIdTranslatedFeatureNameMapping[index];
    this.showContextMenu = true;
  }

  async contextMenuItemSelected(selectedItem: ContextMenuItem): Promise<void> {
    this.showContextMenu = false;

    if (selectedItem?.action == 'batch') {
      await this.setBatchFeaturePermissions(selectedItem.item);
    }
    if (
      selectedItem?.action == 'cockpitRead' ||
      selectedItem?.action == 'cockpitWrite' ||
      selectedItem?.action == 'sismediaRead' ||
      selectedItem?.action == 'sismediaWrite' ||
      selectedItem?.action == 'allNone'
    ) {
      await this.setBatchUserPermissions(selectedItem.item, selectedItem.action);
    }
    if (selectedItem?.action == 'allMax') {
      await this.setAllUserPermissionsMax(selectedItem.item);
    }
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.USERMANAGEMENT]]);
  }

  openUserContextMenu(user: User, event: any): void {
    const requiredFeature = new Feature(FeatureId.USERMANAGEMENT, FeatureAccessLevel.WRITE);
    const contextMenuItems: ContextMenuItem[] = [];

    contextMenuItems.push({
      translationString: 'usermanagement.term.cockpitRead',
      requiredFeature,
      action: 'cockpitRead',
      item: user,
    });

    contextMenuItems.push({
      translationString: 'usermanagement.term.cockpitWrite',
      requiredFeature,
      action: 'cockpitWrite',
      item: user,
    });

    contextMenuItems.push({
      translationString: 'usermanagement.term.sismediaRead',
      requiredFeature,
      action: 'sismediaRead',
      item: user,
    });

    contextMenuItems.push({
      translationString: 'usermanagement.term.sismediaWrite',
      requiredFeature,
      action: 'sismediaWrite',
      item: user,
    });

    contextMenuItems.push({
      translationString: 'usermanagement.term.allMax',
      requiredFeature,
      action: 'allMax',
      item: user,
    });

    contextMenuItems.push({
      translationString: 'usermanagement.term.allNone',
      requiredFeature,
      action: 'allNone',
      item: user,
    });

    this.contextMenuItems = contextMenuItems;

    this.contextMenuX = event.x - 70;
    this.contextMenuY = event.y - 50;
    if (this.contextMenuY + 200 > this.platform.height()) {
      this.contextMenuY = this.platform.height() - 210;
    }
    this.contextMenuTitle = '';
    this.showContextMenu = true;
  }

  async addUser(managedTenants: ManagedTenant[]): Promise<void> {
    await this.openEditModal(null, managedTenants, this.isAdministrator);
  }

  isAdminOnly(index: number): boolean {
    return this.tenantFeatures[index].adminOnly;
  }

  private async setAllUserPermissionsMax(user: User): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert(
      'usermanagement.phrase.maxAllUserPermissionsDefault',
      'general.term.yes'
    );

    if (confirmed && user) {
      await this.userManagementService.setBatchPermissions(
        this.tenantFeatures.filter((f) => f.featureId != 3006),
        [user.userGuid]
      );
    }
  }

  private async setBatchFeaturePermissions(feature: Feature): Promise<void> {
    if (!feature) {
      return;
    }

    const usersToUpdate = this.usersFiltered.map((u) => u.userGuid);
    const featuresToUpdate = this.tenantFeatures
      .filter((f) => f.featureId == feature.featureId)
      .map((f) => new Feature(f.featureId, feature.featureAccessLevel));

    this.userManagementService.setBatchPermissions(featuresToUpdate, usersToUpdate);
  }

  private setBatchUserPermissions(user: User, action: string): void {
    if (!user) {
      return;
    }
    const filterFunc =
      action == 'allNone'
        ? (f: Feature) => f.featureId != 3006
        : action == 'cockpitRead' || action == 'cockpitWrite'
        ? (f: Feature) => f.featureId < 2000
        : (f: Feature) => f.featureId >= 2000 && f.featureId < 3000;
    const accessLevel =
      action == 'allNone'
        ? FeatureAccessLevel.NONE
        : action == 'cockpitRead' || action == 'sismediaRead'
        ? FeatureAccessLevel.READ
        : FeatureAccessLevel.WRITE;
    const featuresToUpdate = this.tenantFeatures.filter(filterFunc).map((f) => new Feature(f.featureId, accessLevel));
    const usersToUpdate = this.usersFiltered.filter((u) => u.userGuid == user.userGuid).map((u) => u.userGuid);

    this.userManagementService.setBatchPermissions(featuresToUpdate, usersToUpdate);
  }

  private async openEditModal(user: User, managedTenants: ManagedTenant[], isAdministrator): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: UserManagementEditModalComponent,
      cssClass: 'sis-user-management-edit-modal',
      componentProps: {
        user,
        managedTenants,
        isAdministrator,
      },
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((overlayEventDetail) => (this.usersLoaded = !overlayEventDetail.data));
    await modal.present();
  }

  private filterUserList(): void {
    this.usersFiltered = this.users
      .filter(
        (u) =>
          !this.searchTerm ||
          u.email?.toLowerCase().indexOf(this.searchTerm) > -1 ||
          u.displayName?.toLowerCase().indexOf(this.searchTerm) > -1
      )
      .sort((a) => (a.isSisag ? -1 : 1));
  }
}
