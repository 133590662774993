<ion-grid class="sis-global-page-grid sis-global-no-scrollbar" data-test="weblink-collection-grid">
  <sis-page-title
    [title]="'general.term.weblinkcollection'"
    [reorderingEnabled]="this.reorderingEnabled"
    [showAddButton]="this.writePermission"
    [showReorderButton]="this.writePermission"
    [ignoreAdminRequirement]="true"
    (onReorderClick)="this.onReorderClick()"
    (onReorderCancelClick)="this.onReorderCancelClick()"
    (openEditModal)="this.openEditModal()"
  >
  </sis-page-title>

  <ion-row>
    <ion-col style="padding: 0">
      <sis-weblink-group
        *ngFor="let category of categories"
        [category]="category"
        [writePermission]="this.writePermission"
        [readPermission]="this.readPermission"
        [reorderingEnabled]="this.reorderingEnabled"
        [tenantAlias]="this.tenantAlias"
        data-test="weblink-reorder-group"
      >
      </sis-weblink-group>
    </ion-col>
  </ion-row>
</ion-grid>
