import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, firstValueFrom, map, Observable, shareReplay, switchMap } from 'rxjs';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterConfigurationAdapter } from 'src/app/media-center/domain/mediacenter-configuration.adapter';
import { MediaCenterConfiguration } from 'src/app/media-center/domain/mediacenter-configuration.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterService {
  private readonly requestConfigurationUrl = '/mediacenter/configuration';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ);

  readonly configuration$: Observable<MediaCenterConfiguration> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.httpClient.get(`${environment.baseUrlApi}${this.requestConfigurationUrl}`)),
    map((data) => MediaCenterConfigurationAdapter.adapt(data)),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(private destinationService: DestinationService, private httpClient: HttpClient) {}

  async openSlideShowScreenPreview(
    screenLabel: string,
    screenWidth: number,
    screenHeight: number,
    previewUrl?: string,
    infoBannerGuid?: string
  ): Promise<void> {
    await this.openSlideShowPreview('screen', screenLabel, screenWidth, screenHeight, previewUrl, infoBannerGuid);
  }

  async openSlideShowPlaylistPreview(playlistName: string): Promise<void> {
    await this.openSlideShowPreview('playlist', playlistName);
  }

  private async openSlideShowPreview(
    contentType: string,
    contentId: string,
    screenWidth?: number,
    screenHeight?: number,
    previewUrl?: string,
    infoBannerGuid?: string
  ): Promise<void> {
    if (previewUrl) {
      const windowDimensions = `width=${screenWidth},height=${screenHeight}`;
      window.open(previewUrl, '_blank', windowDimensions + ', toolbar=no, location=no, status=no, menubar=no, scrollbars=no');
    } else {
      const tenant = await firstValueFrom(this.destinationService.selectedTenant$);
      if (tenant) {
        let url = `${environment.slideshowPlayerWebApp}/slideshow/${tenant.sisId}/${contentType}/${contentId}?showinfo=true&nightMode=off`;
        const windowDimensions = this.getWindowDimensions(screenWidth, screenHeight);
        if (infoBannerGuid) {
          url += `&infobanner=${infoBannerGuid}`;
        }
        window.open(url, '_blank', windowDimensions + ', toolbar=no, location=no, status=no, menubar=no, scrollbars=no');
      }
    }
  }

  private getWindowDimensions(configWidth: number, configHeight: number): string {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    let windowWidth = configWidth ?? 1920;
    let windowHeight = configHeight ?? 1080;

    if (configWidth > screenWidth || configHeight > screenHeight) {
      const configRatio = configWidth / configHeight;
      const screenRatio = screenWidth / screenHeight;

      if (screenRatio > configRatio) {
        windowHeight = Math.round(screenHeight * 0.8);
        const scale = windowHeight / configHeight;
        windowWidth = Math.round(configWidth * scale);
      } else {
        windowWidth = Math.round(screenWidth * 0.8);
        const scale = windowWidth / configWidth;
        windowHeight = Math.round(configHeight * scale);
      }
    }
    return `width=${windowWidth},height=${windowHeight}`;
  }
}
