/* eslint-disable max-classes-per-file */
import { BreakdownInfoActiveTextAdapter } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.adapter';
import { BreakdownInfoLibraryEntryAdapter } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.adapter';
import { LogbookEntryAdapter } from 'src/app/core/components/logbook/domain/logbook-entry.adapter';
import {
  AlarmLibraryOverrideUpdatedEvent,
  BreakdownInfoActiveTextUpdatedEvent,
  BreakdownInfoLibraryUpdatedEvent,
  BusEvent,
  ClampingCountResetedEvent,
  EcoModeSettingsUpdatedEvent,
  EmailDeliveryRecipientUpsertedEvent,
  EmailDeliverySentEvent,
  FeatureUpdatedEvent,
  HtmlToPdfGeneratedEvent,
  InfoBannerMappingUpdatedEvent,
  InfoBannerUpdatedEvent,
  InfotextUpdatedEvent,
  LastSlopeControlUpdatedEvent,
  LedTickerUpdatedEvent,
  LogbookUpdatedEvent,
  MaintenanceUpdatedEvent,
  MarkerPositionUpdatedEvent,
  MediaCenterBlobStorageUpdatedEvent,
  MediaCenterPlaylistUpdatedEvent,
  MediaCenterScreenUpdatedEvent,
  MediaCenterSlideUpdatedEvent,
  MediaCenterThumbnailUpdatedEvent,
  MeteoInfoUpdatedEvent,
  OperatingInfoActiveTextUpdatedEvent,
  OperatingInfoLibraryUpdatedEvent,
  ParkingCockpitOrderUpdatedEvent,
  ParkingCurrentOccupancyUpdatedEvent,
  ParkingEditedEvent,
  ParkingExportMappingUpdatedEvent,
  ParkingStatusUpdatedEvent,
  RopewayAvailabilityTrendUpdatedEvent,
  RopewaySettingsUpdatedEvent,
  SambesiWhatsUpMessagesUpdatedEvent,
  SisMapEditTokenUpdatedEvent,
  SisMediaAssetCockpitOrderUpdatedEvent,
  SisMediaAssetEditUpdatedEvent,
  SisMediaAssetExportMappingUpdatedEvent,
  SisMediaAssetOverrideUpdatedEvent,
  SisMediaOperatingHoursUpdatedEvent,
  SisMediaPlsUpdatedEvent,
  SisMediaSettingUpdatedEvent,
  SisMediaStatusUpdatedEvent,
  SlopesOperatingTimesUpdatedEvent,
  STnetStatusUpdatedEvent,
  STnetUpdatedEvent,
  TenantUpdatedEvent,
  TimetableImportUpdatedEvent,
  TimetableIntervalDayUpdatedEvent,
  TimetableJourneyUpdatedEvent,
  TimetableRealTimeDataUpdatedEvent,
  TimetableSeasonUpdatedEvent,
  UserAlarmConfigUpdatedEvent,
  UserAlarmEvent,
  UserManagementUserUpdatedEvent,
  UserPermissionUpdatedEvent,
  UserSettingsUpdatedEvent,
  WebLinkCollectionUpdatedEvent,
} from 'src/app/core/eventbus/events';
import { STnetStatusAdapter as STnetStatusAdapter } from 'src/app/core/stnet/stnet-status.adapter';
import { SisConverter } from 'src/app/core/utils/sis-converter';
import { TenantAdapter } from 'src/app/domain/tenant/tenant.adapter';
import { FeatureAdapter as FeatureManagementFeatureAdapter } from 'src/app/feature-management/domain/feature.adapter';
import { LedTickerAdapter } from 'src/app/led-ticker/domain/led-ticker.adapter';
import { AlarmLibraryItemAdapter } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.adapter';
import { UserAlarmConfigAdapter } from 'src/app/maps/alarmlibrary/domain/useralarmconfig.adapter';
import { RopewayEcoModeSettingsAdapter } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.adapter';
import { RopewaySettingsAdapter } from 'src/app/maps/domain/ropeway-settings/ropeway-settings.adapter';
import { UserAlarmAdapter } from 'src/app/maps/livedata/user-alarm.adapter';
import { PlaylistAdapter } from 'src/app/media-center/playlists/domain/playlist.adapter';
import { ScreenAdapter } from 'src/app/media-center/screens/domain/screen.adapter';
import { SlideAdapter } from 'src/app/media-center/slides/domain/slide.adapter';
import { MeteoInfoItemAdapter } from 'src/app/meteo-info/domain/meteo-info-item.adapter';
import { OperatingInfoEntryAdapter } from 'src/app/operating-info/domain/operating-info-entry.adapter';
import { SisMediaAssetOverrideEntryAdapter } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.adapter';
import { SisMediaSettingAdapter } from 'src/app/sismedia-setting/sismedia-setting.adapter';
import { STnetItemAdapter } from 'src/app/stnet/domain/stnet-item.adapter';
import { TimetableIntervalDayAdapter } from 'src/app/timetable/domain/timetable-interval-day.adapter';
import { TimetableSeasonAdapter } from 'src/app/timetable/domain/timetable-season.adapter';
import { UserAdapter } from 'src/app/user-management/domain/user.adapter';

export const EventAdapterRegistry = new Map<string, (payload: string) => BusEvent>();

export class UserAlarmEventAdapter {
  static adapt(payload: string): UserAlarmEvent {
    const event = new UserAlarmEvent();
    const parsed = JSON.parse(payload);
    event.alarm = UserAlarmAdapter.adapt([parsed])[0];
    return event;
  }
}
EventAdapterRegistry.set('UserAlarmEvent', UserAlarmEventAdapter.adapt);

export class UserSettingsUpdatedEventAdapter {
  static adapt(payload: string): UserSettingsUpdatedEvent {
    const event = new UserSettingsUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.userSettings = parsed;
    event.userSettings.lastNewsViewed = parsed.lastNewsViewed != null ? new Date(parsed.lastNewsViewed) : null;
    event.updateSuccessful = parsed.updateSuccessful;
    event.updaterId = parsed.updaterId;
    return event;
  }
}
EventAdapterRegistry.set('UserSettingsUpdatedEvent', UserSettingsUpdatedEventAdapter.adapt);

export class UserAlarmConfigUpdatedEventAdapter {
  static adapt(payload: string): UserAlarmConfigUpdatedEvent {
    const event = new UserAlarmConfigUpdatedEvent();
    event.userAlarmConfig = UserAlarmConfigAdapter.adapt([JSON.parse(payload)])[0];
    return event;
  }
}
EventAdapterRegistry.set('UserAlarmConfigUpdatedEvent', UserAlarmConfigUpdatedEventAdapter.adapt);

export class BreakdownInfoActiveTextUpdatedEventAdapter {
  static adapt(payload: string): BreakdownInfoActiveTextUpdatedEvent {
    const event = new BreakdownInfoActiveTextUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.breakdownInfoActiveTexts = BreakdownInfoActiveTextAdapter.adapt(parsed.changedActiveTexts);
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('BreakdownInfoActiveTextUpdatedEvent', BreakdownInfoActiveTextUpdatedEventAdapter.adapt);

export class BreakdownInfoLibraryUpdatedEventAdapter {
  static adapt(payload: string): BreakdownInfoLibraryUpdatedEvent {
    const event = new BreakdownInfoLibraryUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.breakdownInfo = BreakdownInfoLibraryEntryAdapter.adapt([parsed.breakdownInfo])[0];
    event.removed = parsed.removed;
    return event;
  }
}
EventAdapterRegistry.set('BreakdownInfoLibraryUpdatedEvent', BreakdownInfoLibraryUpdatedEventAdapter.adapt);

export class OperatingInfoActiveTextUpdatedEventAdapter {
  static adapt(payload: string): OperatingInfoActiveTextUpdatedEvent {
    const event = new OperatingInfoActiveTextUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.operatingInfoEntries = OperatingInfoEntryAdapter.adapt(parsed.operatingInfoEntries);
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('OperatingInfoActiveTextUpdatedEvent', OperatingInfoActiveTextUpdatedEventAdapter.adapt);

export class OperatingInfoLibraryUpdatedEventAdapter {
  static adapt(payload: string): OperatingInfoLibraryUpdatedEvent {
    const event = new OperatingInfoLibraryUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.operatingInfoLibraryEntry = OperatingInfoEntryAdapter.adapt([parsed.operatingInfoEntry])[0];
    event.removed = parsed.removed;
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('OperatingInfoLibraryUpdatedEvent', OperatingInfoLibraryUpdatedEventAdapter.adapt);

export class MaintenanceUpdatedEventAdapter {
  static adapt(payload: string): MaintenanceUpdatedEvent {
    const event = new MaintenanceUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.tenantSisIdsUnderMaintenance = parsed;
    return event;
  }
}
EventAdapterRegistry.set('MaintenanceUpdatedEvent', MaintenanceUpdatedEventAdapter.adapt);

export class AlarmLibraryOverrideUpdatedEventAdapter {
  static adapt(payload: string): AlarmLibraryOverrideUpdatedEvent {
    const event = new AlarmLibraryOverrideUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.alarmLibraryEntry = AlarmLibraryItemAdapter.adapt([parsed.alarmLibraryOverrideEntry])[0];
    event.tenantGuid = parsed.tenantGuid;
    event.successful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('AlarmLibraryOverrideUpdatedEvent', AlarmLibraryOverrideUpdatedEventAdapter.adapt);

export class SisMediaStatusUpdatedEventAdapter {
  static adapt(payload: string): SisMediaStatusUpdatedEvent {
    const event = new SisMediaStatusUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.guid = parsed.assetGuid;
    event.tenantGuid = parsed.tenantGuid;
    event.status = parsed.status;
    event.activeStatus = parsed.activeStatus;
    event.statusOverrideActive = parsed.statusOverrideActive;
    event.lastStatusChange = new Date(parsed.lastStatusChange);
    event.updateSuccessful = parsed.updateSuccessful;
    event.failReason = parsed.failReason;
    event.assetCategory = parsed.assetCategory;
    event.changedBy = parsed.changedBy;
    return event;
  }
}
EventAdapterRegistry.set('SisMediaStatusUpdatedEvent', SisMediaStatusUpdatedEventAdapter.adapt);

export class SisMediaPlsUpdatedEventAdapter {
  static adapt(payload: string): SisMediaPlsUpdatedEvent {
    const event = new SisMediaPlsUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.assetGuid = parsed.AssetGuid;
    event.assetStatus = parsed.AssetStatus;
    event.successfullyUpdatedDisplays = parsed.SuccessfullyUpdatedDisplays;
    event.unsuccessfullyUpdatedDisplays = parsed.UnsuccessfullyUpdatedDisplays;
    event.displaysInSimulationMode = parsed.DisplaysInSimulationMode;
    return event;
  }
}
EventAdapterRegistry.set('SisMediaPlsUpdatedEvent', SisMediaPlsUpdatedEventAdapter.adapt);

export class MediaCenterSlideUpdatedEventAdapter {
  static adapt(payload: string): MediaCenterSlideUpdatedEvent {
    const event = new MediaCenterSlideUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.slide = parsed.slide ? SlideAdapter.adapt([parsed.slide])[0] : undefined;
    event.removed = parsed.removed;
    event.success = parsed.success;
    event.settings = parsed.slideSettingsUpdated;
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('MediaCenterSlideUpdatedEvent', MediaCenterSlideUpdatedEventAdapter.adapt);

export class MediaCenterScreenUpdatedEventAdapter {
  static adapt(payload: string): MediaCenterScreenUpdatedEvent {
    const event = new MediaCenterScreenUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.screens = parsed.screens ? ScreenAdapter.adapt(parsed.screens) : [];
    event.removed = parsed.removed;
    event.success = parsed.success;
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('MediaCenterScreenUpdatedEvent', MediaCenterScreenUpdatedEventAdapter.adapt);

export class MediaCenterPlaylistUpdatedEventAdapter {
  static adapt(payload: string): MediaCenterPlaylistUpdatedEvent {
    const event = new MediaCenterPlaylistUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.playlist = parsed.playlist ? PlaylistAdapter.adapt([parsed.playlist])[0] : undefined;
    event.removed = parsed.removed;
    event.success = parsed.success;
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('MediaCenterPlaylistUpdatedEvent', MediaCenterPlaylistUpdatedEventAdapter.adapt);

export class MediaCenterThumbnailUpdatedEventAdapter {
  static adapt(payload: string): MediaCenterThumbnailUpdatedEvent {
    const event = new MediaCenterThumbnailUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.url = parsed.url;
    event.thumbnailUrl = parsed.thumbnailUrl;
    return event;
  }
}
EventAdapterRegistry.set('MediaCenterThumbnailUpdatedEvent', MediaCenterThumbnailUpdatedEventAdapter.adapt);

export class MediaCenterBlobStorageUpdatedEventAdapter {
  static adapt(payload: string): MediaCenterBlobStorageUpdatedEvent {
    const event = new MediaCenterBlobStorageUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.fileName = parsed.fileName;
    event.successful = parsed.successful;
    event.uploadedBy = parsed.uploadedBy;
    return event;
  }
}
EventAdapterRegistry.set('MediaCenterBlobStorageUpdatedEvent', MediaCenterBlobStorageUpdatedEventAdapter.adapt);

export class InfoBannerUpdatedEventAdapter {
  static adapt(payload: string): InfoBannerUpdatedEvent {
    const event = new InfoBannerUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.infoBanner = parsed.infoBanner;
    event.updateSuccessful = parsed.updateSuccessful;
    event.removed = parsed.removed;
    return event;
  }
}
EventAdapterRegistry.set('InfoBannerUpdatedEvent', InfoBannerUpdatedEventAdapter.adapt);

export class InfoBannerMappingUpdatedEventAdapter {
  static adapt(payload: string): InfoBannerMappingUpdatedEvent {
    const event = new InfoBannerMappingUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.infoBannerMappings = parsed.infoBannerMappings;
    event.infoBanners = parsed.infoBanners;
    event.tenantGuid = parsed.tenantGuid;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('InfoBannerMappingUpdatedEvent', InfoBannerMappingUpdatedEventAdapter.adapt);

export class InfotextUpdatedEventAdapter {
  static adapt(payload: string): InfotextUpdatedEvent {
    const event = new InfotextUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.guid = parsed.infotextEntry.guid;
    event.categoryId = parsed.infotextEntry.categoryId;
    event.textDe = parsed.infotextEntry.textDe;
    event.textEn = parsed.infotextEntry.textEn;
    event.textFr = parsed.infotextEntry.textFr;
    event.textIt = parsed.infotextEntry.textIt;
    event.updateSuccessful = parsed.updateSuccessful;
    event.changedBy = parsed.infotextEntry.changedBy;
    event.failReason = parsed.failReason;
    return event;
  }
}
EventAdapterRegistry.set('InfotextUpdatedEvent', InfotextUpdatedEventAdapter.adapt);

export class LastSlopeControlEventAdapter {
  static adapt(payload: string): LastSlopeControlUpdatedEvent {
    const event = new LastSlopeControlUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.guid = parsed.guid;
    event.zone = parsed.zone;
    event.times = parsed.times;
    event.activeTime = parsed.activeTime;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('LastSlopeControlUpdatedEvent', LastSlopeControlEventAdapter.adapt);

export class TimetableJourneyUpdatedEventAdapter {
  static adapt(payload: string): TimetableJourneyUpdatedEvent {
    const event = new TimetableJourneyUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.timetableJourney = parsed.timetableJourney;
    event.tenantGuid = parsed.tenantGuid;
    event.deleted = parsed.deleted;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('TimetableJourneyUpdatedEvent', TimetableJourneyUpdatedEventAdapter.adapt);

export class TimetableSeasonUpdatedEventAdapter {
  static adapt(payload: string): TimetableSeasonUpdatedEvent {
    const event = new TimetableSeasonUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.timetableSeason = TimetableSeasonAdapter.adapt([parsed.timetableSeason])[0];
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('TimetableSeasonUpdatedEvent', TimetableSeasonUpdatedEventAdapter.adapt);

export class TimetableImportUpdatedEventAdapter {
  static adapt(payload: string): TimetableImportUpdatedEvent {
    const event = new TimetableImportUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.trackGuid = parsed.trackGuid;
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('TimetableImportUpdatedEvent', TimetableImportUpdatedEventAdapter.adapt);

export class TimetableIntervalDayUpdatedEventAdapter {
  static adapt(payload: string): TimetableIntervalDayUpdatedEvent {
    const event = new TimetableIntervalDayUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.timetableIntervalDay = TimetableIntervalDayAdapter.adapt(parsed.timetableIntervalDay);
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('TimetableIntervalDayUpdatedEvent', TimetableIntervalDayUpdatedEventAdapter.adapt);

export class SisMediaAssetOverrideEventAdapter {
  static adapt(payload: string): SisMediaAssetOverrideUpdatedEvent {
    const event = new SisMediaAssetOverrideUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.sisMediaAssetOverrideEntry = SisMediaAssetOverrideEntryAdapter.adapt([parsed.sisMediaAssetOverrideEntry])[0];
    event.operation = parsed.operation;
    event.changedBy = parsed.changedBy;
    event.lastChanged = parsed.lastChanged;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('SisMediaAssetOverrideUpdatedEvent', SisMediaAssetOverrideEventAdapter.adapt);

export class SisMediaAssetEditUpdatedEventAdapter {
  static adapt(payload: string): SisMediaAssetEditUpdatedEvent {
    const event = new SisMediaAssetEditUpdatedEvent();
    Object.assign(event, JSON.parse(payload));
    return event;
  }
}
EventAdapterRegistry.set('SisMediaAssetEditUpdatedEvent', SisMediaAssetEditUpdatedEventAdapter.adapt);

export class SisMediaAssetCockpitOrderUpdatedEventAdapter {
  static adapt(payload: string): SisMediaAssetCockpitOrderUpdatedEvent {
    const event = new SisMediaAssetCockpitOrderUpdatedEvent();
    Object.assign(event, JSON.parse(payload));
    return event;
  }
}
EventAdapterRegistry.set('SisMediaAssetCockpitOrderUpdatedEvent', SisMediaAssetCockpitOrderUpdatedEventAdapter.adapt);

export class SisMediaAssetExportMappingEventAdapter {
  static adapt(payload: string): SisMediaAssetExportMappingUpdatedEvent {
    const event = new SisMediaAssetExportMappingUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.assetCategory = parsed.assetCategory;
    event.assetGuid = parsed.assetGuid;
    event.exportGuids = parsed.exportGuids;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    event.tenantGuid = parsed.tenantGuid;
    return event;
  }
}
EventAdapterRegistry.set('SisMediaAssetExportMappingUpdatedEvent', SisMediaAssetExportMappingEventAdapter.adapt);

export class UserPermissionUpdatedEventAdapter {
  static adapt(payload: string): UserPermissionUpdatedEvent {
    const event = new UserPermissionUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.userGuid = parsed.userGuid;
    event.tenantGuid = parsed.tenantGuid;
    event.featureId = parsed.featureId;
    event.featureAccessLevel = parsed.featureAccessLevel;
    event.adminOnly = parsed.adminOnly;
    event.changedBy = parsed.changedBy;
    return event;
  }
}
EventAdapterRegistry.set('UserPermissionUpdatedEvent', UserPermissionUpdatedEventAdapter.adapt);

export class MeteoInfoUpdatedEventAdapter {
  static adapt(payload: string): MeteoInfoUpdatedEvent {
    const event = new MeteoInfoUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.meteoInfoItems = MeteoInfoItemAdapter.adapt(parsed.updatedMeteoInfos);
    event.updateSuccessful = parsed.updateSuccessful;
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.meteoInfoItems.forEach((m) => {
      m.changedBy = parsed.changedBy;
      m.changedByEmail = parsed.changedByEmail;
      m.lastChange = SisConverter.toDateOrUndefined(parsed.lastChange);
    });
    return event;
  }
}
EventAdapterRegistry.set('MeteoInfoUpdatedEvent', MeteoInfoUpdatedEventAdapter.adapt);

export class STnetDataUpdatedEventAdapter {
  static adapt(payload: string): STnetUpdatedEvent {
    const event = new STnetUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.stnetItem = STnetItemAdapter.adapt(parsed.sTnetData);
    return event;
  }
}
EventAdapterRegistry.set('STnetDataUpdatedEvent', STnetDataUpdatedEventAdapter.adapt);

export class STnetStatusUpdatedEventAdapter {
  static adapt(payload: string): STnetStatusUpdatedEvent {
    const event = new STnetStatusUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.status = STnetStatusAdapter.adapt(parsed.status);
    return event;
  }
}
EventAdapterRegistry.set('STnetStatusUpdatedEvent', STnetStatusUpdatedEventAdapter.adapt);

export class LogbookUpdatedEventAdapter {
  static adapt(payload: string): LogbookUpdatedEvent {
    const event = new LogbookUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.logbookEntry = LogbookEntryAdapter.adapt([parsed])[0];
    return event;
  }
}
EventAdapterRegistry.set('LogbookUpdatedEvent', LogbookUpdatedEventAdapter.adapt);

export class LedTickerUpdatedEventAdapter {
  static adapt(payload: string): LedTickerUpdatedEvent {
    const event = new LedTickerUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.ledTicker = LedTickerAdapter.adapt(parsed)[0];
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('LedTickerUpdatedEvent', LedTickerUpdatedEventAdapter.adapt);

export class SisMediaOperatingHoursUpdatedEventAdapter {
  static adapt(payload: string): SisMediaOperatingHoursUpdatedEvent {
    const event = new SisMediaOperatingHoursUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.operatingHoursEnabled = parsed.operatingHoursEnabled;
    event.operatingHours = parsed.operatingHours;
    event.assetGuid = parsed.assetGuid;
    event.assetCategory = parsed.assetCategory;
    event.tenantGuid = parsed.tenantGuid;
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('SisMediaOperatingHoursUpdatedEvent', SisMediaOperatingHoursUpdatedEventAdapter.adapt);

export class HtmlToPdfGeneratedEventAdapter {
  static adapt(payload: string): HtmlToPdfGeneratedEvent {
    const event = new HtmlToPdfGeneratedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.pdfName = parsed.pdfName;
    event.pdfUrl = decodeURI(parsed.pdfUrl);
    event.successful = parsed.successful;
    event.changedBy = parsed.changedBy;
    return event;
  }
}
EventAdapterRegistry.set('HtmlToPdfGeneratedEvent', HtmlToPdfGeneratedEventAdapter.adapt);

export class EmailDeliverySentEventAdapter {
  static adapt(payload: string): EmailDeliverySentEvent {
    const event = new EmailDeliverySentEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.emailText = parsed.emailText;
    event.subjectText = parsed.subjectText;
    event.attachmentGuid = parsed.attachmentGuid;
    event.changedBy = parsed.changedBy;
    event.successful = parsed.successful;
    return event;
  }
}
EventAdapterRegistry.set('EmailDeliverySentEvent', EmailDeliverySentEventAdapter.adapt);

export class EmailDeliveryRecipientUpsertedEventAdapter {
  static adapt(payload: string): EmailDeliveryRecipientUpsertedEvent {
    const event = new EmailDeliveryRecipientUpsertedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.recipientGuid = parsed.recipientGuid;
    event.name = parsed.name;
    event.email = parsed.email;
    event.location = parsed.location;
    event.deleted = parsed.deleted;
    event.changedBy = parsed.changedBy;
    event.successful = parsed.successful;
    return event;
  }
}
EventAdapterRegistry.set('EmailDeliveryRecipientUpsertedEvent', EmailDeliveryRecipientUpsertedEventAdapter.adapt);

export class EcoModeSettingsUpdatedEventAdapter {
  static adapt(payload: string): EcoModeSettingsUpdatedEvent {
    const event = new EcoModeSettingsUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.ropewayEcoModeSettings = RopewayEcoModeSettingsAdapter.adapt([parsed.ropewayEcoModeSettings])[0];
    event.changedBy = parsed.changedBy;
    event.moduleUpdateSuccessful = parsed.moduleUpdateSuccessful;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('EcoModeSettingsUpdatedEvent', EcoModeSettingsUpdatedEventAdapter.adapt);

export class RopewaySettingsUpdatedEventAdapter {
  static adapt(payload: string): RopewaySettingsUpdatedEvent {
    const event = new RopewaySettingsUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.ropewaySettings = RopewaySettingsAdapter.adapt(parsed.ropewaySettings);
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('RopewaySettingsUpdatedEvent', RopewaySettingsUpdatedEventAdapter.adapt);

export class UserManagementUserUpdatedEventAdapter {
  static adapt(payload: string): UserManagementUserUpdatedEvent {
    const event = new UserManagementUserUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.user = UserAdapter.adapt([parsed.user])[0];
    event.managedTenants = parsed.managedTenants;
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    event.updateSuccessful = parsed.updateSuccessful;
    return event;
  }
}
EventAdapterRegistry.set('UserManagementUserUpdatedEvent', UserManagementUserUpdatedEventAdapter.adapt);

export class SambesiWhatsUpMessagesUpdatedEventAdapter {
  constructor() {}
  static adapt(payload: string): SambesiWhatsUpMessagesUpdatedEvent {
    const event = new SambesiWhatsUpMessagesUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.updateSuccessful = parsed.updateSuccessful;
    event.ropewaySisIds = parsed.ropewaySisIds;
    event.changedBy = parsed.changedBy;
    return event;
  }
}
EventAdapterRegistry.set('SambesiWhatsUpUpdatedEvent', SambesiWhatsUpMessagesUpdatedEventAdapter.adapt);

export class MarkerPositionUpdatedEventAdapter {
  static adapt(payload: string): MarkerPositionUpdatedEvent {
    const event = new MarkerPositionUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    event.markerGuid = parsed.markerGuid;
    event.successful = parsed.successful;
    return event;
  }
}
EventAdapterRegistry.set('MarkerPositionUpdatedEvent', MarkerPositionUpdatedEventAdapter.adapt);

export class TimetableRealTimeDataUpdatedEventAdapter {
  static adapt(payload: string): TimetableRealTimeDataUpdatedEvent {
    const event = new TimetableRealTimeDataUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.tenantGuid = parsed.tenantGuid;
    event.trackGuid = parsed.trackGuid;
    event.seasonGuid = parsed.seasonGuid;
    event.realTimeDatas = parsed.realTimeDatas;
    event.lastSuccessfulRealTimeImport = new Date(parsed.lastSuccessfulRealTimeImport);
    return event;
  }
}
EventAdapterRegistry.set('TimetableRealTimeDataUpdatedEvent', TimetableRealTimeDataUpdatedEventAdapter.adapt);

export class SlopesOperatingTimesUpdatedEventAdapter {
  static adapt(payload: string): SlopesOperatingTimesUpdatedEvent {
    const event = new SlopesOperatingTimesUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('SlopesOperatingTimesUpdatedEvent', SlopesOperatingTimesUpdatedEventAdapter.adapt);

export class WebLinkCollectionUpdatedEventAdapter {
  static adapt(payload: string): WebLinkCollectionUpdatedEvent {
    const event = new WebLinkCollectionUpdatedEvent();
    Object.assign(event, JSON.parse(payload));
    return event;
  }
}
EventAdapterRegistry.set('WebLinkCollectionUpdatedEvent', WebLinkCollectionUpdatedEventAdapter.adapt);

export class SisMapEditTokenUpdatedEventAdapter {
  static adapt(payload: string): SisMapEditTokenUpdatedEvent {
    const event = new SisMapEditTokenUpdatedEvent();
    Object.assign(event, JSON.parse(payload));
    return event;
  }
}
EventAdapterRegistry.set('SisMapEditTokenUpdatedEvent', SisMapEditTokenUpdatedEventAdapter.adapt);

export class TenantUpdatedEventAdapter {
  static adapt(payload: string): TenantUpdatedEvent {
    const event = new TenantUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    event.tenant = TenantAdapter.adapt([parsed.tenant])[0];
    return event;
  }
}
EventAdapterRegistry.set('TenantUpdatedEvent', TenantUpdatedEventAdapter.adapt);

export class FeatureUpdatedEventAdapter {
  static adapt(payload: string): FeatureUpdatedEvent {
    const event = new FeatureUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.changedBy = parsed.changedBy;
    event.tenantGuid = parsed.tenantGuid;
    event.updateSuccessful = parsed.updateSuccessful;
    event.features = FeatureManagementFeatureAdapter.adapt(parsed.features);
    return event;
  }
}
EventAdapterRegistry.set('FeatureUpdatedEvent', FeatureUpdatedEventAdapter.adapt);

export class RopewayAvailabilityTrendUpdatedEventAdapter {
  static adapt(payload: string): RopewayAvailabilityTrendUpdatedEvent {
    const event = new RopewayAvailabilityTrendUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('RopewayAvailabilityTrendUpdatedEvent', RopewayAvailabilityTrendUpdatedEventAdapter.adapt);

export class ClampingCountResetedEventAdapter {
  static adapt(payload: string): ClampingCountResetedEvent {
    const event = new ClampingCountResetedEvent();
    const parsed = JSON.parse(payload);
    event.changedBy = parsed.changedBy;
    event.ropewayGuid = parsed.ropewayGuid;
    event.tenantGuid = parsed.tenantGuid;
    event.resetSuccessful = parsed.resetSuccessful;
    event.email = parsed.email;
    event.lastReset = parsed.lastReset;
    event.vehicleId = parsed.vehicleId;
    event.totalValueOnReset = parsed.totalValueOnReset;
    return event;
  }
}
EventAdapterRegistry.set('ClampingCountResetedEvent', ClampingCountResetedEventAdapter.adapt);

export class ParkingStatusUpdatedEventAdapter {
  static adapt(payload: string): ParkingStatusUpdatedEvent {
    const event = new ParkingStatusUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    event.lastStatusChange = new Date(parsed.lastStatusChange);
    return event;
  }
}
EventAdapterRegistry.set('ParkingStatusUpdatedEvent', ParkingStatusUpdatedEventAdapter.adapt);

export class ParkingEditedEventAdapter {
  static adapt(payload: string): ParkingEditedEvent {
    const event = new ParkingEditedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('ParkingEditedEvent', ParkingEditedEventAdapter.adapt);

export class ParkingExportMappingUpdatedEventAdapter {
  static adapt(payload: string): ParkingExportMappingUpdatedEvent {
    const event = new ParkingExportMappingUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('ParkingExportMappingUpdatedEvent', ParkingExportMappingUpdatedEventAdapter.adapt);

export class ParkingCockpitOrderUpdatedEventAdapter {
  static adapt(payload: string): ParkingCockpitOrderUpdatedEvent {
    const event = new ParkingCockpitOrderUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('ParkingCockpitOrderUpdatedEvent', ParkingCockpitOrderUpdatedEventAdapter.adapt);

export class ParkingCurrentOccupancyUpdatedEventAdapter {
  static adapt(payload: string): ParkingCurrentOccupancyUpdatedEvent {
    const event = new ParkingCurrentOccupancyUpdatedEvent();
    const parsed = JSON.parse(payload);
    Object.assign(event, parsed);
    return event;
  }
}
EventAdapterRegistry.set('ParkingCurrentOccupancyUpdatedEvent', ParkingCurrentOccupancyUpdatedEventAdapter.adapt);

export class SisMediaSettingUpdatedEventAdapter {
  static adapt(payload: string): SisMediaSettingUpdatedEvent {
    const event = new SisMediaSettingUpdatedEvent();
    const parsed = JSON.parse(payload);
    event.changedBy = parsed.changedBy;
    event.updateSuccessful = parsed.updateSuccessful;
    event.sisMediaSetting = SisMediaSettingAdapter.adapt(parsed.sisMediaSetting);
    return event;
  }
}
EventAdapterRegistry.set('SisMediaSettingUpdatedEvent', SisMediaSettingUpdatedEventAdapter.adapt);
