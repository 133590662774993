<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ this.parking.label }} {{ this.parking.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <form *ngIf="this.formGroup" [formGroup]="this.formGroup">
    <!-- Big Screen -->
    <ion-grid class="sis-global-modal-grid" *ngIf="this.bigScreenMode$ | async">
      <ion-row
        class="vertical-align-content sis-global-row-50"
        [matTooltip]="this.parking.autoUpdate ? ('parking.phrase.autoUpdated' | translate) : null"
      >
        <ion-col size="9" class="sis-global-input sis-parking-input-label">
          {{ 'parking.term.currentOccupancy' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="number"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.currentOccupancy?.errors }"
            inputmode="text"
            min="0"
            formControlName="currentOccupancy"
            data-test="parking-occupancy-modal-current-input"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row-50">
        <ion-col size="9" class="sis-global-input sis-parking-input-label">
          {{ 'parking.term.occupancyOffset' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="number"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.occupancyOffset?.errors }"
            inputmode="text"
            formControlName="occupancyOffset"
            data-test="parking-occupancy-modal-offset-input"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row-50">
        <ion-col size="9" class="sis-global-input sis-parking-input-label">
          {{ 'parking.term.maxCapacity' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="number"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.maxCapacity?.errors }"
            inputmode="text"
            min="0"
            formControlName="maxCapacity"
            data-test="parking-occupancy-modal-max-input"
          ></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Small Screen -->
    <ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-global-modal-grid">
      <ion-row [matTooltip]="this.parking.autoUpdate ? ('parking.phrase.autoUpdated' | translate) : null">
        <ion-col>
          {{ 'parking.term.currentOccupancy' | translate }}
        </ion-col>
      </ion-row>
      <ion-row
        class="sis-global-row-60 vertical-align-content"
        [matTooltip]="this.parking.autoUpdate ? ('parking.phrase.autoUpdated' | translate) : null"
      >
        <ion-col class="sis-global-input">
          <ion-input
            type="number"
            class="sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.currentOccupancy?.errors }"
            inputmode="text"
            min="0"
            formControlName="currentOccupancy"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          {{ 'parking.term.occupancyOffset' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-60 vertical-align-content">
        <ion-col class="sis-global-input">
          <ion-input
            type="number"
            class="sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.occupancyOffset?.errors }"
            inputmode="text"
            formControlName="occupancyOffset"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          {{ 'parking.term.maxCapacity' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-60 vertical-align-content">
        <ion-col class="sis-global-input">
          <ion-input
            type="number"
            class="sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls.maxCapacity?.errors }"
            inputmode="text"
            min="0"
            formControlName="maxCapacity"
          ></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.hasUnsavedChanges || this.isSaving || this.formGroup.invalid"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="parking-occupancy-modal-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="parking-occupancy-modal-cancel-btn"
    ></sis-general-button>
  </div>
</ion-footer>
