import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingService } from 'src/app/parking/domain/parking.service';

@Component({
  selector: 'sis-parking',
  templateUrl: './parking.page.html',
  styleUrls: ['./parking.page.scss'],
})
export class ParkingPage extends Unsubscriber implements OnInit {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly isAdministrator$ = this.userSettingsService.userSettings$.pipe(map((settings) => settings.isAdministrator));
  readonly featureIds: FeatureId[][] = [[FeatureId.PARKING]];

  filterControl = new FormControl<string>('');
  featureId = [[FeatureId.PARKING]];

  displayedItems: Parking[];
  lastChange: { date: Date; name: string };

  anyItemHasInfo: boolean;

  hasEditPermission: boolean;
  reorderingEnabled: boolean;
  labelMaxLength: number;

  private readonly parkings$ = this.parkingService.parkings$.pipe(map((items) => items.sort(this.sortItems)));
  private readonly reorderEnabled$ = new BehaviorSubject<boolean>(false);
  private readonly editWriteFeature = new Feature(FeatureId.PARKING, FeatureAccessLevel.WRITE);

  private itemOrders: Map<string, number>;
  private parkings: Parking[];

  constructor(
    private parkingService: ParkingService,
    private logbookModalService: LogbookModalService,
    private userSettingsService: UserSettingsService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.parkings$, this.reorderEnabled$, this.filterControl.valueChanges.pipe(debounceTime(300), startWith<string>(''))])
      .pipe(
        map(([items, reorderEnabled, search]) =>
          reorderEnabled ? items : items.filter((item) => this.containsSearchStringInNameOrLabel(item, search))
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((items) => {
        this.displayedItems = items;
        this.updateLabelMaxLength(items.map((i) => i.label));
        this.anyItemHasInfo = this.containsItemsWithInfo(this.displayedItems);
      });

    this.parkings$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => {
      this.parkings = items;
      this.setLastChange(items);
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.hasEditPermission = features.some((f) => f.hasMinimumRequirementFor(this.editWriteFeature));
    });
  }

  private updateLabelMaxLength(labels: string[]) {
    this.labelMaxLength = Math.max(0, ...labels.filter((l) => !!l).map((l) => l.length));
  }

  async onReorderClick(): Promise<void> {
    if (this.reorderingEnabled) {
      const reorderedItems: Parking[] = this.parkings.filter((item) => this.itemOrders.get(item.guid) !== item.cockpitOrder);

      await this.parkingService.editParking(reorderedItems);
    } else {
      this.itemOrders = new Map<string, number>(this.parkings.map((item) => [item.guid, item.cockpitOrder]));
    }

    this.reorderEnabled$.next(!this.reorderingEnabled);
    this.reorderingEnabled = !this.reorderingEnabled;
  }

  async onReorderCancelClick(): Promise<void> {
    this.displayedItems.forEach((item) => (item.cockpitOrder = this.itemOrders.get(item.guid)));
    this.displayedItems.sort(this.sortItems);
    this.reorderEnabled$.next(false);
    this.reorderingEnabled = false;
  }

  doReorder(ev: any) {
    this.parkings = ev.detail.complete(this.displayedItems);
    this.parkings.forEach((item, index) => (item.cockpitOrder = index));
  }

  private setLastChange(items: Parking[]): void {
    if (!items || items.length === 0) {
      return null;
    }

    const lastChangedItem = items.reduce((p, c) => (c.lastStatusChange > p.lastStatusChange ? c : p));

    this.lastChange = { date: lastChangedItem.lastStatusChange, name: lastChangedItem.name };
  }

  private sortItems(a: Parking, b: Parking): number {
    const order = a.cockpitOrder - b.cockpitOrder;
    if (order !== 0) {
      return order;
    }

    if (a.label && b.label) {
      const label = a.label.localeCompare(b.label);
      if (label !== 0) {
        return label;
      }
    }

    return a.name.localeCompare(b.name);
  }

  private containsItemsWithInfo(items: Parking[]): boolean {
    return items.some((item) => item.info?.length > 0);
  }

  private containsSearchStringInNameOrLabel(item: Parking, search: string): boolean {
    const searchString = search ? search.toLowerCase() : undefined;
    if (searchString != null) {
      return item.name.toLowerCase().includes(searchString) || item.label?.toLowerCase()?.includes(searchString);
    }

    return true;
  }
}
