import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { catchError, map, Observable, of, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { WebLinkItemCategory } from 'src/app/weblink-collection/domain/weblink-item-category.enum';
import { WebLinkCollectionService } from 'src/app/weblink-collection/weblink-collection.service';
import { NIL as emptyGuid } from 'uuid';

@Component({
  templateUrl: './weblink-edit-modal.component.html',
  styleUrls: ['./weblink-edit-modal.component.scss'],
})
export class WeblinkEditModalComponent extends Unsubscriber implements OnInit {
  @Input() webLinkItem: WebLink;
  @Input() writePermission: boolean;

  icons: string[] = [
    'link-outline',
    'code-slash-outline',
    'desktop-outline',
    'document-text-outline',
    'enter-outline',
    'map-outline',
    'partly-sunny-outline',
    'stats-chart-outline',
    'warning-outline',
  ];

  ioniconsUrl = 'https://ionic.io/ionicons';
  isNewItem: boolean;
  isExistingPublicItem: boolean;
  webLinkItemEdit: WebLink;
  webLinkFormGroup: FormGroup;
  bigScreenMode: boolean;
  showExtraIcon: boolean;
  isSaving = false;
  userGuid: string;
  tenantAlias: string;
  enumWebLinkCategoryItem = WebLinkItemCategory;

  private initialCategory: WebLinkItemCategory;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private webLinkCollectionService: WebLinkCollectionService,
    private confirmationDialogService: ConfirmationDialogService,
    private http: HttpClient,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.webLinkItem) {
      this.webLinkItem = {
        guid: emptyGuid,
        iconName: 'link-outline',
        category: this.writePermission ? WebLinkItemCategory.tenant : WebLinkItemCategory.private,
      };
      this.isNewItem = true;
    } else {
      this.isExistingPublicItem = this.webLinkItem.category === WebLinkItemCategory.tenant;
    }

    const copy = JSON.parse(JSON.stringify(this.webLinkItem));
    this.webLinkItemEdit = copy;

    if (this.icons.includes(this.webLinkItemEdit.iconName)) {
      this.webLinkItemEdit.extraIcon = '';
      this.showExtraIcon = false;
    } else {
      this.webLinkItemEdit.extraIcon = this.webLinkItemEdit.iconName;
      this.webLinkItemEdit.iconName = 'link-outline';
      this.showExtraIcon = true;
    }
    this.initialCategory = this.webLinkItemEdit.category;

    this.webLinkFormGroup = new FormGroup({
      category: new FormControl(
        {
          value: this.webLinkItemEdit.category,
          disabled: !this.isNewItem && this.webLinkItemEdit.category === WebLinkItemCategory.tenant,
        },
        Validators.required
      ),
      extraIcon: new FormControl(this.webLinkItemEdit.extraIcon, [], (c) => this.ionIconValidator(c)),
      url: new FormControl(this.webLinkItemEdit.url, [
        Validators.required,
        Validators.pattern(/^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-./?%&=+]*)?(#.*)?$/),
      ]),
      description: new FormControl(this.webLinkItemEdit.description, Validators.required),
    });

    this.webLinkFormGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.webLinkItemEdit.category = value.category;
      this.webLinkItemEdit.extraIcon = value.extraIcon;
      this.webLinkItemEdit.url = value.url;
      this.webLinkItemEdit.description = value.description;
      this.showExtraIcon = value.extraIcon !== '';
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.userSettingsService.userSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((userSettings) => {
      this.userGuid = userSettings.userGuid;
    });

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedTenant) => {
      this.tenantAlias = selectedTenant?.alias;
    });
  }

  ionIconValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    const iconValue = control.value;
    const url = `${window.location.origin}/svg/${iconValue}.svg`;
    if (iconValue === '') {
      return of(null);
    }

    return this.http.get(url, { responseType: 'text' }).pipe(
      map((res) => {
        //if svg not valid whole index html is sent by webserver
        if (res.startsWith('<svg')) {
          return null;
        }
        throw new Error('Invalid Ion Icon');
      }),
      catchError(() => of({ invalidIonIcon: true }))
    );
  }

  async save(): Promise<void> {
    this.isSaving = true;

    this.webLinkItemEdit.category = this.webLinkItemEdit.category || WebLinkItemCategory.tenant;
    this.webLinkItemEdit.displayOrder =
      this.webLinkItemEdit.category === this.initialCategory && !this.isNewItem
        ? this.webLinkItemEdit.displayOrder
        : 999;
    this.webLinkItemEdit.iconName = this.webLinkItemEdit.extraIcon || this.webLinkItemEdit.iconName;
    this.webLinkItemEdit.ownerGuid =
      this.webLinkItemEdit.category === WebLinkItemCategory.private ? this.userGuid : null;

    const postData: WebLink = {
      guid: this.webLinkItemEdit.guid,
      displayOrder: this.webLinkItemEdit.displayOrder,
      category: this.webLinkItemEdit.category,
      iconName: this.webLinkItemEdit.iconName,
      url: this.webLinkItemEdit.url,
      description: this.webLinkItemEdit.description,
      ownerGuid: this.webLinkItemEdit.ownerGuid,
    };

    this.webLinkFormGroup.get('category').disable();
    this.webLinkFormGroup.get('extraIcon').disable();
    this.webLinkFormGroup.get('url').disable();
    this.webLinkFormGroup.get('description').disable();

    if (this.isNewItem) {
      await this.webLinkCollectionService.insertOrUpdateItems([postData], 'insert');
    } else {
      await this.webLinkCollectionService.insertOrUpdateItems([postData], 'update');
    }
    this.modalCtrl.dismiss();
  }

  async delete(): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert(
      'weblinkcollection.phrase.deleteWeblink',
      'general.term.delete'
    );

    if (confirmed) {
      const postData: WebLink = {
        guid: this.webLinkItemEdit.guid,
      };
      await this.webLinkCollectionService.insertOrUpdateItems([postData], 'delete');
      this.modalCtrl.dismiss();
    }
  }

  cancel(): void {
    this.modalCtrl.dismiss();
  }

  selectIcon(icon: string): void {
    this.webLinkItemEdit.iconName = icon;
    this.webLinkFormGroup.get('extraIcon').patchValue('');
    this.showExtraIcon = false;
  }

  openMoreIcons(): void {
    window.open(this.ioniconsUrl, '_blank');
  }
}
