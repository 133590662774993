<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ this.titleTranslationString | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <form *ngIf="this.formGroup" [formGroup]="this.formGroup">
    <!-- Big Screen -->
    <ion-grid class="sis-global-modal-grid" *ngIf="this.bigScreenMode$ | async">
      <ion-row class="vertical-align-content sis-global-row-50">
        <ion-col size="3" class="sis-global-input sis-sismedia-input-label">
          {{ 'sismediaEdit.term.season' | translate }}
        </ion-col>
        <ion-col
          size="5"
          class="sis-global-input"
          style="padding-left: 10px; margin: 5px 0px"
          [ngClass]="{ 'sis-illegal-value': this.formGroup.controls?.season?.errors }"
          ><ion-icon
            class="sis-icon"
            [name]="this.writePermission ? this.seasonIcon.iconName : this.seasonIcon.iconNameDisabled"
            [matTooltip]="this.seasonIcon.translateKey | translate"
          ></ion-icon>
          <ion-select
            style="min-width: 240px"
            interface="popover"
            [interfaceOptions]="{
            cssClass: 'sis-global-popover sis-global-noscrollbar sis-sismedia-input-select',
            size: 'cover',
          }"
            formControlName="season"
            data-test="sismedia-asset-edit-season-select"
          >
            <ion-select-option [value]="0">{{ 'sismediaEdit.term.summerAndWinter' | translate }}</ion-select-option>
            <ion-select-option [value]="1">{{ 'sismediaEdit.term.summer' | translate }}</ion-select-option>
            <ion-select-option [value]="2">{{ 'sismediaEdit.term.winter' | translate }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-global-row-50">
        <ion-col size="3" class="sis-global-input sis-sismedia-input-label">
          {{ 'sismediaEdit.term.type' | translate }}
        </ion-col>
        <ion-col
          size="5"
          class="sis-global-input"
          style="padding-left: 10px; margin: 5px 0px"
          [ngClass]="{ 'sis-illegal-value': this.formGroup.controls?.type?.errors }"
        >
          <ion-icon class="sis-icon" [src]="this.typeIcon" />
          <ion-select
            style="min-width: 240px"
            interface="popover"
            [interfaceOptions]="{
              cssClass: 'sis-global-popover sis-global-noscrollbar sis-sismedia-input-select',
              size: 'cover',
            }"
            formControlName="type"
            data-test="sismedia-asset-edit-type-select"
          >
            <ion-select-option *ngFor="let type of this.sisMediaAssetTypes$ | async" [value]="type.name">{{
              type.description
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ion-row
        *ngFor="let fieldOptions of this.inputFieldOptions"
        class="vertical-align-content"
        [ngClass]="
          fieldOptions.inputType === 'textarea' ? 'sis-global-row sis-sismedia-info-input-row' : 'sis-global-row-50'
        "
      >
        <ion-col size="3" class="sis-global-input sis-sismedia-input-label">
          {{ fieldOptions.inputLabelTranslateKey | translate }}
        </ion-col>

        <ion-col>
          <ion-input
            *ngIf="fieldOptions.inputType === 'text'"
            type="text"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'time'"
            type="time"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="time"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            sis-integer-input
            *ngIf="fieldOptions.inputType === 'number'"
            type="text"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'tel'"
            type="tel"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="tel"
            placeholder="+41 41 123 45 67"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'email'"
            type="email"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="email"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'website'"
            type="url"
            class="sis-global-input sis-global-text-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="url"
            placeholder="https://example.ch"
            pattern="https://.*"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-textarea
            *ngIf="fieldOptions.inputType === 'textarea'"
            type="text"
            class="sis-global-textarea-no-scrollbar sis-global-textarea sis-global-input"
            [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            rows="3"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-textarea>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-global-row-50">
        <ion-col size="3" class="sis-global-input sis-sismedia-input-label">
          {{ 'sismedia.term.status' | translate }}
        </ion-col>
        <ion-col>
          <div
            [matTooltip]="
              (this.hasMySqlRouter$ | async) && !this.isNewItem
                ? ('sismediaEdit.phrase.mysqlAvailable' | translate)
                : null
            "
          >
            <ion-checkbox
              color="primary"
              class="sis-checkbox-input"
              [checked]="this.hasStatus"
              formControlName="hasStatus"
              data-test="sismedia-edit-status-checkbox"
            ></ion-checkbox>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-global-row-50 ion-no-padding" style="color: grey">
        <ion-col size="3">ID</ion-col>
        <ion-col>
          {{ this.sisMediaItem.id }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row-50 ion-no-padding" style="color: grey">
        <ion-col size="3">Guid</ion-col>
        <ion-col>
          <span (click)="this.copyToClipboard(this.sisMediaItem.assetGuid)" style="cursor: copy">
            {{ this.sisMediaItem.assetGuid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Small Screen -->
    <ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-global-modal-grid">
      <ion-row>
        <ion-col>
          {{ 'sismediaEdit.term.season' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row-60">
        <ion-col
          class="sis-global-input"
          [ngClass]="{ 'sis-illegal-value': this.formGroup.controls?.season?.errors }"
          style="padding-left: 5px"
        >
          <ion-icon
            class="sis-icon"
            [name]="this.writePermission ? this.seasonIcon.iconName : this.seasonIcon.iconNameDisabled"
            [matTooltip]="this.seasonIcon.translateKey | translate"
          ></ion-icon>
          <ion-select
            style="min-width: 90%"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover sis-global-noscrollbar', size: 'cover' }"
            data-test="sismedia-asset-edit-season-select"
            formControlName="season"
          >
            <ion-select-option [value]="0">{{ 'sismediaEdit.term.summerAndWinter' | translate }}</ion-select-option>
            <ion-select-option [value]="1">{{ 'sismediaEdit.term.summer' | translate }}</ion-select-option>
            <ion-select-option [value]="2">{{ 'sismediaEdit.term.winter' | translate }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          {{ 'sismediaEdit.term.type' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row-60">
        <ion-col
          class="sis-global-input"
          [ngClass]="{ 'sis-illegal-value': this.formGroup.controls?.type?.errors }"
          style="padding-left: 5px"
        >
          <ion-icon class="sis-icon" [src]="this.typeIcon" />
          <ion-select
            style="min-width: 90%"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover sis-global-noscrollbar', size: 'cover' }"
            data-test="sismedia-asset-edit-type-select"
            formControlName="type"
          >
            <ion-select-option *ngFor="let type of this.sisMediaAssetTypes$ | async" [value]="type.name">{{
              type.description
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ng-container *ngFor="let fieldOptions of this.inputFieldOptions">
        <ion-row>
          <ion-col>
            {{ fieldOptions.inputLabelTranslateKey | translate }}
          </ion-col>
        </ion-row>
        <ion-row class="vertical-align-content">
          <ion-col [ngClass]="fieldOptions.inputType === 'textarea' ? 'sis-col-small-textarea' : 'sis-col-small'">
            <ion-input
              *ngIf="fieldOptions.inputType === 'text'"
              type="text"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'time'"
              type="time"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="time"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              sis-integer-input
              *ngIf="fieldOptions.inputType === 'number'"
              type="text"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'tel'"
              type="tel"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="tel"
              placeholder="+41 41 123 45 67"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'email'"
              type="email"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="email"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'website'"
              type="url"
              class="sis-global-input sis-global-text-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              inputmode="url"
              placeholder="https://example.ch"
              pattern="https://.*"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-textarea
              *ngIf="fieldOptions.inputType === 'textarea'"
              type="text"
              class="sis-textarea-input"
              [ngClass]="{ 'sis-illegal-value': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
              style="max-height: 101px; margin-top: 0px"
              rows="3"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ng-container>
      <ion-row style="margin-bottom: -10px">
        <ion-col>
          {{ 'sismedia.term.status' | translate }}
        </ion-col>
        <ion-col>
          <div
            [matTooltip]="
              (this.hasMySqlRouter$ | async) && !this.isNewItem
                ? ('sismediaEdit.phrase.mysqlAvailable' | translate)
                : null
            "
          >
            <ion-checkbox
              color="primary"
              class="sis-checkbox-input"
              [checked]="this.hasStatus"
              data-test="sismedia-edit-status-checkbox"
              formControlName="hasStatus"
            ></ion-checkbox>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-global-row-50 ion-no-padding" style="color: grey">
        <ion-col size="3">ID</ion-col>
        <ion-col>
          {{ this.sisMediaItem.id }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-global-row ion-no-padding" style="color: grey">
        <ion-col size="3">Guid</ion-col>
        <ion-col>
          <span (click)="this.copyToClipboard(this.sisMediaItem.assetGuid)" style="cursor: copy">
            {{ this.sisMediaItem.assetGuid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.hasUnsavedChanges || this.isSaving || this.formGroup.invalid"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="sismedia-asset-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="sismedia-asset-edit-cancel-btn"
    ></sis-general-button>
    <sis-general-button
      *ngIf="!this.isNewItem"
      [text]="'general.term.delete'"
      [btnType]="'Red'"
      [btnAlign]="'Left'"
      (sisClick)="this.delete()"
      [disabled]="this.isSaving || !this.deleteAllowed"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="sismedia-asset-edit-delete-btn"
    ></sis-general-button>
  </div>
</ion-footer>
