<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <ion-row class="sis-progress-bar-row">
    <ion-col>
      <ion-progress-bar
        class="sis-progress-bar"
        [value]="this.percent"
        [color]="this.ecoModeEnabledCockpit && !this.outdated ? '' : 'medium'"
      ></ion-progress-bar>
      <ion-label class="sis-progress-bar-label sis-progress-bar-label-0">0%</ion-label>
      <ion-label class="sis-progress-bar-label sis-progress-bar-label-100">100%</ion-label>
      <ion-label
        *ngIf="!this.outdated"
        class="sis-progress-bar-label sis-progress-bar-label-actual-value"
        [ngStyle]="{ left: 'calc((80% * ' + this.percent + ') + 10% - 33px)' }"
        >{{ this.currentPeopleFrequencyText }}</ion-label
      >
      <ion-label
        *ngIf="!this.outdated"
        class="sis-progress-bar-label"
        [ngStyle]="{ left: 'calc((80% * ' + this.percent + ') + 10% - 8px)' }"
        >{{ this.percentText }}</ion-label
      >
      <div
        class="sis-progress-bar-threshold"
        [ngStyle]="{ left: 'calc((80% * ' + this.thresholdLowPercent + ') + 10%)' }"
      ></div>
      <div
        class="sis-progress-bar-threshold"
        [ngStyle]="{ left: 'calc((80% * ' + this.thresholdMediumPercent + ') + 10%)' }"
      ></div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-grid class="sis-threshold-grid">
        <ion-row
          class="sis-threshold-row"
          [style.background]="
            this.activeThreshold === 0 && !this.outdated
              ? this.ecoModeEnabledCockpit
                ? 'var(--ion-color-primary)'
                : '#e0e0e0'
              : 'white'
          "
        >
          <ion-col class="sis-threshold-text-col">
            {{ 'ecomode.term.empty' | translate }}
          </ion-col>
          <ion-col> {{ this.speedEmpty }} m/s </ion-col>
        </ion-row>
        <ion-row
          *ngIf="this.thresholdLow !== 0"
          class="sis-threshold-row"
          [style.background]="
            this.activeThreshold === 1 && !this.outdated
              ? this.ecoModeEnabledCockpit
                ? 'var(--ion-color-primary)'
                : '#e0e0e0'
              : 'white'
          "
        >
          <ion-col class="sis-threshold-text-col">
            {{ this.thresholdLowText }}
          </ion-col>
          <ion-col> {{ this.speedLow }} m/s </ion-col>
        </ion-row>
        <ion-row
          *ngIf="this.thresholdLow !== this.thresholdMedium"
          class="sis-threshold-row"
          [style.background]="
            this.activeThreshold === 2 && !this.outdated
              ? this.ecoModeEnabledCockpit
                ? 'var(--ion-color-primary)'
                : '#e0e0e0'
              : 'white'
          "
        >
          <ion-col class="sis-threshold-text-col">
            {{ this.thresholdMediumText }}
          </ion-col>
          <ion-col> {{ this.speedMedium }} m/s </ion-col>
        </ion-row>
        <ion-row
          *ngIf="this.thresholdMedium !== 100"
          class="sis-threshold-row"
          [style.background]="
            this.activeThreshold === 3 && !this.outdated
              ? this.ecoModeEnabledCockpit
                ? 'var(--ion-color-primary)'
                : '#e0e0e0'
              : 'white'
          "
        >
          <ion-col class="sis-threshold-text-col">
            {{ this.thresholdHighText }}
          </ion-col>
          <ion-col> {{ this.speedHigh }} m/s </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-grid style="margin-left: 20px">
        <ion-row class="sis-eco-mode-onoff-row">
          <ion-col size="2">
            <div (click)="this.toggleEcoMode($event)" style="cursor: pointer">
              <ion-toggle
                class="sis-ion-toggle"
                [checked]="this.ecoModeEnabledCockpit"
                [disabled]="!(this.hasWritePermission$ | async)"
                [enableOnOffLabels]="true"
                mode="md"
              ></ion-toggle>
            </div>
          </ion-col>
          <ion-col>
            {{ 'ecomode.phrase.onOff' | translate }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-eco-mode-disabled-text-col">
            {{ this.ecoModePLCDisabledText | translate }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
<div class="sis-bottom-buttons">
  <sis-logbook-button
    *ngIf="this.logbookAvailable$ | async"
    class="sis-logbook-button"
    [showText]="false"
    (click)="openLogbook()"
  >
  </sis-logbook-button>
  <ion-chip
    class="sis-settings-button"
    color="secondary"
    [disabled]="!(this.hasWritePermission$ | async)"
    (click)="openEcoModeSettings()"
  >
    <ion-label>{{ 'general.term.settings' | translate }}</ion-label>
  </ion-chip>
</div>
