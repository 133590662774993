<ion-header translucent data-test="sis-operatinghour-editor">
  <ion-toolbar>
    <ion-title data-test="sis-operatinghour-title">
      <ion-label> {{ this.screen.location }}</ion-label>
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-global-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row class="sis-global-row-60 vertical-align-content" data-test="sis-operatinghour-continuousOperation-row">
        <ion-col [size]="(this.bigScreenMode$ | async) ? 3.5 : 4.5">
          {{ 'mediacenter.term.operatingThroughout' | translate }}:
        </ion-col>
        <ion-col>
          <ion-checkbox
            formControlName="continuousOperation"
            labelPlacement="start"
            (ionChange)="this.continuousOperationChanged($event)"
            data-test="sis-operatinghour-continuousOperation-checkbox"
          ></ion-checkbox>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-60 vertical-align-content ion-nowrap">
        <ion-col [size]="(this.bigScreenMode$ | async) ? 3.5 : 4.5"
          >{{ 'mediacenter.term.operatinghour' | translate }}:
        </ion-col>
        <ion-col>
          <ion-input
            type="time"
            class="sis-global-input"
            formControlName="startTime"
            data-test="sis-operatinghour-starttime-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.startTime.errors }"
          ></ion-input>
        </ion-col>
        <ion-col size="auto" style="padding-left: 15px; padding-right: 15px">bis</ion-col>
        <ion-col>
          <ion-input
            type="time"
            class="sis-global-input"
            formControlName="endTime"
            data-test="sis-operatinghour-endtime-input"
            [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.endTime.errors }"
          ></ion-input>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-global-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [disabled]="!this.formGroup.dirty || this.formGroup.invalid"
      [btnType]="'Secondary'"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      (sisClick)="this.save()"
      data-test="sis-screeneditor-save-btn"
    >
    </sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      (sisClick)="this.close()"
    >
    </sis-general-button>
  </div>
</ion-footer>
