<ion-row *ngIf="this.journey && this.journey.guid != null" class="sis-timetable-journey-row" data-test="timetable-journey-row">
  <ion-grid class="sis-timetable-no-scroll ion-no-padding">
    <ion-row class="sis-timetable-content-row">
      <ion-col size="9" class="sis-timetable-padding-left sis-timetable-header-mobile">{{ 'timetable.term.vehicleNumberAbbr' | translate }}</ion-col>
      <ion-col size="3" class="sis-timetable-padding-left"> {{ journey.vehicleNumber ?? '-' }} </ion-col>
    </ion-row>

    <ion-row *ngIf="stations.length <= 2" class="sis-timetable-content-row">
      <ion-col size="9" class="sis-timetable-padding-left sis-timetable-header-mobile">{{ 'timetable.term.direction' | translate }}</ion-col>
      <ion-col *ngIf="journey.direction == 0" size="3" class="sis-timetable-padding-left sis-timetable-direction-arrow-col">
        <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.both' | translate }}">
          <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon
          ><ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
        </a>
      </ion-col>
      <ion-col *ngIf="journey.direction == 1" size="6" class="sis-timetable-padding-left sis-timetable-direction-arrow-col">
        <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.up' | translate }}">
          <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
        </a>
      </ion-col>
      <ion-col *ngIf="journey.direction == 2" size="6" class="sis-timetable-padding-left sis-timetable-direction-arrow-col">
        <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.down' | translate }}">
          <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
        </a>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="this.journey.direction == 0" class="sis-timetable-content-row">
      <ion-col size="9" class="sis-timetable-padding-left"> {{ this.stations[0].name }} / {{ this.stations[1].name }} </ion-col>
      <ion-col size="3" class="sis-timetable-padding-left">
        <sis-timetable-time-item [timetableItem]="stationTimetableItemMapping.get(this.stations[0].guid)"></sis-timetable-time-item>
      </ion-col>
    </ion-row>
    <ng-containter *ngIf="this.journey.direction != 0">
      <ion-row *ngFor="let station of this.stations" class="sis-timetable-content-row">
        <ion-col size="9" class="sis-timetable-padding-left">{{ station.name }}</ion-col>
        <ion-col size="3" class="sis-timetable-padding-left">
          <sis-timetable-time-item [timetableItem]="stationTimetableItemMapping.get(station.guid)"></sis-timetable-time-item>
        </ion-col>
      </ion-row>
    </ng-containter>
  </ion-grid>
</ion-row>

<ion-row *ngIf="this.journey && this.journey.guid == null" class="sis-timetable-day-separator">
  <ion-col>
    <div class="sis-timetable-day-separator-div-1">
      <div class="sis-timetable-day-separator-div-2-mobile">
        <span class="sis-timetable-day-separator-span-mobile"
          ><b>{{ 'timetable.term.tomorrow' | translate }}</b></span
        >
      </div>
    </div>
  </ion-col>
</ion-row>
