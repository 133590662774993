import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingService } from 'src/app/parking/domain/parking.service';

@Component({
  selector: 'sis-parking-occupancy-modal',
  templateUrl: './parking-occupancy-modal.component.html',
  styleUrls: ['./parking-occupancy-modal.component.scss'],
})
export class ParkingOccupancyModalComponent extends Unsubscriber implements OnInit {
  @Input() parking: Parking;

  editedParking: Parking;

  writePermission = false;
  isSaving = false;
  hasUnsavedChanges = false;

  formGroup: FormGroup;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(
    private modalCtrl: ModalController,
    private parkingService: ParkingService,
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.editedParking = JSON.parse(JSON.stringify(this.parking));

    this.formGroup = new FormGroup({
      currentOccupancy: new FormControl<number>(this.editedParking.currentOccupancy, [Validators.required]),
      occupancyOffset: new FormControl<number>(this.editedParking.occupancyOffset, [Validators.required]),
      maxCapacity: new FormControl<number>(this.editedParking.maxCapacity, [Validators.required]),
    });

    this.setValues();

    this.formGroup.valueChanges.pipe(debounceTime(50), takeUntil(this.onDestroy$)).subscribe((value) => {
      if (value.currentOccupancy != null) {
        this.editedParking.currentOccupancy = Number.parseInt(value.currentOccupancy);
      }
      this.editedParking.occupancyOffset = Number.parseInt(value.occupancyOffset);
      this.editedParking.maxCapacity = Number.parseInt(value.maxCapacity);

      this.checkForChanges();
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.PARKING, FeatureAccessLevel.WRITE)));
      this.setDisabled();
    });
  }

  async save(): Promise<void> {
    if (this.formGroup.invalid || !this.hasUnsavedChanges) {
      return;
    }

    this.isSaving = true;

    this.setDisabled();

    await this.parkingService.editParking([this.editedParking]);

    await this.modalCtrl.dismiss();
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  private checkForChanges(): void {
    this.hasUnsavedChanges = JSON.stringify(this.parking) !== JSON.stringify(this.editedParking);
  }

  private setValues(): void {
    this.formGroup.controls.currentOccupancy.setValue(this.editedParking.currentOccupancy);
    this.formGroup.controls.occupancyOffset.setValue(this.editedParking.occupancyOffset);
    this.formGroup.controls.maxCapacity.setValue(this.editedParking.maxCapacity);
  }

  private setDisabled(): void {
    if (!this.writePermission || this.isSaving) {
      this.formGroup.controls.currentOccupancy.disable();
      this.formGroup.controls.occupancyOffset.disable();
      this.formGroup.controls.maxCapacity.disable();
    } else {
      if (this.parking.autoUpdate) {
        this.formGroup.controls.currentOccupancy.disable();
      } else {
        this.formGroup.controls.currentOccupancy.enable();
      }
      this.formGroup.controls.occupancyOffset.enable();
      this.formGroup.controls.maxCapacity.enable();
    }
  }
}
