<span *ngIf="this.displayNumber && (this.activeAlarmCount || this.showIfNoAlarm)" [ngClass]="this.textClass">{{
  this.activeAlarmCount
}}</span>
<ion-icon
  class="sis-alarm-icon"
  [ngStyle]="{ 'font-size.px': iconSize }"
  [ngClass]="this.alarmClass"
  name="warning"
  *ngIf="this.activeAlarmCount || this.showIfNoAlarm"
></ion-icon>
