<!-- Big Screen -->
<ion-row *ngIf="this.bigScreenMode && this.item" class="sis-ion-row vertical-align-content sis-global-hover">
  <ion-col size="auto" class="ion-align-items-center" style="margin-right: 10px">
    <ion-icon class="sis-icon" [src]="this.icon" />
  </ion-col>
  <ion-col
    size="auto"
    style="min-width: 150px; max-width: 150px"
    class="ion-align-items-center"
    *ngIf="!this.isStatusUpdating"
  >
    <ion-icon
      *ngFor="let options of this.item.iconOptions"
      [name]="options.iconName"
      class="sis-statusicon"
      [ngClass]="options.activeClasses"
      (click)="this.iconClick(options.parkingStatus)"
      [matTooltip]="this.item.autoStatus ? ('parking.term.autoStatus' | translate) : ''"
      [attr.data-test]="options.dataTestString"
    ></ion-icon>
  </ion-col>
  <ion-col size="auto" style="min-width: 150px; max-width: 150px" *ngIf="this.isStatusUpdating">
    <ion-spinner *ngFor="let options of this.item.iconOptions" class="sis-spinner" name="dots"></ion-spinner>
  </ion-col>
  <ion-col
    *ngIf="this.item.maxCapacity != null"
    size="auto"
    style="min-width: 100px; max-width: 100px"
    [ngStyle]="{ cursor: this.writePermission ? 'pointer' : 'default' }"
    [matTooltip]="this.currentOccupancyTooltipText"
    (click)="this.openOccupancyEditModal()"
    data-test="parking-occupancy"
  >
    <span>{{ this.item.currentOccupancy }} / {{ this.item.maxCapacity - this.item.occupancyOffset }}</span>
  </ion-col>
  <ion-col *ngIf="this.item.maxCapacity == null" size="auto" style="min-width: 100px; max-width: 100px"></ion-col>
  <ion-col class="sis-global-text-overflow-ellipsis" style="padding-left: 0px">
    <ion-grid style="padding: 0; width: 100%">
      <ion-row style="padding: 0">
        <ion-col
          size="auto"
          class="sis-label-col"
          [style.maxWidth]="this.showingLabelLength"
          [style.minWidth]="this.showingLabelLength"
          data-test="sis-parking-item-label"
        >
          {{ this.item.label }}
        </ion-col>
        <ion-col class="sis-global-text-overflow-ellipsis" data-test="sis-parking-item-name">
          {{ this.item.name }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
  <ion-col *ngIf="this.showInfoCol && this.showButtons" size="auto" class="sis-icon-col">
    <ion-icon *ngIf="this.item.info" name="information-circle-outline" matTooltip="{{ this.item.info }}"> </ion-icon>
  </ion-col>
  <ion-col
    *ngIf="(this.hasExports || this.hasExportsEditPermission) && this.showButtons"
    size="auto"
    class="sis-icon-col"
  >
    <ion-icon
      name="share-outline"
      matTooltip="{{ ('parking.phrase.exportTooltipTitle' | translate) + this.exportTooltipText }}"
      matTooltipClass="sis-global-tooltip"
      [matTooltipDisabled]="!this.hasExports"
      [style.cursor]="this.hasExportsEditPermission ? 'pointer' : 'default'"
      [style.color]="this.hasExports ? null : 'rgb(0, 0, 0, 0.3)'"
      (click)="this.openExportsEditModal()"
      data-test="parking-export"
    >
    </ion-icon>
  </ion-col>

  <ion-col *ngIf="this.writePermission && this.showButtons" size="auto" class="sis-icon-col">
    <ion-icon
      name="create-outline"
      class="sis-global-pointer-cursor"
      style="margin-bottom: 2px"
      (click)="this.openEditModal()"
      matTooltip="{{ 'general.term.edit' | translate }}"
      data-test="parking-edit"
    >
    </ion-icon>
  </ion-col>
</ion-row>

<!-- Small Screen -->
<ion-row *ngIf="!this.bigScreenMode" class="sis-ion-row-small vertical-align-content" (press)="this.openEditModal()">
  <ion-col size="auto" class="ion-align-items-center">
    <ion-icon class="sis-icon-small" [src]="this.icon" />
  </ion-col>
  <ion-col size="auto" class="ion-align-items-center" *ngIf="!this.isStatusUpdating">
    <ion-icon
      *ngFor="let options of this.item.iconOptions"
      [name]="options.iconName"
      class="sis-statusicon-small"
      [ngClass]="getStatusIconClasses(this.item, options)"
      (click)="this.iconClick(options.parkingStatus)"
      [matTooltip]="this.item.autoStatus ? ('parking.term.autoStatus' | translate) : ''"
      [attr.data-test]="options.dataTestString"
    ></ion-icon>
  </ion-col>
  <ion-col size="auto" *ngIf="this.isStatusUpdating">
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
  </ion-col>
  <ion-col
    *ngIf="this.item.maxCapacity != null"
    size="auto"
    style="min-width: 68px; max-width: 68px"
    [ngStyle]="{ cursor: this.writePermission ? 'pointer' : 'default' }"
    [matTooltip]="this.currentOccupancyTooltipText"
    (click)="this.openOccupancyEditModal()"
  >
    <span>{{ this.item.currentOccupancy }} / {{ this.item.maxCapacity - this.item.occupancyOffset }}</span>
  </ion-col>
  <ion-col *ngIf="this.item.maxCapacity == null" size="auto" style="min-width: 68px; max-width: 68px"></ion-col>
  <ion-col class="sis-global-text-overflow-ellipsis">
    <ion-grid style="padding: 0; width: 100%">
      <ion-row style="padding: 0" (click)="this.openEditModal()">
        <ion-col
          class="sis-label-col-small sis-global-text-overflow-ellipsis"
          size="auto"
          [style.maxWidth]="this.showingLabelLength"
          [style.minWidth]="this.showingLabelLength"
        >
          {{ this.item.label }}
        </ion-col>
        <ion-col class="sis-global-text-overflow-ellipsis">
          {{ this.item.name }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
</ion-row>
