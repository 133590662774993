import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { MediaCenterInfoBannerType } from 'src/app/media-center/domain/mediacenter-configuration.model';
import { MediaCenterService } from 'src/app/media-center/media-center.service';
import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';
import { InfoBannerTextCategory } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner-textcategory.enum';
import { Position } from 'src/app/media-center/screens/infobanner-modal/domain/position.enum';
import { InfoBannerService } from 'src/app/media-center/screens/infobanner-modal/infobanner.service';
import { v4 as v4guid } from 'uuid';

@Component({
  templateUrl: './infobanner-text.component.html',
  styleUrls: ['./infobanner-text.component.scss', '../../../media-center-global.scss'],
})
export class InfoBannerTextComponent extends Unsubscriber implements OnInit {
  btnIsDisabled = false;
  fullscreenMode: boolean;
  infoBannerTextSettingsForm: FormGroup;
  editedInfoBanner: InfoBanner;
  editorType: number = undefined;
  textCategories: Array<{ translateKey: string; id: number }>;
  languageTextControls: Array<{ translateKey: string; formControlName: string; textCounter: string }>;
  editor: any;
  ckEditorData: string = '';

  private maxLengthValidatorFn: ValidatorFn;
  private infoBannerMaxCharacters: number;

  @Input() screenLocation: string;
  @Input() screenWidth: number;
  @Input() screenHeight: number;
  @Input() infoBanner: InfoBanner;

  constructor(
    private modalCtrl: ModalController,
    private infoBannerService: InfoBannerService,
    private destinationService: DestinationService,
    private mediaCenterService: MediaCenterService,
  ) {
    super();

    const textCategoryKeys = Object.values(InfoBannerTextCategory).filter((v) => isNaN(Number(v)));
    this.textCategories = textCategoryKeys.map((k: string) => ({
      translateKey: `alarming.term.${k.toLowerCase()}`,
      id: InfoBannerTextCategory[k] as number,
    }));
  }

  ngOnInit() {
    if (!this.infoBanner) {
      this.infoBanner = {
        guid: v4guid(),
        height: '100%',
        background: '#ffffff',
        content: '',
        position: Position.Full,
        textCategory: InfoBannerTextCategory.Info
      };
    }

    this.editedInfoBanner = JSON.parse(JSON.stringify(this.infoBanner));
    this.ckEditorData = this.editedInfoBanner.content;
    this.fullscreenMode = this.editedInfoBanner.position === Position.Full;

    this.infoBannerTextSettingsForm = new FormGroup({
      fullscreenMode: new FormControl<boolean>(this.fullscreenMode),
      htmlContent: new FormControl<string>(this.editedInfoBanner.content, [], this.htmlContentValidator.bind(this)),
      position: new FormControl<Position>(this.editedInfoBanner.position),
      height: new FormControl<string>(this.editedInfoBanner.height, [Validators.minLength(2)]),
      name: new FormControl<string>(this.editedInfoBanner.name, [Validators.minLength(1), Validators.required]),
      textCategory: new FormControl<number>(this.editedInfoBanner.textCategory),
      backgroundColorChecked: new FormControl<boolean>(this.editedInfoBanner.background !== null),
      colorPicker: new FormControl<string>(this.editedInfoBanner.background),
    });

    this.infoBannerTextSettingsForm.valueChanges.pipe(debounceTime(100), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.editedInfoBanner.name = value.name;
      this.editedInfoBanner.position = this.fullscreenMode ? Position.Full : value.position;
      this.editedInfoBanner.height = this.fullscreenMode ? '100%' : value.height;
      this.editedInfoBanner.background = value.backgroundColorChecked ? value.colorPicker : null;
      this.editedInfoBanner.content = value.htmlContent;

      this.fullscreenMode = value.fullscreenMode;

      this.languageTextControls.forEach((t) => {
        this.editedInfoBanner[t.formControlName] = value[t.formControlName];
        t.textCounter = `(${this.editedInfoBanner[t.formControlName]?.length ?? 0}/${this.infoBannerMaxCharacters})`;
      });

      if (this.editedInfoBanner !== this.infoBanner) {
        this.btnIsDisabled = this.infoBannerTextSettingsForm.invalid;
      }

      const iframe = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement;
      if (iframe) {
        iframe.contentDocument.body.style.background = this.editedInfoBanner.background;
        iframe.style.height = this.editedInfoBanner.height;
      }
    });

    this.mediaCenterService.configuration$.pipe(takeUntil(this.onDestroy$)).subscribe((configuration) => {
      this.editorType = configuration.infoBannerType;
      this.infoBannerMaxCharacters = configuration.infoBannerMaxCharacters;
      this.languageTextControls = configuration.languages.map((l) => {
        const formControlName = `text${l[0].toUpperCase()}${l[1].toLowerCase()}`;
        return {
          translateKey: `general.term.${l}`,
          formControlName,
          textCounter: `(${this.editedInfoBanner[formControlName]?.length ?? 0}/${configuration.infoBannerMaxCharacters})`,
        };
      });

      this.languageTextControls.forEach((t) => {
        this.infoBannerTextSettingsForm.addControl(t.formControlName, new FormControl<string>(this.editedInfoBanner[t.formControlName]));
        if (this.maxLengthValidatorFn) {
          this.infoBannerTextSettingsForm.controls[t.formControlName].removeValidators(this.maxLengthValidatorFn);
        }
      });

      this.maxLengthValidatorFn = Validators.maxLength(configuration.infoBannerMaxCharacters);
      this.languageTextControls.forEach((t) => {
        if (this.maxLengthValidatorFn) {
          this.infoBannerTextSettingsForm.controls[t.formControlName].addValidators(this.maxLengthValidatorFn);
        }
      });

      this.infoBannerTextSettingsForm.updateValueAndValidity();
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  setEditor(event: any): void {
    this.editor = event.editor;

    const iframe = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement;
    if (iframe) {
      iframe.contentDocument.body.style.background = this.editedInfoBanner.background;
      iframe.style.height = this.editedInfoBanner.height;
    }

    this.editor.on('mode', () => {
      if (this.editor.mode === 'wysiwyg') {
        const iframe = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement;
        if (iframe) {
          iframe.contentDocument.body.style.background = this.editedInfoBanner.background;
          iframe.style.height = this.editedInfoBanner.height;
        }
      }
    });
  }

  infobannerChangedEvent(): void {
    const data = '<!DOCTYPE html>' + this.editor.getData();
    this.infoBannerTextSettingsForm.controls.htmlContent.setValue(data);

    const iframe = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement;
    if (iframe) {
      iframe.contentDocument.body.style.background = this.editedInfoBanner.background;
      iframe.style.height = this.editedInfoBanner.height;
    }
  }

  checkboxValueChangedScreenMode() {
    this.fullscreenMode = this.infoBannerTextSettingsForm.controls.fullscreenMode.value;
    if (!this.fullscreenMode) {
      this.infoBannerTextSettingsForm.controls.position.setValue(Position.Top);
    }
  }

  onColorChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const color = input.value;

    this.infoBannerTextSettingsForm.controls.colorPicker.setValue(color);

    const iframe = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement;
    if (iframe) {
      iframe.contentDocument.body.style.background = color;
    }
  }

  async save(): Promise<void> {
    try {
      if (!this.editedInfoBanner.tenantGuid) {
        const tenantGuid = await firstValueFrom(this.destinationService.selectedTenant$.pipe(map((t) => t.guid)));
        this.editedInfoBanner.tenantGuid = tenantGuid;
      }

      await firstValueFrom(this.infoBannerService.updateInfoBanner(this.editedInfoBanner));
      this.close();
    } catch {
      this.infoBannerService.presentToast(false, 'infobannerNew', false);
    }
  }

  private async htmlContentValidator(control: AbstractControl<any, any>): Promise<ValidationErrors | null> {
    const configuration = await firstValueFrom(this.mediaCenterService.configuration$);
    const bannerType = configuration?.infoBannerType ?? MediaCenterInfoBannerType.WYSIWYG;

    if (bannerType === MediaCenterInfoBannerType.WYSIWYG) {
      if (!control.value || control.value.trim() === '') {
        return { htmlContent: true };
      }
    }
    return null;
  }
}
